import React from 'react';
import { Link } from 'react-router-dom';

import Routes from './Routes';

// import CookieConsent, { Cookies } from "react-cookie-consent";
import CookieConsent from "react-cookie-consent";

const App = () => {
  return (
    <>
      <Routes />
      
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        <p className='__poli'>This website uses cookies that provide necessary site functionality and improve your online experience. By continuing to use this website, you agree to the use of cookies. Our privacy policy provides more information about what cookies we use and how you can change them.</p>
        <Link to="/terms-of-use/legal" className='__polibtn'>Privacy Policy</Link>
        
        
        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </>
  );
}

export default App;
