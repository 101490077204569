import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { NavLink as Link } from 'react-router-dom';
import moment from "moment";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SideMenu from '../../common/SideMenu';
import UploadComponent from '../../common/Upload.jsx';
// import UserAvatar from '../../common/UserAvatar.jsx';
import DashboardNav from '../component/DashboardNav';
import { errorHandler } from "../../../utils/helpers/errorHandler";

import { fetchAllPublications } from "../../../utils/api/publication";
import { setAllPublications, setSelectedPublication } from "../../../store/actions/actions/publication";
import Pagination from "../../../common/components/Pagination";

const PublicationsPage = (props) => {
	const dispatch = useDispatch();
	const storedPublications = useSelector(state => state.publications.publications);

	const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

	const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));

	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);
	const [publications, setPublications] = useState([]);

	const handleFetchPublicatons = async (page) => {
		try {
			const data = await fetchAllPublications(token, page);

			setPage(data.page);
      setMaxPage(data.count);
      setPublications(data.allPublications);
      dispatch(setAllPublications(data.allPublications));

		} catch (error) {
			errorHandler(error, toast);
		}
	}

	useEffect(() => {
		if(storedPublications && storedPublications.length > 0) {
      setPublications(storedPublications);
    }
	}, [storedPublications]);

	useEffect(() => {
		if(storedPublications && !(storedPublications.length > 0)) {
			handleFetchPublicatons();
		}
	}, []);

	useEffect(() => {
		window.$('#openSidebar').click(function(){
		  window.$('.leftbar').addClass('biger');
		  });
		  window.$('#closebtn').click(function(){
			  window.$('.leftbar').removeClass('biger');
		  });
	  },[]);

	const handleNextPagination = async (event) => {
    event.preventDefault();
    await handleFetchPublicatons(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await handleFetchPublicatons(page - 2);
  }

	const handleUpdateSelectedPublication = async (data) => {
		dispatch(setSelectedPublication(data));
	}

	const title = "Publications"

	return  (
		<>
			<div className='section body'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-2'>
							<SideMenu />
						</div>

						<div className='col-md-10'>
							<DashboardNav 
								page={title}
							/>
							

							<section>
								<div className='__house'>
									<div className='__btcard'>
										<div className='__btcardin'>
											<div className='row'>
												<div className='col-lg-3 col-md-3 col-sm-3'>
													<p className='__btp'>
														All Publications
													</p>
												</div>
												<div className='col-lg-2 col-md-2 col-sm-2'>
													<select
														className='form-control __allsel'>
														<option value=''>
															All
														</option>
													</select>
												</div>
												<div className='col-lg-4 col-md-3 col-sm-3'></div>
												<div className='col-lg-3 col-md-4 col-sm-4'>
													<form>
														<div className='form-group'>
															<input
																type='text'
																className='form-control __btform'
																placeholder='Search by name'
															/>
														</div>
													</form>
												</div>
											</div>
										</div>
										<div className='table-responsive'>
											<table className='table __table table-striped'>
												<thead>
													<tr>
														<th>Name</th>
														<th>Uploaded date</th>
														<th>View</th>
														<th>Status</th>
													</tr>
												</thead>
												<tbody>
													{publications.length ? (publications.map(publication => (
														<tr key={publication.uuid} onClick={() => handleUpdateSelectedPublication(publication)}>
															<td>
																<span>Name</span>
																<Link to={`/admin/dashboard/publications/${publication.uuid}/detail`}>
																	<p className='__tablp'>
																		{publication.title}
																	</p>
																</Link>
															</td>
															<td>
																<span>
																	Uploaded date
																</span>
																<Link to={`/admin/dashboard/publications/${publication.uuid}/detail`}>
																	<p className='__tablp'>
																		{moment(publication.createdAt).format('LLLL')}
																	</p>
																</Link>
															</td>
															<td>
																<span>View</span>
																<Link to={`/admin/dashboard/publications/${publication.uuid}/detail`}>
																	<p className='__tablp'>
																		0
																	</p>
																</Link>
															</td>
															<td>
																<span>Status</span>
																	{publication.status === 'draft' && (<p
																		className='__allstatus btn'
																		style={{ color: '#464646', background: '#F0F0F0' }}>draft
																	</p>)}

                                  {publication.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{publication.status}</p>)}

                                  {publication.status === 'declined' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Rejected</p>)}
															</td>
														</tr>
													))): null}
												</tbody>
											</table>
											{publications.length === 0 && 
												<p style={{textAlign: "center" }}>There are no Publications yet</p>}
										</div>

											<Pagination
												page={+page}
												maxPage={+maxPage}
												SIZE_PER_PAGE={+SIZE_PER_PAGE}
												handleNextPagination={handleNextPagination}
												handlePreviousPagination={handlePreviousPagination}
											/>

										</div>

									<div className='__spacet7p'></div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>

			<ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>

			<UploadComponent />
		</>
	)
}

export default PublicationsPage;
