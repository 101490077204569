import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const sendContactUsMail = async (payload) => {
  try {
    const { data } = await axios(`${baseUrl}/contact-us`,{
      method: 'POST',
      data: payload,
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}