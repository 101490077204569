import { combineReducers } from "redux";

import user from "./reducers/user";
import survey from "./reducers/survey";
import search from "./reducers/search";
import dataset from "./reducers/dataset";
import metrics from "./reducers/metrics";
import interactiveAnalysis from "./reducers/interactiveAnalysis";
import publications from "./reducers/publication";
import question from "./reducers/question";
import pages from "./reducers/cms/pages";
import sections from "./reducers/cms/sections";
import team from "./reducers/team";

const rootReducer = combineReducers({
  user,
  survey,
  search,
  dataset,
  metrics,
  interactiveAnalysis,
  publications,
  question,
  pages,
  sections,
  team,
});

export default rootReducer;
