import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import { useAuth } from '../../hooks/auth';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegisterPage = () => {
  const history = useHistory();

  const auth = useAuth();
  const [name, setName] = useState(''),
        [email, setEmail] = useState(''),
        [password, setPassword] = useState(''),
        [confirmPassword, setConfirmPassword] = useState(''),
        [ phone, setPhone ] = useState('');

  const [showLoader, setShowLoader] = useState(false),
        [showPassword, toggleShowPassword] = useState(false),
        [showConfirmPassword, toggleShowConfirmPassword] = useState(false);

  const handleRegistration = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    try {
      const user = await auth.registerUser({ name, email, password, confirmPassword, phone });

      setTimeout(() => {
        toast.success(`Congratulations ${user.name} your registration was successful!`);
      }, 500);

      resetState();
      setShowLoader(false)
      history.push('/login');

    } catch ({ error }) {
      setShowLoader(false);
      const { message } = error;
      if (Array.isArray(message) && Object.keys(message).length > 0) {
        const nextedErrorMsg = message[0][Object.keys(message[0])];
        return toast.error(`${nextedErrorMsg}`);
      }
      return toast.error(`${error.message}`);
    }
  }

  const resetState = () => {
    setName('');
    setPassword('');
    setConfirmPassword('');
    setEmail('');
  }

  return (
    <>
      <header>
        <div className="__landbgex" style={{ height: '100vh' }}>
          <Nav/>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="__captionex text-center">Register</p>
                <div className="__spacet3p"></div>
                <div className="__regdiv">
                  <form>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control __regdivform"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <input
                        type="email"
                        className="form-control __regdivform"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </div>

                    <div className="form-group mb-3" style={{ position: "relative" }}>
                      <input
                        type={showPassword ? "text":"password"}
                        className="form-control __regdivform"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <label
                       onClick={() => toggleShowPassword(!showPassword)}
                       style={{
                         position: "absolute",
                         top: "32%",
                         right: 20,
                         color: "#555",
                         cursor: "pointer"
                        }}>
                        {!showPassword && <i class="fa fa-eye" aria-hidden="true"></i>}
                        {showPassword && <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                      </label>
                    </div>

                    <div className="form-group mb-3" style={{ position: "relative" }}>
                      <input
                        type={showConfirmPassword ? "text":"password"}
                        className="form-control __regdivform"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        required
                      />
                      <label
                       onClick={() => toggleShowConfirmPassword(!showConfirmPassword)}
                       style={{
                         position: "absolute",
                         top: "32%",
                         right: 20,
                         color: "#555",
                         cursor: "pointer"
                        }}>
                        {!showConfirmPassword && <i class="fa fa-eye" aria-hidden="true"></i>}
                        {showConfirmPassword && <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                      </label>
                    </div>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control __regdivform"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        placeholder="Phone"
                      />
                    </div>

                    <p className="__reg text-center">Already registered? <Link to="/login">Sign in</Link></p>

                    <div className="__spacet5p"></div>
                    <button
                      type="submit"
                      className="btn btn-block __regbtn"
                      onClick={handleRegistration}
                    >
                      REGISTER {showLoader && <img src="/assets/img/loader.gif" alt="" />}
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
            </div>
          </div>
        </div>
      </header>

      <ToastContainer
        closeOnClick
        pauseOnHover
      >
      </ToastContainer>
    </>
  )
}

export default RegisterPage;