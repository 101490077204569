import React, { useEffect, useState } from 'react';
import {NavLink as Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { fetchAllPage } from "../../../utils/api/cms/page";
import { setPages, setSelectedPage } from "../../../store/actions/actions/cms/pages";

import SideMenu from '../../common/SideMenu';
import UploadComponent from '../../common/Upload.jsx'
// import UserAvatar from '../../common/UserAvatar.jsx';
import DashboardNav from '../component/DashboardNav';

import Pagination from "../../../common/components/Pagination";
import { errorHandler } from '../../../utils/helpers/errorHandler';

const UsersPage = (props) => {
  const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

  const history = useHistory();
  
  const storedPages = useSelector(state => state.pages.pages);
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token")); 
  const dispatch = useDispatch();

	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);
	const [localPages, setLocalPages] = useState([]);


  const [searchResult, setSearchResult] = useState([]);

  const handleFetchAllPages = async (page) => {
    try {
      const data = await fetchAllPage(token, page);

      setPage(data.page);
      setMaxPage(data.count);
      setLocalPages(data.allPages);
      dispatch(setPages(data.allPages));

    } catch (error) {
      errorHandler(error, toast);
    }
  }

  useEffect(() => {
    if(storedPages?.length === 0) {
      handleFetchAllPages();
    } else {
      setLocalPages(storedPages);
    }
  }, [storedPages]);

  useEffect(() => {
    window.$('#openSidebar').click(function(){
      window.$('.leftbar').addClass('biger');
      });
      window.$('#closebtn').click(function(){
          window.$('.leftbar').removeClass('biger');
      });
  },[])

   const handleNextPagination = async (event) => {
    event.preventDefault();
    await handleFetchAllPages(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await handleFetchAllPages(page - 2);
  }

  const handeGotoDetailPage = (page) => {
    dispatch(setSelectedPage(page));
    history.push(`/admin/dashboard/resources/${page.slug}/detail`);
  }

  const title = "CMS"
  
  return (
    <>
      <div className='section body'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-2'>
              <SideMenu />
            </div>

            <div className='col-md-10'>
                <DashboardNav 
                  page={title}
                />
              

              <section>
                <div className='__house'>
                  <div className='__btcard'>
                    <div className='__btcardin'>
                      <div className='row'>
                        <div className='col-lg-8 col-md-8 col-sm-8'>
                          <p className='__btp'>Content Management System</p>
                        </div>
                        <div className='col-lg-5 col-md-3 col-sm-3'></div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table __table table-striped'>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Registration date</th>
                            <th>Type</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                        { (localPages.length > 0 && searchResult.length === 0) ? (localPages.map(page => (
                          <tr key={page.id}>
                          <td onClick={() => handeGotoDetailPage(page)} style={{ cursor: 'pointer' }}>
                            <span>Title</span>
                              <p className='__tablp'>
                                {page.title}
                              </p>
                          </td>
                          <td onClick={() => handeGotoDetailPage(page)} style={{ cursor: 'pointer' }}>
                            <span>
                              Creation date
                            </span>
                              <p className='__tablp'>
                                {moment(page.createdAt).format('LLLL')}
                              </p>
                          </td>
                          <td onClick={() => handeGotoDetailPage(page)} style={{ cursor: 'pointer' }}>
                            <span>
                              Registration date
                            </span>
                              <p className='__tablp1'>
                                <img
                                  src='/assets/img/attachment.png'
                                  className='__tabimg1'
                                  alt=''
                                />
                                &nbsp;&nbsp;
                                {page.type}
                              </p>
                            </td>
                          <td onClick={() => handeGotoDetailPage(page)} style={{ cursor: 'pointer' }}>
                                {page.status === 'draft' && (<p
                                  className='__allstatus btn'
                                  style={{ color: '#464646', background: '#F0F0F0' }}>draft
                                </p>)}

                                {page.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{page.status}</p>)}

                                {page.status === 'unpublished' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Unpublised</p>)}
                          </td>
                        </tr>
                        ))): (
                          searchResult.map(page => (
                            <tr key={page.id}>
                            <td onClick={() => handeGotoDetailPage(page)} style={{ cursor: 'pointer' }}>
                              <span>Title</span>
                                <p className='__tablp'>
                                  <img
                                    src='/assets/img/clock.jpg'
                                    className='__tabimg2'
                                    alt=""
                                  />
                                  &nbsp;&nbsp;{page.title}
                                </p>
                            </td>
                            <td onClick={() => handeGotoDetailPage(page)} style={{ cursor: 'pointer' }}>
                              <span>
                                Registration date
                              </span>
                                <p className='__tablp'>
                                  {moment(page.createdAt).format('LLLL')}
                                </p>
                            </td>
                            <td onClick={() => handeGotoDetailPage(page)} style={{ cursor: 'pointer' }}>
                              <span>
                                Type
                              </span>
                                <p className='__tablp'>
                                  {page.type}
                                </p>
                            </td>
                            <td onClick={() => handeGotoDetailPage(page)} style={{ cursor: 'pointer' }}>
                                {page.status === 'draft' && (<p
                                  className='__allstatus btn'
                                  style={{ color: '#464646', background: '#F0F0F0' }}>draft
                                </p>)}

                                {page.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{page.status}</p>)}

                                {page.status === 'declined' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Rejected</p>)}
                            </td>
                          </tr>
                          ))
                        )}
                        </tbody>
                      </table>
                      { localPages.length === 0 && 
												<p style={{textAlign: "center" }}>There are no Pages yet</p>}
                    </div>
                    <Pagination
                      page={+page}
                      maxPage={+maxPage}
                      SIZE_PER_PAGE={+SIZE_PER_PAGE}
                      handleNextPagination={handleNextPagination}
                      handlePreviousPagination={handlePreviousPagination}
                    />
                  </div>

                  <div className='__spacet7p'></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <UploadComponent />

      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>

    </>
  )
}

export default UsersPage;