import React, { useEffect, useState } from 'react';
import {NavLink as Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { fetchAllUsers, updateUSerStatus } from '../../../utils/api/user';
import { storeAllUsers, updateUserStatus } from '../../../store/actions/actions/user';

import SideMenu from '../../common/SideMenu';
import UploadComponent from '../../common/Upload.jsx'
// import UserAvatar from '../../common/UserAvatar.jsx';
import DashboardNav from '../component/DashboardNav';

import Pagination from "../../../common/components/Pagination";
import { errorHandler } from '../../../utils/helpers/errorHandler';

const UsersPage = (props) => {
  const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;
  
  const allUsers = useSelector(state => state.user.users);
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token")); 

  const dispatch = useDispatch();

  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);
  const [selectedUser, setUser] = useState('');
  const [showUpdateLoader, setShowUpdatingLoader] = useState(false);

  const retrieveAllUsers = async (currentPage = 0) => {
    try {
      const data = await fetchAllUsers(token, currentPage);

      setPage(data.page);
      setMaxPage(data.count);
      dispatch(storeAllUsers(data.allUsers));
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  }

  useEffect(() => {
    if(allUsers.length === 0) {
      retrieveAllUsers();
    }
  }, []);
  useEffect(() => {
		window.$('#openSidebar').click(function(){
		  window.$('.leftbar').addClass('biger');
		  });
		  window.$('#closebtn').click(function(){
			  window.$('.leftbar').removeClass('biger');
		  });
	  },[]);

  const handleSearch = (event) => {
    event.preventDefault();

    let searchWord = event.target.value.toLowerCase();
    const allUsersClone = [...allUsers];
    const searchResult = allUsersClone.filter(user => user.full_name.toLowerCase().includes(searchWord));

    setSearchResult(searchResult);
  }

  const handleNextPagination = async (event) => {
    event.preventDefault();
    await retrieveAllUsers(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await retrieveAllUsers(page - 2);
  }

  const handleUpdateUserStatus = async (event) => {
    try {
      event.preventDefault();
      setShowUpdatingLoader(true);
      const status = selectedUser.status === 'active' ? 'in-active' : 'active';
      await updateUSerStatus(selectedUser.uuid, status, token);

      toast.success('User status changed successfully');

      dispatch(updateUserStatus({ uuid: selectedUser.uuid, status }));
   
      document.querySelector(".dismiss-update-user-modal").click();
    } catch (error) {
      errorHandler(error, toast);
    } finally {
      setShowUpdatingLoader(false);
    }
  }

  const title = "Users";
  
  return (
    <>
      <div className='section body'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-2'>
              <SideMenu />
            </div>

            <div className='col-md-10'>
              <DashboardNav 
                page={title}
              />
              

              <section>
                <div className='__house'>
                  <div className='__btcard'>
                    <div className='__btcardin'>
                      <div className='row'>
                        <div className='col-lg-2 col-md-2 col-sm-2'>
                          <p className='__btp'>All Users</p>
                        </div>
                        <div className='col-lg-5 col-md-3 col-sm-3'></div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table __table table-striped'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Registration date</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        { (allUsers.length > 0 && searchResult.length === 0) ? (allUsers.map(user => (
                          <tr key={user.id}>
                          <td>
                            <span>Name</span>
                              <p className='__tablp'>
                                <img
                                  src='/assets/img/clock.jpg'
                                  className='__tabimg2'
                                  alt=""
                                />
                                &nbsp;&nbsp;{user.name}
                              </p>
                          </td>
                          <td>
                            <span>
                              Registration date
                            </span>
                              <p className='__tablp'>
                                {moment(user.createdAt).format('LLLL')}
                              </p>
                          </td>
                          <td>
                            <span>Email</span>
                              <p className='__tablp'>
                                {user.email}
                              </p>
                          </td>
                          <td>
                            <span>Status</span>
                            {user.status === 'active' ? (<p
                              className='__allstatus btn'
                              style={{ color: '#0C9EA9', background: '#E6FDFF' }}>
                              Active
                            </p>) : (<p
                              className='__allstatus btn'
                              style={{ color: '#EE7000', background: '#FFE8D4' }}>
                                Pending
                            </p>)}
                          </td>
                          <td>
                            <span>Actions</span>
                              <a
                              data-bs-toggle="modal" data-bs-target="#modal-updateUser"
                                onClick={() => setUser(user)}
                              >
                                <i class="fa fa-pencil-square-o" aria-hidden="true" style={{ color: 'gray', cursor: 'pointer' }}></i>
                              </a>
                          </td>
                        </tr>
                        ))): (
                          searchResult.map(user => (
                            <tr key={user.id}>
                            <td>
                              <span>Name</span>
                              <Link to="/admin/dashboard/users/detail">
                                <p className='__tablp'>
                                  <img
                                    src='/assets/img/clock.jpg'
                                    className='__tabimg2'
                                    alt=""
                                  />
                                  &nbsp;&nbsp;{user.full_name}
                                </p>
                              </Link>
                            </td>
                            <td>
                              <span>
                                Registration date
                              </span>
                              <Link to="/admin/dashboard/users/detail">
                                <p className='__tablp'>
                                  {moment(user.createdAt).format('LLLL')}
                                </p>
                              </Link>
                            </td>
                            <td>
                              <span>Types</span>
                              <Link to="/admin/dashboard/users/detail">
                                <p className='__tablp'>
                                  {user.role.name}
                                </p>
                              </Link>
                            </td>
                            <td>
                              <span>Status</span>
                              <Link to="/admin/dashboard/users/detail">
                                {user.status === 'active' ? (<p
                                  className='__allstatus btn'
                                  style={{ color: '#0C9EA9', background: '#E6FDFF' }}>
                                  Active
                                </p>) : (<p
                                  className='__allstatus btn'
                                  style={{ color: '#EE7000', background: '#FFE8D4' }}>
                                    Pending
                                </p>)}
                              </Link>
                            </td>
                            <td>
                              <span>Actions</span>
                              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          </td>
                          </tr>
                          ))
                        )}
                        </tbody>
                      </table>
                      { allUsers.length === 0 && 
												<p style={{textAlign: "center" }}>There are no Users yet</p>}
                    </div>

                    <Pagination
                      page={+page}
                      maxPage={+maxPage}
                      SIZE_PER_PAGE={+SIZE_PER_PAGE}
                      handleNextPagination={handleNextPagination}
                      handlePreviousPagination={handlePreviousPagination}
                    />

                  </div>

                  <div className='__spacet7p'></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>


        

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    ...
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
        </div>
            
      <div className="modal fade" id="modal-updateUser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
            <button type="button" class="btn-close __close dismiss-update-user-modal" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps1">User</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="row __flexsmail" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <div
                  className="col-lg-7 col-md-7 col-sm-7 __mobidown"
                >
                  <p>Are you sure you want to <strong>{selectedUser.status === 'active' ? 'SUSPEND':'ACTIVATE'}</strong> this User - <strong>{selectedUser.name}</strong>?</p>
                  <form style={{ marginTop: "1.5rem", display: "flex", justifyContent: "center" }}>
                    <a
                      className="btn __autbtn"
                      onClick={handleUpdateUserStatus}
                      style={{ width: "120px", marginRight: "15px", marginTop: "2rem" }}
                    >
                      Update
                      { showUpdateLoader && <img src="/assets/img/loader.gif" alt="" /> }
                    </a>
                    <a
                      className="btn __dnbtn1"
                      style={{
                        width: "120px",
                        backgroundColor: "#fff",
                        border: "1px solid #0C9EA9",
                      }}
                      data-dismiss="modal"
                      data-toggle="modal"
                    >
                      Cancel
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

	

      <UploadComponent />

      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>

    </>
  )
}

export default UsersPage;
