import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const uploadInteractiveAnalysis = async (interactiveAnalysis, token) => {
  try {
    const { data } = await axios(`${baseUrl}/interactivities`,{
      method: 'POST',
      data: interactiveAnalysis,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllInteractiveAnalysis = async (token, page = 0) => {
  try {
    const {data: {data} } = await axios({
      method: 'GET',
      url: `${baseUrl}/interactivities?page=${page}`,
      headers: {'Content-Type': 'application/json', 'Authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const updateInteractiveAnalysisStatus = async ({ status, comment = undefined, uuid, token }) => {
  try {
    const { data } = await axios({
      method: 'PUT',
      data: { status, comment},
      url: `${baseUrl}/interactivities/admin/${uuid}`,
      headers: {'Content-Type': 'application/json', 'Authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllPublishedInteractiveAnalysis = async (page = 0) => {
  try {
    const {data: {data} } = await axios({
      method: 'GET',
      url: `${baseUrl}/interactivities/public?page=${page}`,
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const searchInteractivity = async (searchKeys, page = 0) => {
  // console.log(searchKeys)
  try {
    const {data: { data }} = await axios({
      method: 'GET',
      url: `${baseUrl}/interactivities/search?${searchKeys.join('&')}&page=${page}`,
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const findInteractiveAnalysis = async (uuid) => {
  try {
    const {data: {data}} = await axios({
      method: 'GET',
      url: `${baseUrl}/interactivities/${uuid}`,
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const updateInteractiveAnalysis = async (interactiveAnalysis, uuid, token) => {
  try {
    const { data } = await axios(`${baseUrl}/interactivities/${uuid}`,{
      method: 'PUT',
      data: interactiveAnalysis,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data
  } catch (error) {
    throw error.response.data;
  }
}

export const deleteInteractiveAnalysis = async (uuid, token) => {
  try {
    const { data } = await axios(`${baseUrl}/interactivities/${uuid}`,{
      method: 'DELETE',
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data
  } catch (error) {
    throw error.response.data;
  }
}


