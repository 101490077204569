import React from 'react';
import DashboardNav from '../../component/DashboardNav.js';

import SideMenu from '../../../common/SideMenu.jsx';

const UserDetailPage = (props) => {
	const title = "User Details"
  
  return (
  <>
  	<div className="section body">
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-2">
					<SideMenu />
				</div>

				<div className="col-md-10">
					<DashboardNav 
						page={title}
					/>
					{/* <nav className="navbar __dashnavbar" role="navigation">
						<div className="container-fluid">
							<div className="navbar-header">
								<button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
									<span className="sr-only">Toggle navigation</span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
								<a href="#" className="navbar-brand __navbar-brand1" id="openSidebar"><i className=" fa fa-bars" onclick="openSidebar()" ></i> <span className="hidden-sm">User Details</span></a>
							</div>

							<div className="collapse navbar-collapse navbar-ex1-collapse">
								<ul className="nav navbar-nav">
									<p className="__dasovp"> <a href="admin-user.html"><i className="fas fa-arrow-left" style={{ color: '#07A0AB' }}></i>&nbsp;&nbsp; User Details</a></p>
								</ul>
								<ul className="nav navbar-nav navbar-right">
									<li className="dropdown">
										<a href="#" className="dropdown-toggle __droprof" data-toggle="dropdown">Ademola Precious&nbsp;&nbsp; <img src="/assets/img/clock.jpg" className=" __dashimg" /> <b className="caret"></b></a>
										<ul className="dropdown-menu __dropdown-menu" role="menu">
										    <li><a className="__a" href="">Log out</a></li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</nav> */}

					<section>
						<div className="__house">
							<div className="__btcard">
								<div className="__spacet3p"></div>
								<div className="__btcardin1">
									<div className="row">
										<div className="col-lg-3 col-md-3 col-sm-3">
											<a href="">
												<div className="__dascard text-center">
													<p className="__dasp">Viewed Dataset</p>
													<p className="__dasp1">300</p>
												</div>
											</a>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-3">
											<a href="">
												<div className="__dascard text-center">
													<p className="__dasp">Shared Datasets</p>
													<p className="__dasp1">120</p>
												</div>
											</a>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-3">
											<a href="">
												<div className="__dascard text-center">
													<p className="__dasp">Accepted Datasets</p>
													<p className="__dasp1">869</p>
												</div>
											</a>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-3">
											<a href="">
												<div className="__dascard text-center">
													<p className="__dasp">Denied Datasets</p>
													<p className="__dasp1">45</p>
												</div>
											</a>
										</div>
									</div>
									<div className="__spacet2p"></div>
									<div className="row">
										<div className="col-lg-4 col-md-4 col-sm-4">
											<p className="__subp">Reg Date: 5th September, 2020; 12:04AM</p>
										</div>
										<div className="col-lg-4 col-md-4 col-sm-4">
											<p className="__subp1">User Account Type: Data share</p>
										</div>
										<div className="col-lg-4 col-md-4 col-sm-4">
											<p className="__subp2">Status:&nbsp;&nbsp; <span style={{ color: '#0C9EA9', background: '#E6FDFF' }}>Accepted</span></p>
										</div>
									</div>
									<hr />
									<form action="" method="POST" role="form">
										<div className="row __flexsmail">
											<div className="col-lg-6 col-md-6 col-sm-6 __mobidown">

												<div className="form-group">
													<label htmlFor="" className="__modlab">First Name</label>
													<input type="text" className="form-control __regdivform" id="" placeholder="Hailee Steinfeld" />
												</div>

												<div className="form-group">
													<label htmlFor="" className="__modlab">Last Name</label>
													<input type="text" className="form-control __regdivform" id="" placeholder="Hailee Steinfeld" />
												</div>

												<div className="form-group">
													<label htmlFor="" className="__modlab">Email Address</label>
													<input type="email" className="form-control __regdivform" id="" placeholder="Hailee@example.gmail.com" />
												</div>
											</div>
											<div className="col-lg-1 col-md-1"></div>
											<div className="col-lg-5 col-md-5 col-sm-6 __mobiup">
												<div className="__moduldiv">
													<div className="__picture1 text-center">
					                                	<img src="/assets/img/profile1.png"
						                                  className="__modadimg" id="picture_preview1" alt="" />
						                                <input className="img_container __minpute" type="file" id="upload1" name="file" title="Change profile Picture" />
						                                <p className="__editpic">Add a profile picture</p>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-6 col-md-6 col-sm-6">
												<a href="" className="btn __profbtn" style={{ background: '#F87501', border: '1px solid #F87501' }}>Block users</a>
												&nbsp;&nbsp;
												<a href="" className="btn __dnbtn">Delete Users</a>
											</div>
										</div>
									</form>
									<div className="__spacet5p"></div>
								</div>
							</div>

							<div className="__spacet7p"></div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>

	<div className="modal fade" id="modal-id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div className="modal-dialog __modal-dialog">
			<div className="modal-content">
				<div className="modal-body">
				
					<button type="button" className="btn-close __close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
							<p className="__modseps">Step
								<span className="__hidden2">1</span>
								<span className="hidden1">2</span>
								<span className="hidden2">3</span>
							 of 3</p>
							 <p className="__modseps1">Dataset Details</p>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
							<div className="text-center __hidden2">
								<span className="__kangproga"></span>
								<span className="__kangprog"></span>
								<span className="__kangprog"></span>
		                    </div>

		                    <div className="text-center hidden1">
		                    	<span className="__kangprog"></span>
								<span className="__kangproga"></span>
								<span className="__kangprog"></span>
		                    </div>

		                    <div className="text-center hidden2">
		                    	<span className="__kangprog"></span>
								<span className="__kangprog"></span>
								<span className="__kangproga"></span>
		                    </div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-4"></div>
					</div>
					<div className="__spacet7p"></div>
					<div className="row __flexsmail">
                		<div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
		        			<form action="" method="POST" role="form">
	                            <div className="__hidden2">
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Dataset Name</label>
										<input type="text" className="form-control __regdivform" id="" placeholder="E.g. Child Care" />
									</div>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
			                            		<label htmlFor="" className="__modlab">Ministry</label>
												<select name="" id="input" className="form-control __regdivform">
													<option value="">E.g. Education</option>
												</select>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
			                            		<label htmlFor="" className="__modlab">Organization</label>
												<select name="" id="input" className="form-control __regdivform">
													<option value="">E.g. Education</option>
												</select>
											</div>
										</div>
									</div>
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Description</label><textarea name="" id="input" className="form-control __regdivform" rows="5" ></textarea>
									</div>
									<div className="__spacet7p"></div>
			        				<div className="row">
			        					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
			        					<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
			        						<a className="btn btn-block __autbtn showButton show1">Proceed</a>
			        					</div>
			        					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
			        				</div>
	                            </div>

	                            <div className="hidden1">
	                            	<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
			                            		<label htmlFor="" className="__modlab">From:</label>
												<input type="date" className="form-control __regdivform" id="" placeholder="E.g. May 2020" />
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
			                            		<label htmlFor="" className="__modlab">To:</label>
												<input type="date" className="form-control __regdivform" id="" placeholder="E.g. May 2020" />
											</div>
										</div>
									</div>
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Location(s)</label>
	                            		<div className="__chcdiv">
	                            			<div className="row">
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            			</div>

	                            			<div className="row">
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            			</div>

	                            			<div className="row">
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            			</div>

	                            			<div className="row">
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            			</div>

	                            			<div className="row">
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            				<div className="col-lg-6 col-md-6 col-sm-6">
	                            					<label className="check">
														Local government name
														<input type="checkbox" name="is_name" id="" />
													  	<span className="checkmark"></span>
													</label>
	                            				</div>
	                            			</div>
	                            		</div>
									</div>
			        				<div className="__spacet5p"></div>
			        				<div className="row">
	                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
	                                        <a className="btn btn-block __autbtn1 hideButton">Back</a>
	                                    </div>
	                                    <div className="col-lg-4 col-md-4 col-sm-4"></div>
	                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
	                                        <a className="btn btn-block __autbtn showButton1 show2">Continue</a>
	                                    </div>
	                                </div>
			        			</div>

	                            <div className="hidden2">
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Add Tags</label>
										<select className="js-select2" multiple="multiple">
											<option value="O1" selected="" data-badge="">Education </option>
											<option value="O2" data-badge="">Sickness</option>
											<option value="O3" data-badge="">Health </option>
											<option value="O4" data-badge="">Food </option>
											<option value="O5" data-badge="">Life </option>
											<option value="O6" data-badge="">Deseases</option>
											<option value="O7" data-badge="">Food</option>
										</select>
									</div>
									<div className="__spacet5p"></div>
			        				<div className="row">
	                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
	                                        <a className="btn btn-block __autbtn1 hideButton1 ">Back</a>
	                                    </div>
	                                    <div className="col-lg-4 col-md-4 col-sm-4"></div>
	                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
	                                        <a data-dismiss="modal" data-toggle="modal" href='#modal-idf' className="btn btn-block __autbtn">Finish</a>
	                                    </div>
	                                </div>
			        			</div>
							</form>
						</div>
                		<div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                			<div className=" __it">
				        		<div className="uploadDoc">
				                    <div className="fileUpload btn btn-orange">
			                            <img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" /><br />
			                            <span className="__upl" id="upload">Browse <span>file here</span></span>
			                            <input type="file" className="upload up" id="" required="required" />
			                        </div>
				                </div>
					        </div>
                		</div>
                	</div>
	        	</div>
			</div>
		</div>
	</div>

	<div className="modal fade" id="modal-idf" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div className="modal-dialog __modal-dialog">
			<div className="modal-content">
				<div className="modal-body">
				
					<button type="button" className="btn-close__close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div className="__spacet10p"></div>
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-2"></div>
						<div className="col-lg-8 col-md-8 col-sm-8">
							<div className="text-center">
								<img src="/assets/img/check.png" className="__yosuccimg" />
								<p className="__yosucc">You have not submitted any dataset.Get started by clicking on the down left corner of your screen</p>
								<p className="__yosucc1">Estinmated Date: 8th September, 2020</p>
								<div className="__spacet7p"></div>
		        				<div className="row">
		        					<div className="col-lg-2 col-md-2 col-sm-2"></div>
		        					<div className="col-lg-8 col-md-8 col-sm-8">
		        						<div className="row">
				                            <div className="col-xs-6">
				                                <a data-dismiss="modal" data-toggle="modal" href='#modal-id' className="btn btn-block __autbtn1">Back to Edit</a>
				                            </div>
				                            <div className="col-xs-6">
				                                <a href="admin-dashboard.html" className="btn btn-block __autbtn">Goto Dashboard</a>
				                            </div>
				                        </div>
		        					</div>
		        					<div className="col-lg-2 col-md-2 col-sm-2"></div>
		        				</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-2"></div>
					</div>
					<div className="__spacet7p"></div>
				</div>
			</div>
		</div>
	</div>

	<div className="modal fade" id="modal-idpub" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div className="modal-dialog __modal-dialog">
			<div className="modal-content">
				<div className="modal-body">
					<button type="button" className="btn-close __close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
							<p className="__modseps">Step
								<span className="__hidden2">1</span>
								<span className="hidden1">2</span>
							 of 2</p>
							 <p className="__modseps1">Publications</p>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
							<div className="text-center __hidden2">
								<span className="__kangproga"></span>
								<span className="__kangprog"></span>
		                    </div>

		                    <div className="text-center hidden1">
		                    	<span className="__kangprog"></span>
								<span className="__kangproga"></span>
		                    </div>

						</div>
						<div className="col-lg-4 col-md-4 col-sm-4"></div>
					</div>
					<div className="__spacet7p"></div>
					<div className="row __flexsmail">
                		<div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
		        			<form action="" method="POST" role="form">
	                            <div className="__hidden2">
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Dataset Name</label>
										<input type="text" className="form-control __regdivform" id="" placeholder="E.g. Child Care" />
									</div>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
			                            		<label htmlFor="" className="__modlab">Ministry</label>
												<select name="" id="input" className="form-control __regdivform">
													<option value="">E.g. Education</option>
												</select>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
			                            		<label htmlFor="" className="__modlab">Organization</label>
												<select name="" id="input" className="form-control __regdivform">
													<option value="">E.g. Education</option>
												</select>
											</div>
										</div>
									</div>
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Description</label><textarea name="" id="input" className="form-control __regdivform" rows="5" ></textarea>
									</div>
									<div className="__spacet7p"></div>
			        				<div className="row">
			        					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
			        					<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
			        						<a data-dismiss="modal" data-toggle="modal" href='#modal-idfpu' className="btn btn-block __autbtn">Proceed</a>
			        					</div>
			        					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
			        				</div>
	                            </div>
							</form>
						</div>
                		<div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                			<div className=" __it">
				        		<div className="uploadDoc">
				                    <div className="fileUpload btn btn-orange">
			                            <img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" /><br />
			                            <span className="__upl" id="upload">Browse <span>file here</span></span>
			                            <input type="file" className="upload up" id="" required="required" />
			                        </div>
				                </div>
					        </div>
                		</div>
                	</div>
	        	</div>
			</div>
		</div>
	</div>

	<div className="modal fade" id="modal-idfpu" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div className="modal-dialog __modal-dialog">
			<div className="modal-content">
				<div className="modal-body">
					<button type="button" className="btn-close __close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
							<p className="__modseps">Step
								<span className="__hidden1">1</span>
								<span className="hidden2">2</span>
							 of 2</p>
							 <p className="__modseps1">Publications</p>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
							<div className="text-center __hidden2">
								<span className="__kangprog"></span>
								<span className="__kangproga"></span>
		                    </div>

		                    <div className="text-center hidden1">
		                    	<span className="__kangproga"></span>
								<span className="__kangprog"></span>
		                    </div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-4"></div>
					</div>
					<div className="__spacet7p"></div>
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-2"></div>
						<div className="col-lg-8 col-md-8 col-sm-8">
							<div className="text-center">
								<img src="/assets/img/check.png" className="__yosuccimg" />
								<p className="__yosucc">Your Publication was added successfully</p>
								<div className="__spacet7p"></div>
		        				<div className="row">
		        					<div className="col-lg-4 col-md-4 col-sm-4"></div>
		        					<div className="col-lg-4 col-md-4 col-sm-4">
		        						<a href="admin-dashboard.html" className="btn btn-block __autbtn">Done</a>
		        					</div>
		        					<div className="col-lg-4 col-md-4 col-sm-4"></div>
		        				</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-2"></div>
					</div>
					<div className="__spacet7p"></div>
				</div>
			</div>
		</div>
	</div>

	<div className="modal fade" id="modal-idia" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div className="modal-dialog __modal-dialog">
			<div className="modal-content">
				<div className="modal-body">
					<button type="button" class="btn-close __close dismiss-pub-update-modal" data-bs-dismiss="modal" aria-label="Close"></button>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
							<p className="__modseps">Step
								<span className="__hidden2">1</span>
								<span className="hidden1">2</span>
								<span className="hidden2">3</span>
							 of 3</p>
							 <p className="__modseps1">Interactive Analysis</p>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
							<div className="text-center __hidden2">
								<span className="__kangproga"></span>
								<span className="__kangprog"></span>
								<span className="__kangprog"></span>
		                    </div>

		                    <div className="text-center hidden1">
		                    	<span className="__kangprog"></span>
								<span className="__kangproga"></span>
								<span className="__kangprog"></span>
		                    </div>

		                    <div className="text-center hidden2">
		                    	<span className="__kangprog"></span>
								<span className="__kangprog"></span>
								<span className="__kangproga"></span>
		                    </div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-4"></div>
					</div>
					<div className="__spacet7p"></div>
					<div className="row __flexsmail">
                		<div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
		        			<form action="" method="POST" role="form">
	                            <div className="__hidden2">
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Interactive Analysis Title</label>
										<input type="text" className="form-control __regdivform" id="" placeholder="Interactive Analysis Title" />
									</div>
									<div className="__spacet20p"></div>
			        				<div className="row">
			        					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
			        					<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
			        						<a className="btn btn-block __autbtn showButton show1">Proceed</a>
			        					</div>
			        					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
			        				</div>
	                            </div>

	                            <div className="hidden1">
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">E.g. Child Care</label>
										<input type="text" className="form-control __regdivform" id="" placeholder="E.g. Child Care" />
									</div>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
			                            		<label htmlFor="" className="__modlab">Misnistry</label>
												<select name="" id="input" className="form-control __regdivform">
													<option value="">E.g. Education</option>
												</select>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
			                            		<label htmlFor="" className="__modlab">Organization</label>
												<select name="" id="input" className="form-control __regdivform">
													<option value="">E.g. Education</option>
												</select>
											</div>
										</div>
									</div>
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Description</label>
	                            		<textarea name="" id="input" className="form-control __regdivform" rows="5" ></textarea>
									</div>
			        				<div className="__spacet5p"></div>
			        				<div className="row">
	                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
	                                        <a className="btn btn-block __autbtn1 hideButton">Back</a>
	                                    </div>
	                                    <div className="col-lg-4 col-md-4 col-sm-4"></div>
	                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
	                                        <a className="btn btn-block __autbtn showButton1 show2">Continue</a>
	                                    </div>
	                                </div>
			        			</div>

	                            <div className="hidden2">
	                            	<div className="form-group">
	                            		<label htmlFor="" className="__modlab">Add Tags <span style={{ color: '#AFAFAF', opacity: '0.6' }}>Optional</span></label>
										<select className="js-select2" multiple="multiple">
											<option value="O1" selected="" data-badge="">Education </option>
											<option value="O2" data-badge="">Sickness</option>
											<option value="O3" data-badge="">Health </option>
											<option value="O4" data-badge="">Food </option>
											<option value="O5" data-badge="">Life </option>
											<option value="O6" data-badge="">Deseases</option>
											<option value="O7" data-badge="">Food</option>
										</select>
									</div>
									<div className="__spacet5p"></div>
			        				<div className="row">
	                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
	                                        <a className="btn btn-block __autbtn1 hideButton1 ">Back</a>
	                                    </div>
	                                    <div className="col-lg-4 col-md-4 col-sm-4"></div>
	                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
	                                        <a data-dismiss="modal" data-toggle="modal" href='#modal-idfia' className="btn btn-block __autbtn">Finish</a>
	                                    </div>
	                                </div>
			        			</div>
							</form>
						</div>
                		<div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                			<div className="form-group __seleimgdiv">
                				<label htmlFor="" className="__modlab">Select file to Upload</label>
	                			<select name="" id="input" className="form-control __regdivform">
									<option value="">MP4</option>
									<option value="">Image</option>
								</select>
	                		</div>
                			<div className=" __it">
				        		<div className="uploadDoc">
				                    <div className="fileUpload btn btn-orange">
			                            <img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" /><br />
			                            <span className="__upl" id="upload">Browse <span>file here</span></span>
			                            <input type="file" className="upload up" id="" required="required" />
			                        </div>
				                </div>
					        </div>
					    </div>
                	</div>
	        	</div>
			</div>
		</div>
	</div>

	<div className="modal fade" id="modal-idfia" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div className="modal-dialog __modal-dialog">
			<div className="modal-content">
				<div className="modal-body">
					<button type="button" className="btn-close __close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div className="__spacet10p"></div>
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-2"></div>
						<div className="col-lg-8 col-md-8 col-sm-8">
							<div className="text-center">
								<img src="/assets/img/check.png" className="__yosuccimg" />
								<p className="__yosucc">Your Animation was added successfully</p>
								<div className="__spacet7p"></div>
		        				<div className="row">
		        					<div className="col-lg-4 col-md-4 col-sm-4"></div>
		        					<div className="col-lg-4 col-md-4 col-sm-4">
		        						<a href="admin-dashboard.html" className="btn btn-block __autbtn">Done</a>
		        					</div>
		        					<div className="col-lg-4 col-md-4 col-sm-4"></div>
		        				</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-2"></div>
					</div>
					<div className="__spacet7p"></div>
				</div>
			</div>
		</div>
	</div>
  </>
  
)
  };

export default UserDetailPage;