import { STORE_METRICS, ADD_METRICS } from '../../actiontypes';

export const storeMetrics = (payload) => ({
  type: STORE_METRICS,
  payload
});

export const addMetric = (payload) => ({
  type: ADD_METRICS,
  payload
});