import {
  SET_TEAM,
  SET_SELECTED_TEAM_MEMBER,
  ADD_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_DATA,
  DELETE_TEAM_MEMBER,
  SET_TEAM_MEMBER_TO_BE_VISUALIZED,
} from "../../actiontypes";

export const setAllTeam = (payload) => ({
  type: SET_TEAM,
  payload,
});

export const setSelectedTeamMember = (payload) => ({
  type: SET_SELECTED_TEAM_MEMBER,
  payload,
});

export const addTeamMember = (payload) => ({
  type: ADD_TEAM_MEMBER,
  payload,
});

export const updateTeamMemberData = (payload) => ({
  type: UPDATE_TEAM_MEMBER_DATA,
  payload,
});

export const deleteTeamMemberData = (uuid) => ({
  type: DELETE_TEAM_MEMBER,
  payload: { uuid },
});

export const setTeamMemberToBeVisualized = (payload) => ({
  type: SET_TEAM_MEMBER_TO_BE_VISUALIZED,
  payload,
});
