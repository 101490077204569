import { STORE_METRICS, ADD_METRICS } from '../../actions/actiontypes';

const initialState = {
  metrics: { usersCount: 0, interactivityCount: 0, datasetCount: 0, publicationCount: 0 },
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case STORE_METRICS: { 
      return {
        metrics: action.payload
      }
    }

    case ADD_METRICS: {
      return {
        ...state.metrics,
        ...action.payload
      }
    }
    
    default: return state
  }
}

export default reducer;