/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { NavLink as Link, useHistory } from 'react-router-dom';
import moment from "moment";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SideMenu from '../../common/SideMenu.jsx';
import UploadComponent from '../../common/Upload.jsx';
// import UserAvatar from '../../common/UserAvatar.jsx';
import DashboardNav from '../component/DashboardNav.js';

import Pagination from "../../../common/components/Pagination";

import {
	setAllInteractiveAnalysis,
	setSelectedInteractiveAnalysis
} from "../../../store/actions/actions/interactiveAnalysis";

import { fetchAllInteractiveAnalysis } from "../../../utils/api/interactiveAnalysis";

import { errorHandler } from "../../../utils/helpers/errorHandler";

const InteractiveAnalysisPage = (props) => {
	const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

	const interactiveAnalysisFromTheStore = useSelector(state => state.interactiveAnalysis.interactive_analysis);

	const dispatch = useDispatch();
	const history = useHistory();
	const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));

	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);
	const [interactiveAnalysis, setInteractiveAnalysis] = useState([]);
	const [searchResult, setSearchResult] = useState([]);

	const handleFetchInteractiveAnalysis = async (page) => {
		try {
			const data = await fetchAllInteractiveAnalysis(token, page);

			if (data.statusCode === 401) {
        setTimeout(() => {
          toast.error("Your Token has Expired please login to continue");
        }, 200);
        localStorage.removeItem('eko-360-p2-user');
        history.push("/login");
      }

			setPage(data.page);
      setMaxPage(data.count);
      setInteractiveAnalysis(data.allInteractivities);
      dispatch(setAllInteractiveAnalysis(data.allInteractivities));
		} catch (error) {
			errorHandler(error, toast);
		}
		
	}

	useEffect(() => {
		if(interactiveAnalysisFromTheStore && !(interactiveAnalysisFromTheStore.length === 1)) {
			handleFetchInteractiveAnalysis();
		}
	}, []);

	useEffect(() => {
		window.$('#openSidebar').click(function(){
		  window.$('.leftbar').addClass('biger');
		  });
		  window.$('#closebtn').click(function(){
			  window.$('.leftbar').removeClass('biger');
		  });
	  },[])

  useEffect(() => {
		if(interactiveAnalysisFromTheStore && interactiveAnalysisFromTheStore.length > 0) {
      setInteractiveAnalysis(interactiveAnalysisFromTheStore);
    }
  },[interactiveAnalysisFromTheStore]);

	const handleNextPagination = async (event) => {
    event.preventDefault();
    await handleFetchInteractiveAnalysis(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await handleFetchInteractiveAnalysis(page - 2);
  }

	const handleSelectInteractiveAnalysis = (data) => {
		dispatch(setSelectedInteractiveAnalysis(data));
	}

	const handleSearch = (event) => {
    event.preventDefault();

    let searchWord = event.target.value.toLowerCase();
    const interactiveAnalysisClone = [...interactiveAnalysis];
    const searchResult = interactiveAnalysisClone.filter(data => data.title.toLowerCase().includes(searchWord));

    setSearchResult(searchResult);
  }

  const title = "Interactive analysis"
	
	return (
		<>
			<div className='section body'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-2'>
							<SideMenu />
						</div>

						<div className='col-md-10'>

							<DashboardNav 
								page={title}
							/>
							

							<section>
								<div className='__house'>
									<div className='__btcard'>
										<div className='__btcardin'>
											<div className='row'>
												<div className='col-lg-9 col-md-8 col-sm-8'>
													<p className='__btp'>
														All Analysis
													</p>
												</div>
												<div className='col-lg-3 col-md-4 col-sm-4'>
													<form>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control __btform'
                                placeholder='Search by title'
                                onChange={handleSearch}
                              />
                            </div>
                          </form>
												</div>
											</div>
										</div>
										<div className='table-responsive'>
											<table className='table __table table-striped'>
												<thead>
													<tr>
														<th>Name</th>
														<th>Uploaded date</th>
														<th>View</th>
														<th>Download</th>
														<th>Status</th>
													</tr>
												</thead>
												<tbody>
													{interactiveAnalysis.length > 0 && searchResult.length === 0 ? 
													(interactiveAnalysis.map(data => (
														<tr key={data.uuid} onClick={() => handleSelectInteractiveAnalysis(data)}>
															<td>
																<span>Name</span>
																<Link to={`/admin/dashboard/interactive-analysis/${data.uuid}/detail`}>
																	<p className='__tablp'>
																		{data.title}
																	</p>
																</Link>
															</td>
															<td>
																<span>
																	Uploaded date
																</span>
																<Link to={`/admin/dashboard/interactive-analysis/${data.uuid}/detail`}>
																	<p className='__tablp'>
																	{moment(data.createdAt).format('LLLL')}
																	</p>
																</Link>
															</td>
															<td>
																<span>View</span>
																<Link to='/admin/dashboard/interactive-analysis/detail'>
																	<p className='__tablp'>
																		0
																	</p>
																</Link>
															</td>
															<td>
																<span>Download</span>
																<Link to={`/admin/dashboard/interactive-analysis/${data.uuid}/detail`}>
																	<p className='__tablp'>
																		0
																	</p>
																</Link>
															</td>
															<td>
																<span>Status</span>
																	{data.status === 'draft' && (<p
																		className='__allstatus btn'
																		style={{ color: '#464646', background: '#F0F0F0' }}>draft
																	</p>)}

                                  {data.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{data.status}</p>)}

                                  {data.status === 'declined' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Rejected</p>)}
															</td>
														</tr>
													))) : (
														searchResult.length > 0 && searchResult.map(data => (
																<tr key={data.uuid}>
																	<td>
																		<span>Name</span>
																		<Link to={`/admin/dashboard/interactive-analysis/${data.uuid}/detail`}>
																			<p className='__tablp'>
																				{data.title}
																			</p>
																		</Link>
																	</td>
																	<td>
																		<span>
																			Uploaded date
																		</span>
																		<Link to={`/admin/dashboard/interactive-analysis/${data.uuid}/detail`}>
																			<p className='__tablp'>
																			{moment(data.createdAt).format('LLLL')}
																			</p>
																		</Link>
																	</td>
																	<td>
																		<span>View</span>
																		<Link to={`/admin/dashboard/interactive-analysis/${data.uuid}/detail`}>
																			<p className='__tablp'>
																				0
																			</p>
																		</Link>
																	</td>
																	<td>
																		<span>Download</span>
																		<Link to={`/admin/dashboard/interactive-analysis/${data.uuid}/detail`}>
																			<p className='__tablp'>
																				0
																			</p>
																		</Link>
																	</td>
																	<td>
																		<span>Status</span>
																			{data.status === 'draft' && (<p
																				className='__allstatus btn'
																				style={{ color: '#464646', background: '#F0F0F0' }}>draft
																			</p>)}

																			{data.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{data.status}</p>)}

																			{data.status === 'declined' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Rejected</p>)}
																	</td>
																</tr>
													)))}
												</tbody>
											</table>
											{interactiveAnalysis.length === 0 && 
												<p style={{textAlign: "center" }}>There are no interactive analysis yet</p>}	
										</div>

											<Pagination
												page={+page}
												maxPage={+maxPage}
												SIZE_PER_PAGE={+SIZE_PER_PAGE}
												handleNextPagination={handleNextPagination}
												handlePreviousPagination={handlePreviousPagination}
											/>

									</div>

									<div className='__spacet7p'></div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>

			<ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>

			<UploadComponent />
		</>
	)
}

export default InteractiveAnalysisPage;
