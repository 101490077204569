import {
  SET_PUBLICATIONS,
  SET_SELECTED_PUBLICATION,
  ADD_PUBLICATION,
  UPDATE_PUBLICATION_STATUS,
  UPDATE_PUBLICATION_DATA,
  DELETE_PUBLICATION,
  SET_PUBLICATION_TO_BE_VISUALIZED
} from '../../actions/actiontypes';

const initialState = {
  publications: [],
  selected_publication: null,
  publication_to_be_visualized: null
}

const publicationReducer = (state = initialState, action) => {
  switch(action.type) {

  case SET_PUBLICATIONS: 
    return {
      ...state,
      publications: action.payload
    }
  case SET_SELECTED_PUBLICATION: {
    return {
      ...state,
      selected_publication: action.payload
    }
  }

  case ADD_PUBLICATION: {
    const publications = [...action.payload, ...state.publications];
    return {
      ...state,
      publications
    }
  }

  case UPDATE_PUBLICATION_STATUS: {
    const index = state.publications.findIndex(data => data.uuid === state.selected_publication.uuid);
      const updateSelectedPublication = {...state.selected_publication, status: action.payload.status};
      state.publications.splice(index, 1, updateSelectedPublication);
      return {
        ...state,
        publications: state.publications,
      }
  }

  case UPDATE_PUBLICATION_DATA: {
    const index = state.publications.findIndex(data => data.uuid === state.selected_publication.uuid);
    state.publications.splice(index, 1, action.payload);
    return {
      ...state,
      publications: state.publications,
      selected_publication: action.payload
    }
  }

  case DELETE_PUBLICATION: {
    const index = state
    .publications
    .findIndex(data => data.uuid === state.selected_publication.uuid);
    
    state.publications.splice(index, 1);
    return {
      ...state,
      publications: state.publications,
      selected_publication: null
    }
  }

  case SET_PUBLICATION_TO_BE_VISUALIZED: {
    return {
      ...state,
      publication_to_be_visualized: action.payload
    }
  }

    default: return state;
  }
}

export default publicationReducer;