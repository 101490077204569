import React from 'react';
import {Link} from 'react-router-dom';



const Footer = () => {
    const today = new Date();
  return (
    <footer className="__foter">
		<div className="container">
			<div className='row'>
				<div className='col-xl-4 col-lg-4 col-md-12'>
					<img src="/assets/img/logo.png" className="__fotimg" />
					<p className='__copyright'>Copyright © {today.getFullYear()}</p>
				</div>
				<div className='col-xl-8 col-lg-8 col-md-12'>
					<div className="row">
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
							<p className="__fotr"><Link to="/about/mission-and-vision">Our Vision</Link></p>
							<p className="__fotr"><Link to="/faqs">FAQs</Link></p>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
							<p className="__fotr"><Link to="/about/mission-and-vision">Our Mission</Link></p>
							<p className="__fotr"><Link to="/submit-dataset">Share Data</Link></p>
							
						</div>
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
							<p className="__fotr"><Link to="/about/mission-and-vision">Core Functions</Link></p>
							<p className="__fotr"><Link to="/web-resources">Web Resources</Link></p>
						</div>

						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
							<p className="__fotr"><Link to="/terms-of-use/legal">Privacy and Terms of Use</Link></p>
							<p className="__fotr"><Link to="/contact">Support and Contact Us</Link></p>
						</div>
						
					</div>
				</div>
			</div>
			<p className='__copyrightm'>Copyright © {today.getFullYear()}</p>
		</div>
	</footer>
  )
};


export default Footer;