import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';
// import DownloadApp from "../../common/components/DownloadApp";


const WebResources = () => {

  return (
    <>
      <header>
      <Nav/>
      </header>

      <section>
        <div className="container">
          <div className='__all-banner' style={{background: `url(/assets/img/pagesbanner.png)`}}>
            <p className="__caption-banner text-center">Web Resources</p>
          </div>
          <div className="__spacet5p"></div>
          <table className="table table-striped table-bordered">
              <tbody>
                  <tr>
                      <td><a className="table-link" href="https://lagosstate.gov.ng/" target="_blank" rel="noreferrer">Lagos State Government Website</a></td>
                  </tr>
                  <tr>
                      <td><a className="table-link" href="https://mepb.lagosstate.gov.ng/" target="_blank" rel="noreferrer">Ministry of Economic Planning and Budget (MEPB)</a></td>
                  </tr>
                  <tr>
                      <td><a className="table-link" href="https://lagosmepb.org/lagos-bureau-of-statistics/" target="_blank" rel="noreferrer">Lagos Bureau of Statistics</a></td>
                  </tr>
                  <tr>
                      <td><a className="table-link" href="https://lagosstate.gov.ng/vital-data-lagos-bureau-of-statistics-2/" target="_blank" rel="noreferrer">Vital Data (Lagos Bureau of Statistics)</a></td>
                  </tr>
                  <tr>
                      <td><a className="table-link" href="https://lagosmepb.org/wp-content/uploads/2021/05/Y2021-APPROPIATION-BILL-1.pdf" target="_blank" rel="noreferrer">Lagos State 2021 Appropriation Bill</a></td>
                  </tr>
                  <tr>
                      <td><a className="table-link" href="https://mepb.lagosstate.gov.ng/principal-officers/" target="_blank" rel="noreferrer">Principal Officers MEPB</a></td>
                  </tr>
              </tbody>
          </table>
          <div className="__spacet10p"></div>
        </div>

        {/* <DownloadApp /> */}
      </section>
      <Footer />
    </>
  )
}

export default WebResources;