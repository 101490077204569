import {
  SET_TEAM,
  SET_SELECTED_TEAM_MEMBER,
  ADD_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_DATA,
  DELETE_TEAM_MEMBER,
  SET_TEAM_MEMBER_TO_BE_VISUALIZED,
} from "../../actions/actiontypes";

const initialState = {
  team: [],
  selected_team_member: null,
  team_member_to_be_visualized: null,
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM:
      return {
        ...state,
        team: action.payload,
      };
    case SET_SELECTED_TEAM_MEMBER: {
      return {
        ...state,
        selected_team_member: action.payload,
      };
    }

    case ADD_TEAM_MEMBER: {
      const team = [...action.payload, ...state.team];
      return {
        ...state,
        team,
      };
    }

    case UPDATE_TEAM_MEMBER_DATA: {
      const index = state.team.findIndex(
        (data) => data.uuid === state.selected_team_member.uuid
      );
      state.team.splice(index, 1, action.payload);
      return {
        ...state,
        team: state.team,
        selected_team_member: action.payload,
      };
    }

    case DELETE_TEAM_MEMBER: {
      const index = state.team.findIndex(
        (data) => data.uuid === state.selected_team_member.uuid
      );

      state.team.splice(index, 1);
      return {
        ...state,
        team: state.team,
        selected_team_member: null,
      };
    }

    case SET_TEAM_MEMBER_TO_BE_VISUALIZED: {
      return {
        ...state,
        team_member_to_be_visualized: action.payload,
      };
    }

    default:
      return state;
  }
};

export default teamReducer;
