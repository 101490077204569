import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const fetchAllUsers = async (token, page) => {
  try {
    const {data: {data}} = await axios({
      method: 'GET',
      url: `${baseUrl}/users?page=${page}`,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const updateUSerStatus = async (uuid, status, token) => {
  try {
    const {data } = await axios({
      method: 'PUT',
      url: `${baseUrl}/users/${uuid}`,
      data: { status },
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}