import React from 'react';

import SideMenu from '../../common/SideMenu';
import UploadComponent from '../../common/Upload.jsx';
import UserAvatar from '../../common/UserAvatar.jsx';

const NotificationsPage = () => (
  <>
  <div className="section body">
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-2">
          <SideMenu />
				</div>

				<div className="col-md-10">
					<nav className="navbar __dashnavbar" role="navigation">
						<div className="container-fluid">
							<div className="navbar-header">
								<button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
									<span className="sr-only">Toggle navigation</span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
								<a href="#" className="navbar-brand __navbar-brand1" id="openSidebar"><i className=" fa fa-bars"></i> <span className="hidden-sm">Notifications</span></a>
								{/* <a href="#" className="navbar-brand __navbar-brand1" id="openSidebar"><i className=" fa fa-bars" onclick="openSidebar()" ></i> <span className="hidden-sm">Notifications</span></a> */}
							</div>

							<div className="collapse navbar-collapse navbar-ex1-collapse">
								<ul className="nav navbar-nav">
									<p className="__dasovp">Notifications</p>
								</ul>
								<ul className="nav navbar-nav navbar-right">
									<UserAvatar />
								</ul>
							</div>
						</div>
					</nav>

					<section>
						<div className="__house">
							<div className="__btcard">
								<div className="table-responsive">
									<table className="table __table table-striped">
										<tbody>
											<tr>
												<td>
													<i className="fas fa-circle __acno"></i>
												</td>

												<td>
													<p className="__notpg">Data Submission for change in number of children immunized.</p>

													<p className="__notpg1">Submitted: 13th February, 2020: 04:35 PM</p>
												</td>
											</tr>

											<tr>
												<td>
													<i className="fas fa-circle __acno"></i>
												</td>

												<td>
													<p className="__notpg">Data Submission for change in number of children immunized.</p>

													<p className="__notpg1">Submitted: 13th February, 2020: 04:35 PM</p>
												</td>
											</tr>

											<tr>
												<td>
													<i className="fas fa-circle __acno1"></i>
												</td>

												<td>
													<p className="__notpg">Data Submission for change in number of children immunized.</p>

													<p className="__notpg1">Submitted: 13th February, 2020: 04:35 PM</p>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="__btcardin">
									<div className="row">
										<div className="col-lg-9 col-md-8 col-sm-8"></div>
										<div className="col-lg-3 col-md-4 col-sm-4">
											<p className="__datanxta">1 of 45&nbsp;&nbsp; <a href=""><i className="fas fa-angle-left"></i></a>&nbsp;&nbsp;&nbsp;&nbsp; <a href=""><i className="fas fa-angle-right"></i></a>&nbsp;&nbsp; Row per page: <b>15</b></p>
										</div>
									</div>
								</div>
							</div>

							<div className="__spacet7p"></div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>

  <UploadComponent />
</>
);

export default NotificationsPage;