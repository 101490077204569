import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setAllTeam } from "../../../store/actions/actions/team/index.js";
import { fetchAllTeamMembers } from "../../../utils/api/team/index.js";

import SideMenu from "../../common/SideMenu";
import UploadComponent from "../../common/Upload.jsx";
import DashboardNav from "../component/DashboardNav";

import Pagination from "../../../common/components/Pagination";
import { errorHandler } from "../../../utils/helpers/errorHandler";

import {
  deleteTeamMember,
  updateTeamMember,
} from "../../../utils/api/team/index.js";

const UsersPage = (props) => {
  const SIZE_PER_PAGE =
    `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
      ? 20
      : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

  const storedTeam = useSelector((state) => state.team.team);
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);
  const [localTeamMembers, setLocalTeamMembers] = useState([]);

  const [searchResult, setSearchResult] = useState([]);
  const [selectedMember, setSelectedMember] = useState(undefined);

  const [memberName, setMemberName] = useState("");
  const [memberPosition, setMemberPosition] = useState("");
  const [memberOffice, setMemberOffice] = useState("");
  const [memberImage, setMemberImage] = useState(undefined);
  const [showTeamLoader, setShowTeamLoader] = useState(false);

  const [showDeleteTeamLoader, setShowDeleteTeamLoader] = useState(false);

  const handleFetchAllTeamMembers = async () => {
    try {
      const data = await fetchAllTeamMembers(page);

      setPage(data.page);
      setMaxPage(data.count);
      setLocalTeamMembers(data.allTeamMembers);
      dispatch(setAllTeam(data.allTeamMembers));
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  const resetModalForm = () => {
    setTimeout(() => {
      window.$(".hidden1").hide();
      window.$(".__hidden2").show();
      window.$(".show1").show();
      window.$(".hidden2").hide();
      window.$(".show4").show();
    }, 300);
  };

  useEffect(() => {
    if (storedTeam.length === 0) {
      handleFetchAllTeamMembers();
    } else {
      setLocalTeamMembers(storedTeam);
    }
  }, [storedTeam]);

  useEffect(() => {
    window.$("#openSidebar").click(function () {
      window.$(".leftbar").addClass("biger");
    });
    window.$("#closebtn").click(function () {
      window.$(".leftbar").removeClass("biger");
    });
  }, []);

  const resetTeamForm = () => {
    setMemberImage(undefined);
    setMemberPosition("");
    setMemberOffice("");
    setMemberName("");
  };

  const handleUpdateTeamMember = async (event) => {
    event.preventDefault();
    console.log("memberImage", memberImage);

    setShowTeamLoader(true);
    try {
      const formData = new FormData();

      formData.append("name", memberName);
      formData.append("position", memberPosition);
      formData.append("office", memberOffice);
      formData.append("image", memberImage);

      const data = await updateTeamMember(
        formData,
        selectedMember?.uuid,
        token
      );

      const indexToUpdate = localTeamMembers.findIndex(
        (member) => member.uuid === selectedMember?.uuid
      );

      localTeamMembers.splice(indexToUpdate, 1, data.data);

      toast.success(`${data.message}`);
      resetTeamForm();
      setShowTeamLoader(false);
      document.getElementById("modal-editTeam").click();
    } catch (error) {
      console.log(error);
      setShowTeamLoader(false);
      errorHandler(error, toast);
    }
  };

  const handleSetMember = (member) => {
    setSelectedMember(member);
    setMemberName(member?.name);
    setMemberPosition(member?.position);
    setMemberOffice(member?.office);
    setMemberImage(member?.image);
  };

  const handleDeleteTeamMember = async (event) => {
    event.preventDefault();
    setShowDeleteTeamLoader(true);
    try {
      const data = await deleteTeamMember(selectedMember?.uuid, token);

      const indexToRemove = localTeamMembers.findIndex(
        (member) => member.uuid === selectedMember?.uuid
      );

      localTeamMembers.splice(indexToRemove, 1);

      toast.success(`${data.message}`);
      resetTeamForm();
      setShowDeleteTeamLoader(false);
      document.getElementById("modal-deleteTeam").click();
    } catch (error) {
      setShowDeleteTeamLoader(false);
      errorHandler(error, toast);
    }
  };

  const handleNextPagination = async (event) => {
    event.preventDefault();
    await fetchAllTeamMembers(+page);
  };

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await fetchAllTeamMembers(page - 2);
  };

  const title = "Team page";

  return (
    <>
      <div className="member body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              <SideMenu />
            </div>

            <div className="col-md-10">
              <DashboardNav page={title} />

              <div>
                <div className="__house">
                  <div className="__btcard">
                    <div className="__btcardin">
                      <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-8">
                          <p className="__btp">Team members</p>
                        </div>
                        <div className="col-lg-5 col-md-3 col-sm-3"></div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table __table table-striped">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Office</th>
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {localTeamMembers.length > 0 &&
                          searchResult.length === 0
                            ? localTeamMembers.map((member) => (
                                <tr key={member.uuid}>
                                  <td>
                                    <span>Name</span>
                                    <p className="__tablp">{member.name}</p>
                                  </td>
                                  <td>
                                    <span>Position</span>
                                    <p className="__tablp">{member.position}</p>
                                  </td>
                                  <td>
                                    <span>Office</span>
                                    <p className="__tablp">{member.office}</p>
                                  </td>
                                  <td>
                                    <span>Image</span>
                                    <img
                                      src={member.image}
                                      style={{
                                        height: "70px",
                                        width: "70px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                      }}
                                      className="__tablp"
                                      alt="team member"
                                    />
                                  </td>

                                  <td>
                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#modal-deleteTeam"
                                      onClick={() => handleSetMember(member)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        style={{
                                          color: "#ff00009e",
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </button>
                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#modal-editTeam"
                                      onClick={() => handleSetMember(member)}
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                        style={{
                                          color: "#07A0AB",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </button>
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                      {localTeamMembers.length === 0 && (
                        <p style={{ textAlign: "center" }}>
                          There are no Sections yet
                        </p>
                      )}
                    </div>
                    <Pagination
                      page={+page}
                      maxPage={+maxPage}
                      SIZE_PER_PAGE={+SIZE_PER_PAGE}
                      handleNextPagination={handleNextPagination}
                      handlePreviousPagination={handlePreviousPagination}
                    />
                  </div>

                  <div className="__spacet7p"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-editTeam"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close del-page-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetTeamForm()}
              >
                &times;
              </button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps1">Team</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form>
                    <div className="__hidden2">
                      <div className="form-group">
                        <label className="__modlab">Member Name</label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          value={memberName}
                          onChange={(event) =>
                            setMemberName(event.target.value)
                          }
                        />
                      </div>
                      <div className="row"></div>

                      <div className="form-group">
                        <label className="__modlab">Member role</label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="e.g Personal Assistant"
                          value={memberPosition}
                          onChange={(event) =>
                            setMemberPosition(event.target.value)
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label className="__modlab">Member Office</label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="e.g Ministry of Education"
                          value={memberOffice}
                          onChange={(event) =>
                            setMemberOffice(event.target.value)
                          }
                        />
                      </div>

                      <div className="__spacet7p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <button
                            data-toggle="modal"
                            className="btn btn-block __autbtn"
                            onClick={handleUpdateTeamMember}
                          >
                            Save Member
                            {showTeamLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className=" __it" style={{ marginTop: "35px" }}>
                    <div className="uploadDoc" style={{ marginBottom: "20px" }}>
                      <div className="fileUpload btn btn-orange p-0">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        <span className="__upl">
                          Upload{" "}
                          <span>
                            {memberImage ? memberImage.name : "Image"}
                          </span>
                        </span>
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          onChange={(event) =>
                            setMemberImage(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-deleteTeam"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close del-page-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="row __flexsmaila">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 __mobidown"
                  style={{ paddingTop: "1.5rem" }}
                >
                  <p>
                    Are you sure you want to delete this Team member -{" "}
                    <strong>{selectedMember?.name}</strong>?
                  </p>
                  <form
                    style={{
                      marginTop: "1.5rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="btn __autbtn"
                      onClick={handleDeleteTeamMember}
                      style={{
                        width: "120px",
                        marginRight: "15px",
                        marginTop: "2rem",
                      }}
                    >
                      Delete
                      {showDeleteTeamLoader && (
                        <img src="/assets/img/loader.gif" alt="" />
                      )}
                    </button>
                    <button
                      className="btn __dnbtn1"
                      style={{
                        width: "120px",
                        backgroundColor: "#fff",
                        border: "1px solid #0C9EA9",
                      }}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UploadComponent />

      <ToastContainer closeOnClick pauseOnHover></ToastContainer>
    </>
  );
};

export default UsersPage;
