import React from 'react';
import {NavLink as Link} from 'react-router-dom';

const Header = () => {
const user = localStorage.getItem('eko-360-p2-user') || null;

return (
    <>
    <div class="collapse navbar-collapse" id="navmenu">
        <a href="/">
            <img src="/assets/img/lagos-logo.png" alt="" className="__laglog" />
            <img src="/assets/img/logo-alt.png" className="__logoimg_alt" />
        </a>
        <ul class="navbar-nav ms-auto __neul">
           <Link activeClassName="__navlinka" exact to="/" className="__navlink"> <li>Search</li></Link>
           <li class="dropdown __navlink">
              <a class=" dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Explore
              </a>
              <ul class="dropdown-menu  __dropdown-m" aria-labelledby="navbarDropdown">
                  <li><Link activeClassName="__navlinkaq" to="/interactivity" className="__navlink1">Interactive Analysis</Link></li>
                  <li><Link activeClassName="__navlinkaq" to="/slice-and-dice" className="__navlink1">Slice & Dice</Link></li>
                  {/* <li><Link to="/question-logic" activeClassName="__navlinkaq" className="__navlink1">Business Decision Helper</Link></li> */}
              </ul>
            </li>
            <Link activeClassName="__navlinka" to="/submit-dataset" className="__navlink"><li>Submit Dataset</li></Link>
            <Link activeClassName="__navlinka" to="/publications" className="__navlink"><li>Publications</li></Link>
            <Link activeClassName="__navlinka" to="/public/datasets" className="__navlink"><li>Public Data</li></Link>
            
            <Link activeClassName="__navlinka" to="/about/mission-and-vision" className="__navlink"><li>About</li></Link>
            <Link activeClassName="__navlinka" to="/faqs" className="__navlink"><li>FAQs</li></Link>
            
            {/* {user && (<Link activeClassName="__navlinka" to="/admin/dashboard" className="__navlink"><li>Dashboard</li></Link>)} */}
            {!user && (
                <>
                {/* <li><Link activeClassName="__navlinka" to="/login" className="__navlinkbtn1 hvr-shutter-in-vertical">Sign in</Link></li> */}
                </>
            )}
        </ul>
    </div>
      {/* <div className="collapse navbar-collapse navbar-ex1-collapse">
        <a href="/">
          <img src="/assets/img/lagos-logo.png" alt="" className="__laglog" />
          <img src="/assets/img/logo.png" className="__logoimg_alt" />
        </a>
        <ul className="nav navbar-nav navbar-right">
          <li><Link activeClassName="__navlinka padding-top" exact to="/" className="__navlink">Search</Link></li>
          <li className="dropdown __navlink" style={{ marginTop: "1.5rem" }}>
            <span>Explore<span className="caret"></span></span>
            <ul className="dropdown-menu __dropdown-m">
              <li><Link activeClassName="__navlinkaq" to="/interactivity" className="__navlink1">Interactive Analysis</Link></li>
              <li><Link activeClassName="__navlinkaq" to="/slice-and-dice" className="__navlink1">Slice & Dice</Link></li>
              <li><Link to="/question-logic" activeClassName="__navlinkaq" className="__navlink1">Business Decision Helper</Link></li>
            </ul>
          </li>
          <li><Link activeClassName="__navlinka" to="/publications" className="__navlink">Publications</Link></li>
          <li><Link activeClassName="__navlinka" to="/faqs" className="__navlink">FAQs</Link></li>
          <li><Link activeClassName="__navlinka" to="/submit-dataset" className="__navlink">Submit Dataset</Link></li>
          <li><Link activeClassName="__navlinka" to="/about" className="__navlink">About</Link></li>
          <li><Link activeClassName="__navlinka" to="/public" className="__navlink">Public Data</Link></li>
          {user && (<li><Link activeClassName="__navlinka" to="/admin/dashboard" className="__navlink">Dashboard</Link></li>)}
          {!user && (
            <>
              <li><Link activeClassName="__navlinka" to="/login" className="__navlinkbtn1 hvr-shutter-in-vertical">Sign in</Link></li>
            </>
          )}
        </ul>
      </div> */}
    </>
  )
}

export default Header;