import React from 'react';

import "./chip.css";

const Chip = ({label, removeChip}) => {
  return (
    <>
      <span className="chip">
        {label}
        {removeChip && <i
          className="fa fa-times"
          style={{ paddingLeft: "5px", cursor: "pointer"}}
          onClick={removeChip}
        ></i>}
      </span>
    </>
  )
}

export default Chip;