import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const fetchAllMetrics = async (token) => {
  try {
    const {data: { data } } = await axios(`${baseUrl}/metrics`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    
    return data;
  } catch (error) {
    throw error.response.data;
  }
}