import React, { useEffect, useState } from 'react';
import { NavLink as Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { saveAs } from 'file-saver';

// import Header from '../../../common/components/Header';
import Nav from '../../../common/components/Nav';
import Footer from '../../../common/components/Footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DownloadApp from "../../../common/components/DownloadApp";
import { errorHandler } from "../../../utils/helpers/errorHandler";

import { fetchQuestion } from "../../../utils/api/questionLogic";
import Chip from "../../../admin/common/chip";

const QuestionOverview = () => {
  const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;

  const question = useSelector(state => state.question.selected_question);
  const { uuid } = useParams();

  const [showPageLoader, setShowPageLoader] = useState(true);
  const [ selectedQuestion, setSelectedQuestion ] = useState(undefined);

  const handleFetchQuestion = async () => {
    try {
      const data = await fetchQuestion(uuid);

      setSelectedQuestion(data);
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  useEffect(() => {
    if(!question) {
      handleFetchQuestion();
    } else {
      setSelectedQuestion(question);
    }
  }, [question]);


  const handleFileDownload = async (file) => {
    try {
      const fileName = file.substr(file.lastIndexOf('/') + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/publications/${fileName}/download/`,
        {
          headers: { 'Content-Type': 'application/json' }
        });

      const blob = await res.blob()
      saveAs(blob, fileName);
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  return (
    <>
      <header>
        <div className="__landbgex">
          <Nav/>
          <div className="container">
          <div class="row">
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                <p className="__captionex text-center">Business Decision Helper</p>
                  <p className="__captionex1 text-center">Go through our rich library of decision logic data to aid your business in Lagos.</p>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
            </div>
          </div>
        </div>
      </header>



      <section>
        <div className="container">

          <div className="__spacet5p"></div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <img src="/assets/img/sideimg1.png" className="__latflotimg hidden-xs" alt="" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 text-right">
              <img src="/assets/img/sideimg.png" className="__latflotimg1 hidden-xs" alt="" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
                <section className="container">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                      <form>
                        <div className="form-group">
                          <label htmlFor="" className="__modlab">Question</label>
                          <p>{selectedQuestion?.question}</p>
                        </div>

                        <div className="form-group">
                          <label htmlFor="" className="__modlab">Findings</label>
                          <p>{selectedQuestion?.findings}</p>
                        </div>

                        <div className="form-group">
                          <label htmlFor="" className="__modlab">Description</label>
                          <p>{selectedQuestion?.description}</p>
                        </div>

                        <div className="form-group">
                          <label htmlFor="" className="__modlab">Keywords</label>
                          <p>{selectedQuestion && selectedQuestion.keywords.split(',').map(keyword => (
                            <>
                              {keyword.length > 0 && <Chip label={keyword} />}
                            </>
                          )) }</p>
                          {!selectedQuestion?.keywords[0] && <p className="text small text-muted">There are no keywords.</p>}
                        </div>

                          <div className="col-lg-12 col-md-12 col-sm-12 __mobiup">
                            <div className="__spacet7p"></div>
                            <div className=" __it">
                              <div className="uploadDoc">
                                <div className="fileUpload btn btn-orange">
                                  {selectedQuestion?.type === 'iframe' &&
                                    <div>
                                      <iframe
                                        className="__ifrmv"
                                        src={selectedQuestion?.analytics}
                                        frameBorder="0"
                                        allowFullScreen="true"
                                        title="powerBi"
                                      ></iframe>
                                    </div>
                                  }
                                  {selectedQuestion?.type === 'file' &&
                                    <>
                                      <img src="/assets/img/link-icon.svg" className="icon" alt="" />
                                      <br /><br />
                                      <span style={{ color: "#07A0AB" }}>
                                        <a
                                          href={selectedQuestion?.analytics.startsWith('/') ?`${rootUrl}/${selectedQuestion?.analytics.substr(selectedQuestion?.analytics.lastIndexOf('/')+1)}` : `${selectedQuestion?.analytics}`}
                                          target="_blank"
                                        >
                                          Click to preview file
                                        </a>
                                    </span>
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                      </form>
                  </div>
                  <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
                      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                      <p class="text-center __disclaimer">Inference and decisions made by business owners from interacting with this page are strictly at owners risk. The Lagos State Government will not be responsible for any decision made by users after interacting with the information on this page.</p>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
                    </div>
                </section>


              </div>
            </div>
            <div className="col-lg-2 col-md-1 col-sm-1"></div>
          </div>
        </div>
        <div className="__spacet5p"></div>
        <DownloadApp />
      </section>
      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
      <Footer />
    </>
  )
}

export default QuestionOverview;