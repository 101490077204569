import React from "react";

const Pagination = ({
  maxPage,
  SIZE_PER_PAGE,
  page,
  handleNextPagination,
  handlePreviousPagination
}) => {
  
  return (
    <>
      <div className='__btcardin'>
        <div className='row'>
          <div className='col-lg-9 col-md-8 col-sm-8'></div>
          <div className='col-lg-3 col-md-4 col-sm-4'>
            {Math.ceil(maxPage/SIZE_PER_PAGE) > 1 ?
            (
              <p className='__datanxta'>
                {page} of {Math.ceil(maxPage/SIZE_PER_PAGE) > 1 
                ? Math.ceil(maxPage/+SIZE_PER_PAGE) : 1} &nbsp;&nbsp;
                <a>
                  {(SIZE_PER_PAGE * page > maxPage) || (page > 1) ? (
                    <i className='fas fa-angle-left' style={{ cursor: "pointer"}} onClick={handlePreviousPagination}></i>
                  ): null}
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a>
                {((SIZE_PER_PAGE * page) < maxPage) && 
                (<i className='fas fa-angle-right' style={{ cursor: "pointer"}} onClick={handleNextPagination}></i>)}
                </a>
                &nbsp;&nbsp; Row per page:&nbsp;
                <b>{SIZE_PER_PAGE}</b>
              </p>
            ): null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Pagination;