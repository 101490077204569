import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const fetchCofogs = async () => {
  try {
    const { data } = await axios(`${baseUrl}/slice-dices/cofogs`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllLocations = async (query) => {
  try {
    const { data } = await axios(`${baseUrl}/slice-dices/cofogs/locations?${query}`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchDataTables = async (query) => {
  try {
    const { data } = await axios(`${baseUrl}/slice-dices/data-tables?${query}`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchGraphNodes = async (query) => {
  try {
    const { data } = await axios(`${baseUrl}/slice-dices/nodes?${query}`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const uploadSliceAndDice = async (file, token) => {
  try {
    const { data } = await axios({
      url: `${baseUrl}/slice-dices/upload`,
      method: 'POST',
      data: file,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}