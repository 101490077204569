import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const createTeamMember = async (team, token) => {
  try {
    const { data } = await axios(`${baseUrl}/team`, {
      method: "POST",
      data: team,
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fetchAllTeamMembers = async (token, page = 0) => {
  try {
    const {
      data: { data },
    } = await axios({
      method: "GET",
      url: `${baseUrl}/team?page=${page}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateTeamMember = async (team, uuid, token) => {
  console.log("team", team);
  try {
    const { data } = await axios(`${baseUrl}/team/${uuid}`, {
      method: "PUT",
      data: team,
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
    });

    return data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
};

export const deleteTeamMember = async (uuid, token) => {
  try {
    const { data } = await axios(`${baseUrl}/team/${uuid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const findTeamMember = async (uuid) => {
  try {
    const {
      data: { data },
    } = await axios({
      method: "GET",
      url: `${baseUrl}/team/${uuid}`,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
};
