import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { saveAs } from 'file-saver';

import SideMenu from '../../../common/SideMenu.jsx';
// import UserAvatar from '../../../common/UserAvatar';
import DashboardNav from "../../component/DashboardNav.js";
import { errorHandler } from "../../../../utils/helpers/errorHandler";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UploadComponent from '../../../common/Upload.jsx';
import "./reset.css";

import {
	acceptPublication,
	updatePublication,
	deletePublication,
	findPublication
} from "../../../../utils/api/publication";

import {
	updatePublicationStatus,
	updatePublicationData,
	deletePublicationData,
	setSelectedPublication
} from "../../../../store/actions/actions/publication";

const PublicationDetailPage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {uuid} = useParams();
	
	const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));
	const storedSelectedPublication = useSelector(state => state.publications.selected_publication);

	const [showPublishLoader, setShowPublishLoader] = useState(false);
	const [showDeclineLoader, setShowDeclineLoader] = useState(false);

	const [comment, setComment] = useState("");

	const [ publicationTitle, setPublicationTitle ] = useState("");
  const [ publicationBody, setPublicationBody ] = useState("");
  const [ publicationIframe, setPublicationIframe ] = useState("");
  const [ publicationBanner, setPublicationBanner ] = useState(undefined);
  const [ publicationFile, setPublicationFile ] = useState(undefined);

	const [
		showUpdatingPublicationLoader,
		setShowUpdatingPublicationLoader
	] = useState(false);

	useEffect(() => {
		if(!storedSelectedPublication) {
			handleFetchSelectedPublication();
		}
	}, []);

	useEffect(() => {
		if(storedSelectedPublication) {
			setPublicationTitle(storedSelectedPublication.title);
			setPublicationBody(storedSelectedPublication.body);
			setPublicationIframe(storedSelectedPublication.iframe || "");
		}
	}, []);

	const handleFetchSelectedPublication = async () => {
		try {
			const data = await findPublication(uuid);
			dispatch(setSelectedPublication(data));
		} catch (error) {
			errorHandler(error, toast);
		}
	}

	const handleAcceptOrDeclineInteractiveAnalysis = async (status) => {
		try {
			let commentToSend = status === "published" ? undefined : comment;
			const data = await acceptPublication({
				status,
				comment: commentToSend,
				uuid: storedSelectedPublication.uuid,
				token
			});

			setTimeout(() => {
				toast.success(`${data.message}`);
      }, 500);

			dispatch(updatePublicationStatus(status));
			setShowDeclineLoader(false);
			setShowPublishLoader(false);
			history.push('/admin/dashboard/publications');
		} catch (error) {
			setShowDeclineLoader(false);
			setShowPublishLoader(false);
			errorHandler(error, toast);
		}
	}
	
	const handleAcceptPublication = async (event) => {
    event.preventDefault();
    setShowPublishLoader(true);
    await handleAcceptOrDeclineInteractiveAnalysis("published");
  }

	const handleDeclinePublication = async (event) => {
		event.preventDefault();
		setShowDeclineLoader(true);
		await handleAcceptOrDeclineInteractiveAnalysis("declined");
	}

	const goBack = (event) => {
		event.preventDefault();
    history.push('/admin/dashboard/publications');
  }

	const handleFileDownload = async (file) => {
    try {
      const fileName = file.substr(file.lastIndexOf('/')+1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/publications/${fileName}/download/`,
        {
          headers: { 'Content-Type': 'application/json', Authorization: `${ token }` 
        }
      });

      const blob = await res.blob()
      saveAs(blob, fileName);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  }

	const handleUpdatePublication = async (event) => {
		event.preventDefault();
		setShowUpdatingPublicationLoader(true);
		try {
			const formData = new FormData();
      formData.append("title", publicationTitle);
      formData.append("body", publicationBody);
      formData.append("banner", publicationBanner);
      formData.append("file", publicationFile);
      if (publicationIframe.trim().length > 0) {
        formData.append("iframe", publicationIframe);
      }
      
      const publication = await updatePublication(formData, storedSelectedPublication.uuid, token);

			setShowUpdatingPublicationLoader(false);
      toast.success(`${publication.message}`);
      dispatch(updatePublicationData(publication.data));
			document.querySelector(".dismiss-pub-update-modal").click();
		} catch (error) {
			setShowUpdatingPublicationLoader(false);
			errorHandler(error, toast);
		}
	}

	const handleDeletePublication = async (event) => {
		event.preventDefault();
		try {
			const data = await deletePublication(storedSelectedPublication.uuid, token);

			setTimeout(() => {
				toast.success(`${data.message}`);
			}, 500);

			history.push("/admin/dashboard/publications");
			dispatch(deletePublicationData(storedSelectedPublication.uuid));
		} catch (error) {
			errorHandler(error, toast);
		}
	}

	const title = "Publication details"

	return (
		<>
			<div className='section body'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-2'>
							<SideMenu />
						</div>

						<div className='col-md-10'>
							<DashboardNav 
								page={title}
							/>
							

							<section>
								<div className='__house'>
									<div className='__btcard'>
										<div className='__spacet3p'></div>
										<div className='__btcardin1'>
											<div className='row'>
												<div className='col-lg-6 col-md-6 col-sm-6'>
													<a href=''>
														<div className='__dascard text-center'>
															<p className='__dasp'>
																Numbers of Views
															</p>
															<p className='__dasp1'>
																0
															</p>
														</div>
													</a>
												</div>
												<div className='col-lg-6 col-md-6 col-sm-6'>
													<a href=''>
														<div className='__dascard text-center'>
															<p className='__dasp'>
																Numbers of Downloads
															</p>
															<p className='__dasp1'>
																0
															</p>
														</div>
													</a>
												</div>
											</div>
											<div className='__spacet2p'></div>
											<div className='row'>
												{storedSelectedPublication && <div className='col-lg-6 col-md-6 col-sm-6'>
													<p className='__subp'>
														Submission Date: {moment(storedSelectedPublication.createdAt).format('LLLL')}
														<br />
														<span className='__subpen'>
															{storedSelectedPublication.status}
														</span>
													</p>
												</div>}
												<div className='col-lg-3 col-md-3 col-sm-3'></div>
												{/* <div className='col-lg-3 col-md-3 col-sm-3'>
													<div className='row'>
														<div className='col-lg-9 col-md-9 col-sm-9 col-xs-9'>
															<p className='__viewown'>
																Owner
															</p>
															<p className='__viewown1'>
																Lagbaja Tamado
															</p>
														</div>
														<div className='col-lg-3 col-md-3 col-sm-3 col-xs-3'>
															<img
																src='/assets/img/clock.jpg'
																className='__viewimg'
															/>
														</div>
													</div>
												</div> */}
											</div>
											<hr />
											<form>
												{storedSelectedPublication && <div className='row __flexsmail'>
													<div className='col-lg-6 col-md-6 col-sm-6 __mobidown'>
														<div className='form-group'>
															<label
																htmlFor=''
																className='__modlab'>
																Publication Name
															</label>
															<p style={{
																display: "flex",
																width: "400px",
															}}>{storedSelectedPublication.title}</p>
														</div>
														
														<div className='form-group' style={{ width: "400px"}}>
															<label
																htmlFor=''
																className='__modlab'>
																Description
															</label>
															<p style={{
																display: "flex",
																width: "400px",
															}}>{storedSelectedPublication.body}</p>
														</div>

														<div className='form-group'>
															<label
																className='__modlab'>
																Comment
															</label>
															<textarea
																style={{ maxWidth: "400px"}}
																className='form-control __regdivform'
																rows='5'
																onChange={event => setComment(event.target.value)}
															></textarea>
														</div>
													</div>

													
														
													<div className='col-lg-6 col-md-6 col-sm-6 __mobiup'>

													<div className='__spacet7p'></div>
													<div className=' __it'>
													{storedSelectedPublication.banner && <div className='uploadDoc'>
															<div
																className='fileUpload btn btn-orange p-0'
																onClick={() => handleFileDownload(storedSelectedPublication.banner)}
															>
																<img
																	src='https://image.flaticon.com/icons/svg/136/136549.svg'
																	className='icon'
																	alt=""
																/>
																<br />
																<span
																	className='__upl'
																	id='upload'>
																	Download&nbsp;
																	<span>
																		Banner
																	</span>
																</span>
															</div>
														</div>}

															{storedSelectedPublication.file && <div className='uploadDoc' style={{ marginTop: "20px"}}>
																<div
																	className='fileUpload btn btn-orange p-0'
																	onClick={() => handleFileDownload(storedSelectedPublication.file)}
																>
																	<img
																		src='https://image.flaticon.com/icons/svg/136/136549.svg'
																		className='icon'
																		alt=""
																	/>
																	<br />
																	<span
																		className='__upl'
																		id='upload'>
																		Download&nbsp;
																		<span>
																			File
																		</span>
																	</span>
																</div>
															</div>}


															{storedSelectedPublication.iframe && <div className='uploadDoc' style={{ marginTop: "20px"}}>
																<div className='fileUpload btn btn-orange p-0'>
																	<img src="/assets/img/link-icon.svg" className="icon" alt="" />
																	<br />
																	<span style={{ color:  "#07A0AB"}}>
																		<a href={storedSelectedPublication.iframe} target="_blank" rel="noreferrer">Click to visit submitted address</a>
																	</span>
																</div>
															</div>}
														</div>
													</div>
												</div>}
												<div className='__spacet2p'></div>
												<div className='row'>
													<div className='col-lg-6 col-md-6 col-sm-6'>
														<a
															href=''
															className='btn __profbtn'
															onClick={handleAcceptPublication}
														>
															Approve & publish {showPublishLoader && <img src="/assets/img/loader.gif" alt="" />}
														</a>
														&nbsp;&nbsp;
														<a
															href=''
															className='btn __dnbtn'
															onClick={handleDeclinePublication}
														>
															Deny {showDeclineLoader && <img src="/assets/img/loader-dark-theme.gif" alt="" />}
														</a>
													</div>
													<div className='col-lg-6 col-md-6 col-sm-6'>
														<div className='text-end'>
															<a
																data-bs-toggle="modal" data-bs-target="#modal-updatepub"
																className='btn __dnbtn1'
															>Edit</a>
															&nbsp;&nbsp;
															<a
																href=''
																className='btn __dnbtnp'
																onClick={handleDeletePublication}
															>
																Delete
															</a>
														</div>
													</div>
												</div>
											</form>
											<div className='__spacet5p'></div>
										</div>
									</div>

									<div className='__spacet7p'></div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>

			

		<div className="modal fade" id="modal-updatepub" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog __modal-dialog">
			<div className="modal-content">
				<div className="modal-body">
					<button type="button" class="btn-close __close dismiss-pub-update-modal" data-bs-dismiss="modal" aria-label="Close"></button>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
					<p className="__modseps1">Publications</p>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-4"></div>
				</div>
				<div className="__spacet7p"></div>
				<div className="row __flexsmail">
					<div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
					<form>
						<div className="__hidden2">
						<div className="form-group">
							<label className="__modlab">Publication Title</label>
							<input
							type="text"
							className="form-control __regdivform"
							placeholder="E.g. Child Care"
							value={publicationTitle}
							onChange={event => setPublicationTitle(event.target.value)}
							/>
						</div>
						<div className="row">

						</div>
						<div className="form-group">
							<label className="__modlab">Iframe</label>
							<input
							type="text"
							className="form-control __regdivform"
							placeholder="Optional"
							value={publicationIframe}
							onChange={event => setPublicationIframe(event.target.value)}
							/>
						</div>
						<div className="form-group">
							<label className="__modlab">Description</label>
							<textarea
							className="form-control __regdivform"
							rows="5"
							value={publicationBody}
							onChange={event => setPublicationBody(event.target.value)}
							></textarea>
						</div>
						<div className="__spacet7p"></div>
						<div className="row">
							<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<a
								data-toggle="modal"
								className="btn btn-block __autbtn"
								onClick={handleUpdatePublication}
							>
								Update
								{showUpdatingPublicationLoader && <img src="/assets/img/loader.gif" alt="" />}
							</a>
							</div>
							<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
						</div>
						</div>
					</form>
					</div>
					<div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
					<div className=" __it" style={{ marginTop: "35px"}}>
						<div className="uploadDoc" style={{ marginBottom: "20px"}}>
						<div className="fileUpload btn btn-orange p-0">
							<img
							src="https://image.flaticon.com/icons/svg/136/136549.svg"
							className="icon"
							alt=""
							/>
							<br />
							<span
							className="__upl"
							>
							Upload 
							<span>Banner</span>
							</span>
							<input
							type="file"
							className="upload up"
							required="required"
							onChange={event => setPublicationBanner(event.target.files[0])}
							/>
						</div>
						</div>
						<div className="uploadDoc">
						<div className="fileUpload btn btn-orange p-0">
							<img
							src="https://image.flaticon.com/icons/svg/136/136549.svg"
							className="icon"
							alt=""
							/>
							<br />
							<span
							className="__upl"
							>
							Upload 
							<span>file</span>
							</span>
							<input
							type="file"
							className="upload up"
							required="required"
							onChange={event => setPublicationFile(event.target.files[0])}
							/>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>

			<ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>

			<UploadComponent />
		</>
	)
}

export default PublicationDetailPage;
