import React, { useEffect, useState } from "react";
import { NavLink as Link, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Nav from "../../common/components/Nav";
import Footer from "../../common/components/Footer";
import MissionaAndVision from "./component/MissionAndVision";
import OurTeam from "./component/OurTeam";
import Statistics from "./component/Statistics";
import CoreFunctions from "./component/CoreFunctions";
import { fetchAllTeamMembers } from "../../utils/api/team";
import { errorHandler } from "../../utils/helpers/errorHandler";
import { setAllTeam } from "../../store/actions/actions/team/index.js";

const AboutPage = () => {
  const [page, setPage] = useState(1);
  const [localTeamMembers, setLocalTeamMembers] = useState([]);
  const dispatch = useDispatch();

  const handleFetchAllTeamMembers = async () => {
    try {
      const data = await fetchAllTeamMembers(page);

      setPage(data.page);
      setLocalTeamMembers(data.allTeamMembers);
      dispatch(setAllTeam(data.allTeamMembers));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchAllTeamMembers();
  }, []);

  const team = localTeamMembers.reduce((acc, member) => {
    acc[member.position] = member;
    return acc;
  }, {});

  return (
    <>
      <header>
        <Nav />
      </header>

      <section>
        <div className="container">
          <div
            className="__all-banner"
            style={{ background: `url(/assets/img/pagesbanner.png)` }}
          >
            <p className="__caption-banner text-center">About LBS</p>
          </div>

          <div className="__spacet3p"></div>
          <div className="__quipdiv">
            <p className="__latst1">
              The Lagos Bureau of Statistics (LBS) is one of the core
              departments in Ministry of Economic Planning and Budget (MEPB).
              The department coordinates statistical activities of the state and
              advises the state government and its stakeholders on policies,
              methods and regulations relating to the development of the state.
            </p>
          </div>
        </div>

        <div
          className="__all-about"
          style={{ background: `url(/assets/img/corevmbg.png)` }}
        >
          <div className="container">
            <div className="__vismsflex">
              <div className="__vimicard">
                <p className="__aboup text-center">Vision</p>
                <p className="__aboup1 text-center">
                  Ensuring a digitalized, efficient and timely statistical
                  system for planning, mission policy formulation and decision
                  making.
                </p>
              </div>

              <div className="__vimicard">
                <p className="__aboup text-center">Mission</p>
                <p className="__aboup1 text-center">
                  To continually be the one-stop shop for qualitative, reliable
                  and robust data for the development of the state.
                </p>
              </div>
            </div>

            <p className="text-center __mteam">Meet Our Team</p>

            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <div className="__teamcard">
                  <img
                    src={
                      team["The Honourable Commissioner"]?.image ||
                      "/assets/img/placeholder.jpg"
                    }
                    className="__teamimg"
                    alt="commissioner"
                  />
                </div>
                <center>
                  <p className="__vimisp">
                    {" "}
                    {team["The Honourable Commissioner"]?.name}
                  </p>
                  <p className="__vimisp1">
                    The Honourable Commissioner, <br />
                    {team["The Honourable Commissioner"]?.office}
                  </p>
                </center>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <div className="__teamcard1">
                  <img
                    src={
                      team["The Permanent Secretary"]?.image ||
                      "/assets/img/placeholder.jpg"
                    }
                    className="__teamimg"
                    alt="permanent secretary"
                  />
                </div>
                <center>
                  <p className="__vimisp">
                    {" "}
                    {team["The Permanent Secretary"]?.name}
                  </p>
                  <p className="__vimisp1">
                    The Permanent Secretary, <br />
                    {team["The Permanent Secretary"]?.office}
                  </p>
                </center>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <div className="__teamcard2">
                  <img
                    src={
                      team["Director"]?.image || "/assets/img/placeholder.jpg"
                    }
                    className="__teamimg"
                    alt="director"
                  />
                </div>
                <center>
                  <p className="__vimisp">{team["Director"]?.name}</p>
                  <p className="__vimisp1">
                    Director, <br />
                    {team["Director"]?.office}
                  </p>
                </center>
              </div>
            </div>

            <p className="__vmicor text-center">
              THE CORE FUNCTIONS OF THE LAGOS BUREAU OF STATISTICS
            </p>

            <div className="__coreflex">
              <div className="__coredivflx">
                <div class="__core-dot">
                  <span class="__core-dot-ripple"></span>
                  <span class="__core-dot-ripple"></span>
                  <span class="__core-dot-ripple"></span>
                </div>
                <p className="__corep">
                  Provide advisory role to the State and Local Government on
                  statistical matters.
                </p>
              </div>

              <div className="__coredivflx">
                <div class="__core-dot">
                  <span class="__core-dot-ripple"></span>
                  <span class="__core-dot-ripple"></span>
                  <span class="__core-dot-ripple"></span>
                </div>
                <p className="__corep">
                  Research and conduct statistical surveys on the socio-economic
                  activities in the state.
                </p>
              </div>

              <div className="__coredivflx">
                <div class="__core-dot">
                  <span class="__core-dot-ripple"></span>
                  <span class="__core-dot-ripple"></span>
                  <span class="__core-dot-ripple"></span>
                </div>
                <p className="__corep">
                  Maintain a comprehensive data bank on the results of the
                  generational coordination of statistical activities in the
                  state.
                </p>
              </div>

              <div className="__coredivflx">
                <div class="__core-dot">
                  <span class="__core-dot-ripple"></span>
                  <span class="__core-dot-ripple"></span>
                  <span class="__core-dot-ripple"></span>
                </div>
                <p className="__corep">
                  Maintain liaison with the three tiers of government (federal,
                  state and local government) and multinational organizations on
                  the issues pertaining to statistics, research, development,
                  publications and dissemination of statistical data.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="__spacet3p"></div>
      </section>
      <Footer />
    </>
  );
};

export default AboutPage;
