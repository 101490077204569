import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const fetchLgas = async () => {
  try {
    const {data: {data}} = await axios({
      method: 'GET',
      url: `${baseUrl}/lgas`,
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchMinistries = async () => {
  try {
    const {data: {data}} = await axios({
      method: 'GET',
      url: `${baseUrl}/ministries`,
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchOrganizations = async () => {
  try {
    const {data: {data}} = await axios({
      method: 'GET',
      url: `${baseUrl}/organization`,
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const searchSurvey = async (searchKeys, type) => {
  try {
    const {data: { data }} = await axios({
      method: 'GET',
      url: `${baseUrl}/${type}/search?${searchKeys.join('&')}`,
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const downloadDataset = async (fileName) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/data-set/files/${fileName}`,
      headers: {'Content-Type': 'application/json'}
    });
    
    window.open(`${baseUrl}/data-set/files/${fileName}`);

    return response;
  } catch (error) {
    throw error.response.data;
  }
}