import React, {useState} from 'react';
import {NavLink as Link, useHistory } from 'react-router-dom';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import { forgotPassword } from '../../utils/api/authentication';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const ForgotPasswordPage = () => {
  const history = useHistory();

  const [email, setEmail] = useState(''),
        [showLoader, setShowLoader] = useState(false);

  const handleForgotPassword = async (event) => {
    setShowLoader(true);
    event.preventDefault();
    if(!email) {
      setShowLoader(false);
      return toast.error(`The Email field is required`);
    }
    const host = window.location.origin;
    
    try {
      const data = await forgotPassword({host, email });
      setShowLoader(false);
      setEmail('');

      setTimeout(() => {
        toast.success(`${data.message}`);
      }, 500);

      history.push('/login');

    } catch (error) {
      setShowLoader(false);
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  }
  return (
    <>
      <header>
        <div className="__landbgex" style={{height: '100vh'}}>
          <Nav/>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="__captionex text-center">Forget Password</p>
                <div className="__spacet7p"></div>
                <div className="__regdiv">
                  <form>
                    <div className="form-group">
                      <input type="email" className="form-control __regdivform" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <p className="__reg">New User? <Link to="/register">Register</Link></p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <p className="__regfg">
                          <Link to="/login">Sign in</Link>
                        </p>
                      </div>
                    </div>

                    <div className="__spacet10p"></div>
                    <button className="btn btn-block __regbtn" onClick={handleForgotPassword}>RESTORE { showLoader && <img src="/assets/img/loader.gif" alt="" /> }</button>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
            </div>
          </div>
        </div>
      </header>
      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
    </>
  )
}

export default ForgotPasswordPage;