import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Header from '../../../common/components/Header';
import Nav from '../../../common/components/Nav';
import Footer from '../../../common/components/Footer';
import { errorHandler } from "../../../utils/helpers/errorHandler";

import {
	findPublication
} from "../../../utils/api/publication";

import { setPublicationToBeVisualized } from "../../../store/actions/actions/publication";
import DownloadApp from '../../../common/components/DownloadApp';

const VisualizationPage = () => {
	const storedPublicationTobeVisualized = useSelector(state => state.publications.publication_to_be_visualized);

  const dispatch = useDispatch();
  const {uuid} = useParams();

  const [publication, setPublication] = useState("");



  useEffect(() => {
    if(storedPublicationTobeVisualized) {
      setPublication(storedPublicationTobeVisualized);
    }
  },[]);

  useEffect(() => {
    if(!storedPublicationTobeVisualized) {
      handleFetchSelectedPublication();
    }
  }, []);

  const handleFetchSelectedPublication = async () => {
		try {
			const publication = await findPublication(uuid);
			dispatch(setPublicationToBeVisualized(publication));
      setPublication(publication);
		} catch (error) {
			errorHandler(error, toast);
		}
	}

  return (
    <>
      <header className="__nehead">
        <Nav/>
      </header>

      <section>
        {publication && <div className="container">
          <div className="__spacet3p"></div>
          <iframe
            src={publication.iframe}
            frameBorder="0"
            allowFullScreen="true"
            className="__ifrmv"
            title="powerBi"
          ></iframe>
        </div>}
        <div className="__spacet10p"></div>
        <DownloadApp />
      </section>
      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
      <Footer />
    </>
  );
};

export default VisualizationPage;