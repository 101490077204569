import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";


// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { errorHandler } from "../../utils/helpers/errorHandler";

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

const SubmitDatasetPage = () => {

  const history = useHistory();
  
  const [ description, setDescription ] = useState('');
  const [ datasetName, setDatasetName ] = useState('');
  const [file, setFile] = useState(null);
  const [datasetType, setDatasetType] = useState("link");
  const [title, setTitle] = useState("");
  const [consent, setConsent] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [showLoader, setLoader] = useState(false);

  const handleUploadDataset = async (event) => {
    event.preventDefault();
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', datasetName);
      formData.append('description', description);
      formData.append('title', title);
      formData.append('email', email);
      formData.append('consent', consent);
      formData.append('type', datasetType);
      formData.append('fullName', fullName);

      const { data: dataset } = await axios(`${baseUrl}/datasets/public`,{
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'application/json'}
      });

      setDescription('');
      setDatasetName('');
      setFile(null);
      setDatasetType("link");
      setTitle("");
      setConsent(false);
      setEmail("");
      setFullName("");
      setLoader(false);
      setTimeout(() => {
        toast.success(`${dataset.message}`);
      }, 500);

      history.push("/");

    } catch (error) {
      setLoader(false);
      errorHandler(error.response.data, toast);
    }
  }


  return (
    <>
     <header>
        <div className="__landbgex">
          <Nav/>
          
          <div className="container">
            <div className='row'>
                <div className='col-xl-3 col-lg-3 col-md-2 col-sm-1'></div>
                <div className='col-xl-6 col-lg-6 col-md-8 col-sm-10'>
                    <p className="__captionex text-center">Upload Dataset</p>
                    <p className="__captionex1 text-center">Do you have datasets you would like to share with the public? Please fill out the form below to submit the dataset which will be reviewed before being made available to the public.</p>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-2 col-sm-1'></div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-2 col-sm-2"></div>
              <div className="col-lg-6 col-md-8 col-sm-8">
                
                <div className="__spacet3p"></div>
                <form>
                
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control __contform"
                      placeholder="Your Full Name"
                      value={fullName}
                      onChange={event => setFullName(event.target.value)}
                      />
                  </div>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control __contform"
                      placeholder="Dataset Name"
                      value={datasetName}
                      onChange={event => setDatasetName(event.target.value)}
                      />
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="file"
                      className="form-control __contform"
                      placeholder="File"
                      onChange={event => setFile(event.target.files[0])}
                    />
                  </div>
                  {/* <div className="form-group mb-3">
                    <select className="form-control __contform" onChange={event => {
                      setFile('');
                      setDatasetType(event.target.value);
                      }}>
                      <option value="link" selected={file === "link"}>link</option>
                      <option value="file" selected={file === "file"}>File</option>
                    </select>
                  </div> */}

                  <div className="form-group mb-3">
                    <textarea
                      className="form-control"
                      rows="7"
                      placeholder="Description"
                      value={description}
                      onChange={event => setDescription(event.target.value)}
                      style={{borderRadius: "2px"}}
                    ></textarea>
                  </div>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control __contform"
                      placeholder="Title"
                      value={title}
                      onChange={event => setTitle(event.target.value)}
                    />
                  </div>

                  {/* {datasetType === "file" && <div className="form-group mb-3">
                    <input
                      type="file"
                      className="form-control __contform"
                      placeholder="File"
                      onChange={event => setFile(event.target.files[0])}
                    />
                  </div>} */}

                  {/* {datasetType === "link" && <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control __contform"
                      placeholder="Link"
                      value={file}
                      onChange={event => setFile(event.target.value)}
                    />
                  </div>} */}

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control __contform"
                      placeholder="Email"
                      onChange={event => setEmail(event.target.value)}
                    />
                  </div>

                  <div className="form-group mb-3">
                  <div style={{display: "flex", alignItems: "center"}}>
                      <input
                        type="checkbox" 
                        id="consent" 
                        className="form-check-input __regdivform" 
                        value={consent} 
                        onChange={(event) => setConsent(event.target.checked)}
                        style={{width: "15px", height: "16px", cursor:"pointer"}} 
                        checked={consent === true}
                      />

                      <label htmlFor="consent" className="__modlab form-check-label" style={{ color: "#ffffff", marginBottom: 0, marginTop: "5px", marginLeft: "10px"}}>I confirm that all the information that I have provided is genuine and valid. <p><a href='https://creativecommons.org/licenses/by/4.0/' className='__lichv' target="_blank"> License: CC BY 4.0</a></p></label>
                    </div>
                  </div>

                  <div className="__spacet5p"></div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-block __autbtn"
                      style={{ width: "100%"}}
                      onClick={handleUploadDataset}
                      disabled={!consent}
                    >
                      Send
                      { showLoader && <img src="/assets/img/loader.gif" alt="" style={{ marginLeft: "7px" }} />}
                    </button>
                  </div>

                </form>
              </div>
              <div className="col-lg-3 col-md-2 col-sm-2"></div>
            </div>
          </div>
        </div>
      </header>
      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
    </>
  )
}

export default SubmitDatasetPage;