import React from 'react';
import {useSelector} from 'react-redux';

import { useAuth } from '../../hooks/auth';

const UserAvatar = () => {

  const auth = useAuth();

  const user = useSelector(state =>  state.user);

  const handleLogout = async (event) => {
    event.preventDefault();

    auth.signoutUser();
  }

  return (
    <>
      <li className="dropdown">
      <a href="#" className="dropdown-toggle __droprof" data-toggle="dropdown">&nbsp;&nbsp;{ user.name }&nbsp;
        <img src="/assets/img/clock.jpg" className=" __dashimg" alt="" /> <b className="caret"></b>
      </a>
      <ul className="dropdown-menu __dropdown-menu" role="menu">
        <li><a className="__a" href="#" onClick={handleLogout}>Log out</a></li>
      </ul>
    </li>
    </>
  )
}

export default UserAvatar;