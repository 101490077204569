import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Pagination from '../../../common/components/Pagination';
import { fetchAllPublicDataset } from "../../../utils/api/dataset";

// import FileViewer from 'react-file-viewer';
// import { CustomErrorComponent } from 'custom-error';


const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
	? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;


const FetchedPublicDatasets = () => {
	const history = useHistory();

	const [publicDatasets, setPublicDataset] = useState([]),
				[datasetPage, setDatasetPage] = useState(0),
				[datasetMaxPage, setdatasetMaxPage] = useState(SIZE_PER_PAGE);

	const handleFetchPublicDatasets = async (datasetPage = 0) => {
		const { publicDatasets, page, count } = await fetchAllPublicDataset(datasetPage);

		setDatasetPage(page);
		setdatasetMaxPage(count);
		setPublicDataset(publicDatasets);
	}

	const handleNextDatasetPagination = async (event) => {
		event.preventDefault();
		await handleFetchPublicDatasets(+datasetPage);
	}

	const handlePreviousDatasetPagination = async (event) => {
		event.preventDefault();
		await handleFetchPublicDatasets(+datasetPage - 2);
	}

	const gotoPublicDataset = (uuid) => {
		history.push(`/pages/dataset/${uuid}`);
	}

	useEffect(() => {
		handleFetchPublicDatasets();
	}, []);

	

// 	const file = 'file:///C:/Users/Abimbola/Documents/My%20Documents/Jobberman_Soft_Skill_Certificate_5070423.pdf'
// 	const type = 'pdf'

// 	const onError = (e) => {
		
//     console.log(e, 'error in file-viewer');
//   }

	return (
		<>
		

		
			<div className="page" style={{ marginTop: 40 }}>
				{publicDatasets?.length ? publicDatasets?.map(page => (<div
					className="page-card"
					onClick={() => gotoPublicDataset(page.uuid)}
					key={page.uuid}
				>
					<div>
						{page.type == 'file' ? (
							<div style={{
								width: "100%",
								height: 170,
								borderBottom: '1px solid #eee',
								display: "flex",
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: 24,
								color: 'gray'
							}}>
								<img src="/assets/img/bar-chart.svg" alt="" style={{ width: "100%", height: "80%", filter: "grayscale(25%)" }} />
							</div>
						) : (
							<div style={{
								width: "100%",
								height: 170,
								borderBottom: '1px solid #eee',
								display: "flex",
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: 24,
								color: 'gray'
							}}>
								<img src="/assets/img/dsndata.jpg" alt="" style={{ width: "100%", height: "80%", filter: "grayscale(25%)" }} />
								{/* <i className="fa fa-link" aria-hidden="true"></i> */}
							</div>
						)}
					</div>
					<div style={{ paddingLeft: '5px' }}>
						<h6 style={{ fontWeight: "bold" }}>{page.title}</h6>
						{page.description.length > 100 ? (<div dangerouslySetInnerHTML={{ __html: `${page.description.substr(0, 100)}...` }}></div>) : (<div dangerouslySetInnerHTML={{ __html: page.description }}></div>)}
					</div>
				</div>)) : <p className='__dtano'>No public data published yet</p>}

				

				<Pagination
					page={+datasetPage}
					maxPage={+datasetMaxPage}
					SIZE_PER_PAGE={+SIZE_PER_PAGE}
					handleNextPagination={handleNextDatasetPagination}
					handlePreviousPagination={handlePreviousDatasetPagination}
				/>
			</div> 

			{/* <FileViewer
        fileType={type}
        filePath={file}
        errorComponent={CustomErrorComponent}
        onError={onError}
		
		/>  */}
		</>
	)
}

export default FetchedPublicDatasets;