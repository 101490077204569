import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// import Header from '../../../common/components/Header';
import Nav from '../../../common/components/Nav';
import Footer from '../../../common/components/Footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { errorHandler } from "../../../utils/helpers/errorHandler";
import { setPublicationToBeVisualized } from "../../../store/actions/actions/publication";

import {
	findPublication
} from "../../../utils/api/publication";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PublicationsPage = () => {
  const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;
  
  const storedPublicationTobeVisualized = useSelector(state => state.publications.publication_to_be_visualized);

  const dispatch = useDispatch();
  const {uuid} = useParams();

  useEffect(() => {
    if(!storedPublicationTobeVisualized) {
      handleFetchSelectedPublication();
    }
  }, []);

  const handleFetchSelectedPublication = async () => {
		try {
			const publication = await findPublication(uuid);
			dispatch(setPublicationToBeVisualized(publication));
		} catch (error) {
			errorHandler(error, toast);
		}
	}

  return (
    <>
      <header>
      <Nav/>
      {/* <div className="__landbgex">
        
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-2 col-sm-1"></div>
            <div className="col-lg-6 col-md-8 col-sm-10">
              <p className="__captionex text-center">Our Publications</p>
              <p className="__captionex1 text-center">Go through our rich library of publications of different activities relating to Ministry of Economic Planning and Budget</p>
            </div>
            <div className="col-lg-3 col-md-2 col-sm-1"></div>
          </div>
        </div>
      </div> */}
    </header>


    <section>
      <div className='__view-pubbg' style={{background:`url(/assets/img/view-publicationbg.png)`}}>
        <div className="container">
          <Link className='__backp' to="/publications">Go back</Link>
            {storedPublicationTobeVisualized && <div className="" style={{ marginBottom: "2rem" }}>
                <p className='__aboup'>{ storedPublicationTobeVisualized.title }</p>
                <div className='__spacet2p'></div>

                <div className='__abt-img1'
                    style={{
                    background: storedPublicationTobeVisualized?.banner.startsWith('/') ? `linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), url(${rootUrl}/${storedPublicationTobeVisualized.banner.substr(storedPublicationTobeVisualized.banner.lastIndexOf('/')+1)})`: `linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), url(${storedPublicationTobeVisualized?.banner})` }}>
                </div>
                <p className='__proviewp'>{ storedPublicationTobeVisualized.body }</p>
            </div>}
        </div>
      </div>
    </section>

    
      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
      <Footer />
    </>
  )
}

export default PublicationsPage;