import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { useDispatch } from 'react-redux';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import {useAuth} from '../../hooks/auth';
import { signin } from '../../store/actions/actions/user';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { errorHandler } from "../../utils/helpers/errorHandler";


const SignInPage = () => {
  const auth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState(''),
        [password, setPassword] = useState(''),
        [showLoader, setShowLoader] = useState(false),
        [showPassword, toggleShowPassword] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    if(!password || !email) {
      return toast.error('The email and password fields are required!');
    }
    setShowLoader(true);
    try {
      const {user, token} = await auth.signin({email, password});
      resetForm();
      dispatch(signin({user, token}));
      setShowLoader(false);

      setTimeout(() => {
        toast.success("Logged in successful!");
      }, 200);

      history.push('/admin/dashboard');
    } catch (error) {
      setShowLoader(false);
      errorHandler(error, toast);
    }
  }

  const resetForm = () => {
    setEmail('');
    setPassword('');
  }
  return (
    <>
      <header>
        <div className="__landbgex" style={{height: '100vh'}}>
          <Nav/>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="__captionex text-center">Sign In</p>
                <div className="__spacet3p"></div>
                <div className="__regdiv">
                  <form>
                    <div className="form-group mb-3">
                      <input type="email" className="form-control __regdivform" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} required />
                    </div>

                    <div className="form-group mb-3" style={{ position: "relative" }}>
                      <input
                        type={showPassword ? "text":"password"}
                        className="form-control __regdivform"
                        placeholder="Password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                      <label
                       onClick={() => toggleShowPassword(!showPassword)}
                       style={{
                         position: "absolute",
                         top: "32%",
                         right: 20,
                         color: "#555",
                         cursor: "pointer"
                        }}>
                        {!showPassword && <i class="fa fa-eye" aria-hidden="true"></i>}
                        {showPassword && <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <p className="__reg">New User? <Link to="/register">Register</Link></p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <p className="__regfg">
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </p>
                      </div>
                    </div>


                    <div className="__spacet10p"></div>
                    <button type="submit" className="btn btn-block __regbtn" onClick={handleLogin}>LOGIN { showLoader && <img src="/assets/img/loader.gif" alt="" /> }</button>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
            </div>
          </div>
        </div>
      </header>
      <ToastContainer
        closeOnClick
        pauseOnHover
      >
      </ToastContainer>
    </>
  )
}

export default SignInPage;