/** @format */

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { saveAs } from 'file-saver';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SideMenu from '../../../common/SideMenu.jsx';
// import UserAvatar from '../../../common/UserAvatar';
import DashboardNav from '../../component/DashboardNav.js';
import UploadComponent from "../../../common/Upload";
import { errorHandler } from "../../../../utils/helpers/errorHandler";

import {
	updateInteractiveAnalysisStatus,
	updateInteractiveAnalysis,
	deleteInteractiveAnalysis,
	findInteractiveAnalysis
} from "../../../../utils/api/interactiveAnalysis";

import {
	updateInteractiveStatus,
	updateInteractiveAnalysisData,
	deleteInteractiveAnalysisData,
	setSelectedInteractiveAnalysis
} from "../../../../store/actions/actions/interactiveAnalysis";


const InteractiveAnalysisDetailsPage = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));
	const interactiveAnalysis = useSelector(state => state.interactiveAnalysis.selected_interactive_analysis);
	const {uuid} = useParams();

	const [showPublishLoader, setShowPublishLoader] = useState(false);
	const [showDeclineLoader, setShowDeclineLoader] = useState(false);
	const [comment, setComment] = useState("");

	const [interactiveAnalysisTitle, setInteractiveAnalysisTitle] = useState("");
  const [interactiveAnalysisDescription, setInteractiveAnalysisDescription] = useState("");
  const [interactiveAnalysisType, setInteractiveAnalysisType] = useState("image");
  const [interactiveAnalysisFile, setInteractiveAnalysisFile] = useState(undefined);
	const [showUpdatingInteractivity, setShowUpdatingInteractivity] = useState(false);
	const [category, setCategory] = useState(""); //i added this when showing category not defined

	const goBack = (event) => {
		event.preventDefault();
    history.push('/admin/dashboard/interactive-analysis');
  }

	const handleFetchInteractiveAnalysis = async () => {
		try {
			const data = await findInteractiveAnalysis(uuid);

			setInteractiveAnalysisTitle(data.title);
			setInteractiveAnalysisDescription(data.description);
			setInteractiveAnalysisType(data.type);
			setInteractiveAnalysisFile(data.contentUrl);
			setCategory(data.category); //i added this when showing category not defined
			dispatch(setSelectedInteractiveAnalysis(data));
		} catch (error) {
			errorHandler(error, toast);
		}
	}

	useEffect(() => {
		if(!interactiveAnalysis) {
			handleFetchInteractiveAnalysis();
		} else {
			setInteractiveAnalysisTitle(interactiveAnalysis.title);
			setInteractiveAnalysisDescription(interactiveAnalysis.description);
			setInteractiveAnalysisType(interactiveAnalysis.type);
			setInteractiveAnalysisFile(interactiveAnalysis.contentUrl);
			setCategory(interactiveAnalysis.category); //i added this when showing category not defined
		}
	}, [interactiveAnalysis]);

	const handleFileDownload = async () => {
		try {
			let files;

			if (interactiveAnalysis.contentUrl.includes(",")) {
				files = interactiveAnalysis.contentUrl
				.split(",")
				.map(file => file.substr(file.lastIndexOf('/')+1)).join("+");
			} else {
				files = interactiveAnalysis.contentUrl.substr(interactiveAnalysis.contentUrl.lastIndexOf('/')+1)
			}

			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/interactivities/${files}/download/`,
				{
					headers: { 'Content-Type': 'application/json', Authorization: `${ token }` 
				}
			});


			const blob = await res.blob()
			saveAs(blob, 'eko-360-interactivity');
		} catch (error) {
			errorHandler(error, toast);
		}
	}

	const acceptOrDeclineInteractiveAnalysis = async (status) => {
		try {
			let commentToSend = status === "published" ? undefined : comment;
			const data = await updateInteractiveAnalysisStatus({
				status,
				comment: commentToSend,
				uuid: interactiveAnalysis.uuid,
				token
			});
			setTimeout(() => {
				toast.success(`${data.message}`);
      }, 500);

			dispatch(updateInteractiveStatus(status));
			setShowDeclineLoader(false);
			setShowPublishLoader(false);
			history.push('/admin/dashboard/interactive-analysis');
		} catch (error) {
			setShowDeclineLoader(false);
			setShowPublishLoader(false);
			errorHandler(error, toast);
		}
	}

	const handleAcceptInteractiveAnalysis = async (event) => {
    event.preventDefault();
    setShowPublishLoader(true);
    await acceptOrDeclineInteractiveAnalysis("published");
  }

	const handleDeclineInteractiveAnalysis = async (event) => {
		event.preventDefault();
		setShowDeclineLoader(true);
		await acceptOrDeclineInteractiveAnalysis("declined");
	}

	const handleUpdateInteractiveAnalysis = async (event) => {
		event.preventDefault();
		setShowUpdatingInteractivity(true);
		
		try {
			const formData = new FormData();
      formData.append("title", interactiveAnalysisTitle);
      formData.append("description", interactiveAnalysisDescription);
      formData.append("type", interactiveAnalysisType);
      formData.append("category", category); //i added this when showing category not defined
	  
      if(interactiveAnalysisType === "image") {
        const multipleFiles = Array.from(interactiveAnalysisFile);
        multipleFiles.forEach(file => {
          formData.append("files", file);
        });
      } else if(interactiveAnalysisType === "iframe") {
        formData.append("url", interactiveAnalysisFile);
			} else {
        formData.append("files", interactiveAnalysisFile[0]);
      }

			const data = await updateInteractiveAnalysis(formData, interactiveAnalysis.uuid, token);

			setShowUpdatingInteractivity(false);
			dispatch(updateInteractiveAnalysisData(data.data[0]));
			toast.success(`${data.message}`);
			document.querySelector(".dismiss-int-update-modal").click();
		} catch (error) {
			setShowUpdatingInteractivity(false);
			errorHandler(error, toast);
		}

	}

	const resetModalForm = () => {
	setTimeout(() => {
		window.$('.hidden1').hide();
		window.$('.__hidden2').show();
		window.$('.show1').show();
		window.$('.hidden2').hide();
		window.$('.show4').show();
	}, 300)
	}

	const handleDeleteInteractiveAnalysis = async (event) => {
		event.preventDefault();
		try {
			const data = await deleteInteractiveAnalysis(interactiveAnalysis.uuid, token);

			setTimeout(() => {
				toast.success(`${data.message}`);
			}, 500);
				
			history.push("/admin/dashboard/interactive-analysis");
			dispatch(deleteInteractiveAnalysisData(interactiveAnalysis.uuid));
		} catch (error) {
			errorHandler(error, toast);
		}
	}

	const title = "Interactive details"

	return (
		<>
			<div className='section body'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-2'>
							<SideMenu />
						</div>

						<div className='col-md-10'>

							<DashboardNav 
								page={title}
							/>
							

							<section>
								<div className='__house'>
									<div className='__btcard'>
										<div className='__spacet3p'></div>
										<div className='__btcardin1'>
											<div className='row'>
												<div className='col-lg-6 col-md-6 col-sm-6'>
													<a href=''>
														<div className='__dascard text-center'>
															<p className='__dasp'>
																Numbers of Views
															</p>
															<p className='__dasp1'>
																0
															</p>
														</div>
													</a>
												</div>
												<div className='col-lg-6 col-md-6 col-sm-6'>
													<a href=''>
														<div className='__dascard text-center'>
															<p className='__dasp'>
																Numbers of Downloads
															</p>
															<p className='__dasp1'>
																0
															</p>
														</div>
													</a>
												</div>
											</div>
											<div className='__spacet2p'></div>
											<div className='row'>
												<div className='col-lg-6 col-md-6 col-sm-6'>
													<p className='__subp'>
														Submission Date: {moment(interactiveAnalysis?.createdAt).format('LLLL')}
														<br />
														<span className='__subpen'>
															{interactiveAnalysis?.status}
														</span>
													</p>
												</div>
												<div className='col-lg-3 col-md-3 col-sm-3'></div>
											</div>
											<hr />
											<form>
												<div className='row __flexsmail'>
													<div className='col-lg-6 col-md-6 col-sm-6 __mobidown'>
														<div className='form-group'>
															<label
																htmlFor=''
																className='__modlab'>
																Dataset Title
															</label>
															<p>{interactiveAnalysis?.title}</p>
														</div>
														
														<div className='form-group'>
															<label
																htmlFor=''
																className='__modlab'>
																Description
															</label>
															<p>{interactiveAnalysis?.description}</p>
														</div>
														<div className='form-group'>
															<label
																htmlFor=''
																className='__modlab'>
																Comment
															</label>
															<textarea
																name=''
																className='form-control __regdivform'
																rows='5'
																onChange={event => setComment(event.target.value)}
																value={comment}
															></textarea>
														</div>
													</div>
													<div className='col-lg-6 col-md-6 col-sm-6 __mobiup'>

														<div className='__spacet7p'></div>
														<div className=' __it'>
															<div className='uploadDoc'>
																{interactiveAnalysis?.type !== "iframe" && (<div className='fileUpload btn btn-orange' onClick={handleFileDownload}>
																	<img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" alt="" /><br /><br/>
																	<span style={{ color:  "#07A0AB"}}>Click to download file</span>
																</div>)}

																{interactiveAnalysis?.type === "iframe" && (<div className='fileUpload btn btn-orange'>
																	<img src="/assets/img/link-icon.svg" className="icon" alt="" /><br /><br/>
																	<a href={`${interactiveAnalysis?.contentUrl}`} target="_blank" style={{ color:  "#07A0AB"}}>Click to view iFrame</a>
																</div>)}

															</div>
														</div>
													</div>
												</div>
												<div className='__spacet2p'></div>
												<div className='row'>
													<div className='col-lg-6 col-md-6 col-sm-6'>
														<a
															href='#'
															className='btn __profbtn'
															onClick={handleAcceptInteractiveAnalysis}
														>
															Accept & publish {showPublishLoader && <img src="/assets/img/loader.gif" alt="" />}
														</a>
														&nbsp;&nbsp;
														<a
															href=''
															className='btn __dnbtn'
															onClick={handleDeclineInteractiveAnalysis}
														>
															{/* <li><a data-toggle="modal" href='#modal-id'>Dataset</a></li> */}
															Deny {showDeclineLoader && <img src="/assets/img/loader-dark-theme.gif" alt="" />}
														</a>
													</div>
													<div className='col-lg-6 col-md-6 col-sm-6'>
														<div className='text-end'>
															<a
																className='btn __dnbtn1'
																data-bs-toggle="modal" data-bs-target="#modal-update"
																
															>
																Edit
															</a>
															
															&nbsp;&nbsp;
															<a
																href=''
																className='btn __dnbtnp'
																onClick={handleDeleteInteractiveAnalysis}
															>
																Delete
															</a>
														</div>
													</div>
												</div>
											</form>
											<div className='__spacet5p'></div>
										</div>
									</div>

									<div className='__spacet7p'></div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="modal-update" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog __modal-dialog">
					<div className="modal-content">
						<div className="modal-body">
							<button
								type="button"
								className="btn-close __close dismiss-int-update-modal page-modal" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetModalForm()}
							></button>
							{/* <button type="button" class="btn-close __close dismiss-update-modal" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetModalForm()}></button> */}
							<div className="row">
								<div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
									<p className="__modseps">
									</p>
									<p className="__modseps1">Interactive Analysis</p>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
									<div className="text-center hidden1">
										<span className="__kangprog"></span>
										<span className="__kangproga"></span>
										<span className="__kangprog"></span>
									</div>

									<div className="text-center hidden2">
										<span className="__kangprog"></span>
										<span className="__kangprog"></span>
										<span className="__kangproga"></span>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-4"></div>
							</div>
							<div className="__spacet7p"></div>
							<div className="row __flexsmail">
								<div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
									<form encType="multipart/form-data">
										<div className="__hidden2">
											<div className="form-group">
												<label htmlFor="" className="__modlab">Title</label>
												<input
												type="text"
												className="form-control __regdivform"
												placeholder="Interactive Analysis Title"
												value={interactiveAnalysisTitle}
												onChange={(event) => setInteractiveAnalysisTitle(event.target.value)}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="" className="__modlab">Category</label>
												<select
												className="form-control __regdivform"
												onChange={(event) => {
													setCategory(event.target.value);
												}}
												>
												<option value="" selected disabled>Please select a category</option>
												<option value="education" selected={category === 'education'}>Education</option>
												<option value="health" selected={category === 'health'}>Health</option>
												<option value="economic affairs" selected={category === 'economic affairs'}>Economic Affairs</option>
												</select>
											</div>

											{interactiveAnalysisType === 'iframe' && (<div className="form-group">
												<label htmlFor="" className="__modlab">iFrame</label>
												<input
													type="text"
													className="form-control __regdivform"
													placeholder="Interactive Analysis Title"
													value={interactiveAnalysisFile}
													onChange={(event) => setInteractiveAnalysisFile(event.target.value)}
												/>
											</div>)} 

											<div className="form-group">
												<label htmlFor="" className="__modlab">Description</label>
												<textarea
												className="form-control __regdivform"
												rows="5"
												value={interactiveAnalysisDescription}
												onChange={event => setInteractiveAnalysisDescription(event.target.value)}
												></textarea>
											</div>
											<div className="__spacet20p"></div>
											<div className="row">
												<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
												<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
													<a
														className="btn btn-block __autbtn"
														onClick={handleUpdateInteractiveAnalysis}
													>Update {showUpdatingInteractivity && <img src="/assets/img/loader.gif" alt="" />}</a>
													{/* >Finish { showInteractiveAnalysisLoader && <img src="/assets/img/loader.gif" alt="" /> }</a> */}
												</div>
												<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
											</div>
										</div>
									</form>
								</div>
								<div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
									<div className="form-group __seleimgdiv">
										<label htmlFor="" className="__modlab">Select file to Upload</label>
										<select
										className="form-control __regdivform"
										onChange={event => {
											setInteractiveAnalysisFile("")
											setInteractiveAnalysisType(event.target.value)
										}}
										value={interactiveAnalysisType}
										>
										<option value="video" selected={interactiveAnalysisType === 'video'}>MP4</option>
										<option value="image" selected={interactiveAnalysisType === 'image'}>Image</option>
												<option value="iframe" selected={interactiveAnalysisType === 'iframe'}>iFrame</option>
										</select>
									</div>
									<div className=" __it">
										<div className="uploadDoc">
											<div className="fileUpload btn btn-orange">
												<img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" alt="" /><br />
												<span className="__upl">Browse <span>file here</span></span>
												<input
												type="file"
												className="upload up"
												required="required"
												name="files"
												multiple
												onChange={(event) => setInteractiveAnalysisFile(event.target.files)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
				
			<UploadComponent />
			</>
	)
}

export default InteractiveAnalysisDetailsPage;
