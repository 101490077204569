import {
  SIGNIN_USER,
  STORE_ALL_USERS,
  UPDATE_USER_STATUS
} from '../../actiontypes';

export const signin = (payload) => {
  return {
    payload,
    type: SIGNIN_USER
  }
}

export const storeAllUsers = (payload) => ({
  payload,
  type: STORE_ALL_USERS
});

export const updateUserStatus = (payload) => ({
  payload, 
  type: UPDATE_USER_STATUS
});