import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

import Footer from "../../common/components/Footer";
import Nav from "../../common/components/Nav";
import { fetchPublicPages } from "../../utils/api/cms/page";
import { errorHandler } from "../../utils/helpers/errorHandler";
import { Link } from "react-router-dom";

const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));
const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;

const AnalysisPage = () => {
  const [publicPages, setPublicPages] = useState([]);
  const [analysis, setAnalysis] = useState([]);

  const handleFetchPublicPages = async (page = 0) => {
    try {
      const data = await fetchPublicPages(page);

      setPublicPages(data.data.allPages);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  useEffect(() => {
    handleFetchPublicPages();
  }, []);

  useEffect(() => {
    if (publicPages) {
      const analysis = publicPages.filter((pages) => pages.type === "analysis");

      setAnalysis(analysis);
    }
  }, [publicPages]);

  const handleFileDownload = async (event, dataset) => {
    event.preventDefault();
    try {
      const fileName = dataset.file.substr(dataset.file.lastIndexOf("/") + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/analysis/${fileName}/download/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      const blob = await res.blob();
      saveAs(blob, dataset.title);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  };

  return (
    <div>
      <header>
        <Nav />
      </header>

      <section>
        <div className="container">
          <div
            className="__all-banner"
            style={{ background: `url(/assets/img/pagesbanner.png)` }}
          >
            <p className="__caption-banner text-center">Latest Analysis</p>
          </div>
          <div className="__spacet5p"></div>
        </div>

        <div
          className="__datasetbg"
          style={{ background: `url(/assets/img/latestbg.png)` }}
        >
          {analysis.length > 0 && (
            <div className="container">
              <div className="__quipdiv">
                <p className="__latst1">
                  This is an interactive dashboard that visualizes different
                  analysis and insights on various Lagos State data across
                  health, education and economic affairs sectors.
                </p>
              </div>
              <div className="row">
                {analysis.length > 0
                  ? analysis.map((data) => (
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="__intracard">
                          <div
                            className="__quicimg"
                            style={{ background: `url(${data?.banner})` }}
                          ></div>
                          <div className="__quicdiv">
                            <p className="__quicp2 text-center1">
                              {data.title}
                            </p>
                            {data.body.length > 2500 ? (
                              <p className="__quicp3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${data.body.substr(0, 2500)}...`,
                                  }}
                                ></div>
                              </p>
                            ) : (
                              <p className="__quicp3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.body,
                                  }}
                                ></div>
                              </p>
                            )}
                            <div className="__morbtn">
                              {data.iFrame && (
                                <div className="__btnbloc" onClick={null}>
                                  <a
                                    href={`${data.iFrame}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn hvr-float-shadow __quicbtn"
                                    style={{ alignSelf: "flex-ends" }}
                                  >
                                    {" "}
                                    <img src="/assets/img/view_list.png" /> View
                                    Dashboard
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AnalysisPage;
