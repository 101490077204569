import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink as Link } from "react-router-dom";

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SideMenu from '../../common/SideMenu';
import UploadComponent from '../../common/Upload.jsx';
import { setQuestion, setAllQuestions } from "../../../store/actions/actions/question";
import { fetchAllQuestion, deleteQuestion } from "../../../utils/api/questionLogic";
// import UserAvatar from '../../common/UserAvatar';
import DashboardNav from '../component/DashboardNav';
import Pagination from "../../../common/components/Pagination";

import { errorHandler } from "../../../utils/helpers/errorHandler";

const QuestionLogicPage = (props) => {
  const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

  const dispatch = useDispatch();
  const dataset = useSelector(state => state.dataset.dataset);
  const allQuestions = useSelector(state => state.question.questions);
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));

  const [questions, setQuestions] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isFiltering, setFiltering] = useState();
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);
  const [questionToDelete,setQuestionToDelete] = useState();
  const [showDeleteQuestionLoader, setShowDeleteQuestionLoader] = useState(false);

  const fetchQuestions = async (page) => {
    try {
      const data = await fetchAllQuestion(page);

      setPage(data.page);
      setMaxPage(data.count);
      setQuestions(data.allQuestions);
      dispatch(setAllQuestions(data.allQuestions));
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  const handleDeleteQuestion = async () => {
    setShowDeleteQuestionLoader(true);
    try {
      const data = await deleteQuestion(token, questionToDelete.uuid);

      const indexToRemove = questions.findIndex(question => question.uuid === questionToDelete);
      const clone = [...questions];
      clone.splice(indexToRemove, 1);

      setQuestions(clone);

      toast.success(`${data.message}`);
      setShowDeleteQuestionLoader(false);
      document.querySelector(".del-question-modal").click();
    } catch (error) {
      setShowDeleteQuestionLoader(false);
      errorHandler(error, toast);
    }
  }

  useEffect(() => {
    if (!(questions.length > 0)) {
      fetchQuestions();
    }
  }, [questions]);

  useEffect(() => {
    window.$('#openSidebar').click(function(){
      window.$('.leftbar').addClass('biger');
      });
      window.$('#closebtn').click(function(){
          window.$('.leftbar').removeClass('biger');
      });
  },[])

  useEffect(() => {
    if (allQuestions?.length > 0) {
      setQuestions(allQuestions);
    }
  }, [allQuestions]);

  const handleSearch = (event) => {
    event.preventDefault();

    let searchWord = event.target.value.toLowerCase();
    const questionClone = [...questions];
    const searchResult = questionClone.filter(data => data.question.toLowerCase().includes(searchWord));

    setSearchResult(searchResult);
  }

  const handleFilterbyStatus = (event) => {
    event.preventDefault();

    let searchWord = event.target.value.toLowerCase();
    const questionClone = [...questions];

    const searchResult = questionClone.filter(question => question.status.toLowerCase() === searchWord);

    setSearchResult(searchResult);
  }

  const handleFilterbyMonth = (event) => {
    event.preventDefault();

    event.target.value.toLowerCase().length > 0 ? setFiltering(true) : setFiltering(false);

    let searchWord = event.target.value.toLowerCase();
    const questionClone = [...questions];
    const searchResult = questionClone.filter(question => (+question.createdAt.substr(question.createdAt.indexOf('-') + 1, question.createdAt.lastIndexOf('-') - question.createdAt.indexOf('-') - 1) === +searchWord));

    setSearchResult(searchResult);
  }

  const handleUpdateQuestion = (data) => {
    dispatch(setQuestion(data));
  }

  const handleNextPagination = async (event) => {
    event.preventDefault();
    // await fetchDataset(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    // await fetchDataset(page - 2);
  }

  const title = "Question Logic"

  return (
    <>
      <div className="section body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              <SideMenu />
            </div>

            <div className="col-md-10">
                <DashboardNav 
                  page={title}
                />
              

              <section>
                <div className="__house">
                  <div className="__btcard">
                    <div className="__btcardin">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                          <p className="__btp">Submitted Questions</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3">
                          <select className="form-control __allsel" onChange={handleFilterbyStatus}>
                            <option value="">All Questions</option>
                            <option value="draft">Draft</option>
                            <option value="published">Published</option>
                          </select>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2">
                          <select className="form-control __allsel" onChange={handleFilterbyMonth}>
                            <option value="">Monthly</option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                          </select>
                        </div>
                        <div className="col-lg-1 col-md-1"></div>
                        <div className="col-lg-3 col-md-4 col-sm-4">
                          <form>
                            <div className="form-group">
                              <input type="text" className="form-control __btform" placeholder="Search by question" onChange={handleSearch} />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table __table table-striped'>
                        <thead>
                          <tr>
                            <th>Question</th>
                            <th>Submitted date</th>
                            <th>File</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(questions.length > 0 && searchResult.length === 0) ? (questions.map(question => (

                            <tr onClick={() => handleUpdateQuestion(question)} key={question.uuid}>
                              <td>
                                <span>Name</span>
                                <Link to={`/question-logic/${question.uuid}/view`}>
                                  <p className='__tablp'>
                                    <img
                                      src='/assets/img/clock.png'
                                      className='__tabimg'
                                      alt=''
                                    />
                                      &nbsp;&nbsp;{question.question}
                                  </p>
                                  </Link>
                              </td>
                              <td>
                                <span>
                                  Submitted date
                                  </span>
                                <Link to={`/question-logic/${question.uuid}/view`}>
                                  <p className='__tablp'>
                                    {moment(question.createdAt).format('LLLL')}
                                  </p>
                                  </Link>
                              </td>
                              <td>
                                <span>File format</span>
                                <Link to={`/question-logic/${question.uuid}/view`}>
                                  <p className='__tablp1'>
                                    <img
                                      src='/assets/img/attachment.png'
                                      className='__tabimg1'
                                      alt=''
                                    />
                                      &nbsp;&nbsp;
                                      {question.type}
                                  </p>
                                  </Link>
                              </td>
                              <td>
                                <span>Status</span>
                                <Link to={`/question-logic/${question.uuid}/view`}>
                                  {question.status === 'draft' && (<p
                                      className='__allstatus btn'
                                      style={{ color: '#464646', background: '#F0F0F0' }}>draft
                                    </p>)}

                                    {question.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{question.status}</p>)}
                                </Link>
                              </td>
                            </tr>
                          ))) : (
                            searchResult.map(question => (
                              <tr onClick={() => handleUpdateQuestion(question)} key={question.uuid}>
                              <td>
                                <span>Name</span>
                                <Link to={`/question-logic/${question.uuid}/view`}>
                                  <p className='__tablp'>
                                    <img
                                      src='/assets/img/clock.png'
                                      className='__tabimg'
                                      alt=''
                                    />
                                      &nbsp;&nbsp;{question.question}
                                  </p>
                                  </Link>
                              </td>
                              <td>
                                <span>
                                  Submitted date
                                  </span>
                                <Link to={`/question-logic/${question.uuid}/view`}>
                                  <p className='__tablp'>
                                    {moment(question.createdAt).format('LLLL')}
                                  </p>
                                  </Link>
                              </td>
                              <td>
                                <span>File format</span>
                                <Link to={`/question-logic/${question.uuid}/view`}>
                                  <p className='__tablp1'>
                                    <img
                                      src='/assets/img/attachment.png'
                                      className='__tabimg1'
                                      alt=''
                                    />
                                      &nbsp;&nbsp;
                                      {question.type}
                                  </p>
                                  </Link>
                              </td>
                              <td>
                                <span>Status</span>
                                <Link to={`/question-logic/${question.uuid}/view`}>
                                  {question.status === 'draft' && (<p
                                      className='__allstatus btn'
                                      style={{ color: '#464646', background: '#F0F0F0' }}>draft
                                    </p>)}

                                    {question.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{question.status}</p>)}
                                </Link>
                              </td>
                            </tr>
                            )))}
                        </tbody>
                      </table>
                      {questions.length === 0 &&
                        <p style={{ textAlign: "center" }}>There are no Questions yet</p>}
                    </div>

                    <Pagination
                      page={+page}
                      maxPage={+maxPage}
                      SIZE_PER_PAGE={+SIZE_PER_PAGE}
                      handleNextPagination={handleNextPagination}
                      handlePreviousPagination={handlePreviousPagination}
                    />
                  </div>

                  <div className="__spacet7p"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal fade" id="modal-deletequestion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close __close del-page-modal" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="row __flexsmaila">
                <div className="col-lg-12 col-md-12 col-sm-12 __mobidown" style={{ paddingTop: "1.5rem" }}>
                  <p>Are you sure you want to delete this Question - <strong>{questionToDelete?.question}</strong>?</p>
                  <form style={{ marginTop: "1.5rem", display: "flex", justifyContent: "center" }}>
                    <a
                      className="btn __autbtn"
                      onClick={handleDeleteQuestion}
                      style={{ width: "120px", marginRight: "15px", marginTop: "2rem" }}
                    >
                      Delete
                      { showDeleteQuestionLoader && <img src="/assets/img/loader.gif" alt="" /> }
                    </a>
                    <a
                      className="btn __dnbtn1"
                      style={{
                        width: "120px",
                        backgroundColor: "#fff",
                        border: "1px solid #0C9EA9",
                      }}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      
      <UploadComponent />

      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
    </>
  )
}

export default QuestionLogicPage;