/** @format */

import React from 'react';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';
import './CollectionsPage.css';

const CollectionsPage = () => (
	<>
		<header className='__nehead'>
			<Nav/>
		</header>

		<section>
			<div className='container'>
				<p className='__latst text-center'>Collections</p>
				<p className='__latst1 text-center'>21, 690 Items</p>
			</div>
			<div className='__bgwh'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-4 col-md-4 col-sm-4'>
							<a href=''>
								<div className='__collcard-add text-center'>
									<img src='assets/img/add.png' />
								</div>
							</a>
						</div>
						<div className='col-lg-4 col-md-4 col-sm-4'>
							<div className='__collcard'>
								<div
									className='__collcard-img'
									style={{
										background:
											'url(assets/img/collec.png)',
									}}>
									<div className='__collcard-img-hover'>
										<a
											href='download-collection.html'
											className='btn __colip'>
											<img src='assets/img/downloadimg1.png' />{' '}
											Download all
										</a>
										&nbsp;&nbsp;
										<a href='' className='btn __colip'>
											<img src='assets/img/delete.png' />{' '}
											Delete collection
										</a>
									</div>
								</div>

								<div className='__collcardin'>
									<p className='__collp'>Collection 1</p>
									<div className='row'>
										<div className='col-lg-7 col-md-7 col-sm-7 col-xs-7'>
											<p className='__collp1'>
												May 23 2030
											</p>
										</div>
										<div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'>
											<p className='__collp1 text-right'>
												23 items
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='col-lg-4 col-md-4 col-sm-4'>
							<div className='__collcard'>
								<div
									className='__collcard-img'
									style={{
										background:
											'url(assets/img/collec1.png)',
									}}>
									<div className='__collcard-img-hover'>
										<a
											href='download-collection.html'
											className='btn __colip'>
											<img src='assets/img/downloadimg1.png' />{' '}
											Download all
										</a>
										&nbsp;&nbsp;
										<a href='' className='btn __colip'>
											<img src='assets/img/delete.png' />{' '}
											Delete collection
										</a>
									</div>
								</div>

								<div className='__collcardin'>
									<p className='__collp'>Collection 1</p>
									<div className='row'>
										<div className='col-lg-7 col-md-7 col-sm-7 col-xs-7'>
											<p className='__collp1'>
												May 23 2030
											</p>
										</div>
										<div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'>
											<p className='__collp1 text-right'>
												23 items
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-lg-4 col-md-4 col-sm-4'>
							<div className='__collcard'>
								<div
									className='__collcard-img'
									style={{
										background:
											'url(assets/img/collec2.png)',
									}}>
									<div className='__collcard-img-hover'>
										<a
											href='download-collection.html'
											className='btn __colip'>
											<img src='assets/img/downloadimg1.png' />{' '}
											Download all
										</a>
										&nbsp;&nbsp;
										<a href='' className='btn __colip'>
											<img src='assets/img/delete.png' />{' '}
											Delete collection
										</a>
									</div>
								</div>
								<div className='__collcardin'>
									<p className='__collp'>Collection 1</p>
									<div className='row'>
										<div className='col-lg-7 col-md-7 col-sm-7 col-xs-7'>
											<p className='__collp1'>
												May 23 2030
											</p>
										</div>
										<div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'>
											<p className='__collp1 text-right'>
												23 items
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-4 col-md-4 col-sm-4'>
							<div className='__collcard'>
								<div
									className='__collcard-img'
									style={{ background: 'url(assets/img/collec3.png)' }}>
									<div className='__collcard-img-hover'>
										<a
											href='download-collection.html'
											className='btn __colip'>
											<img src='assets/img/downloadimg1.png' />{' '}
											Download all
										</a>
										&nbsp;&nbsp;
										<a href='' className='btn __colip'>
											<img src='assets/img/delete.png' />{' '}
											Delete collection
										</a>
									</div>
								</div>
								<div className='__collcardin'>
									<p className='__collp'>Collection 1</p>
									<div className='row'>
										<div className='col-lg-7 col-md-7 col-sm-7 col-xs-7'>
											<p className='__collp1'>
												May 23 2030
											</p>
										</div>
										<div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'>
											<p className='__collp1 text-right'>
												23 items
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-4 col-md-4 col-sm-4'>
							<div className='__collcard'>
								<div
									className='__collcard-img'
									style={{
										background:
											'url(assets/img/collec4.png)',
									}}>
									<div className='__collcard-img-hover'>
										<a
											href='download-collection.html'
											className='btn __colip'>
											<img src='assets/img/downloadimg1.png' />{' '}
											Download all
										</a>
										&nbsp;&nbsp;
										<a href='' className='btn __colip'>
											<img src='assets/img/delete.png' />{' '}
											Delete collection
										</a>
									</div>
								</div>
								<div className='__collcardin'>
									<p className='__collp'>Collection 1</p>
									<div className='row'>
										<div className='col-lg-7 col-md-7 col-sm-7 col-xs-7'>
											<p className='__collp1'>
												May 23 2030
											</p>
										</div>
										<div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'>
											<p className='__collp1 text-right'>
												23 items
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='__appbgc'>
				<div className='container'>
					<div className='row __flexsmail'>
						<div className='col-lg-7 col-md-7 col-sm-7 __mobidown'>
							<p className='__appp'>Download our app</p>
							<p className='__appp1'>
								The standard chunk of Lorem Ipsum used since the
								1500s is reproduced below for those interested.
								Sections 1.10.32 and 1.10.33 from "de Finibus
								Bonorum et Malorum" by Cicero are also
								reproduced in their exact original form.
							</p>

							<a
								href=''
								className='btn hvr-float-shadow __appbtn'>
								Download
							</a>
						</div>
						<div className='col-lg-5 col-md-5 col-sm-5 __mobiup'>
							<img
								src='assets/img/appimg.png'
								className='__appimg'
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
		<Footer />
	</>
);

export default CollectionsPage;
