import { 
  SET_PAGES,
  ADD_PAGE,
  UPDATE_PAGE_STATUS,
  UPDATE_PAGE,
  SET_SELECTED_PAGE
} from '../../../actions/actiontypes';

const initialState = {
  pages: [],
  selected_page: {}
}

const pagesReducer = (state = initialState, action) => {
  switch(action.type) {

    case SET_PAGES: 
    return {
      ...state,
      pages: action.payload
    }

    case UPDATE_PAGE_STATUS: {
      const index = state.pages.findIndex(data => data.slug === state.selected_page.slug);
      const updateSelectedPages = {...state.selected_page, status: action.payload};
      state.pages.splice(index, 1, updateSelectedPages);
      return {
        ...state,
        pages: state.pages,
      }    
    }

    case UPDATE_PAGE: {
      const index = state.pages.findIndex(page => page.uuid === action.payload.uuid);
      const updateSelectedPage = action.payload;
      state.pages.splice(index, 1, updateSelectedPage);
      return {
        ...state,
        pages: state.pages,
        selected_pages: action.payload,
      }
    }

    case SET_SELECTED_PAGE: 
    return {
      ...state,
      selected_page: action.payload
    }

    case ADD_PAGE: {
    const update = [action.payload,...state.pages];
      return {
        ...state,
        pages: update,
      }
    }

    default: return state;
  }
}

export default pagesReducer;