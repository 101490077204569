import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./reset.css";

import { errorHandler } from "../../utils/helpers/errorHandler";

import { uploadDataset } from "../../utils/api/dataset";
import { addDataset } from "../../store/actions/actions/dataset";

import { storeMetrics } from "../../store/actions/actions/metrics";

import { addInteractiveAnalysis } from "../../store/actions/actions/interactiveAnalysis";
import { uploadInteractiveAnalysis } from "../../utils/api/interactiveAnalysis";

import { uploadPublication } from "../../utils/api/publication";
import { createTeamMember } from "../../utils/api/team";
import { addPublication } from "../../store/actions/actions/publication";
import { addTeamMember } from "../../store/actions/actions/team";

import { uploadQuestionLogic } from "../../utils/api/questionLogic";
import { addQuestion } from "../../store/actions/actions/question";

import { uploadSliceAndDice } from "../../utils/api/sliceAndDice";

import { createSection, fetchAllSections } from "../../utils/api/cms/section";
import { createPage } from "../../utils/api/cms/page";
import { addPage } from "../../store/actions/actions/cms/pages";
import { addSection } from "../../store/actions/actions/cms/sections";
import Chip from "./chip";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Upload = () => {
  const dispatch = useDispatch();

  const token = JSON.parse(localStorage.getItem("eko-360-p2-token")),
    metrics = useSelector((state) => state.metrics.metrics);

  const [descriptionValue, setDescriptionValue] = useState(""),
    [datasetName, setDatasetName] = useState(""),
    [file, setFile] = useState(null),
    [showLoader, setShowLoader] = useState(false),
    [datasetCategory, setDatasetCategory] = useState(""),
    [showInteractiveAnalysisLoader, setShowInteractiveAnalysisLoader] =
      useState(false),
    [showPublicationLoader, setShowPublicationLoader] = useState(false),
    [showTeamLoader, setShowTeamLoader] = useState(false);

  const [datasetType, setDatasetType] = useState("file"),
    [title, setTitle] = useState(""),
    [consent, setConsent] = useState(false),
    [email, setEmail] = useState("");

  const [interactiveAnalysisTitle, setInteractiveAnalysisTitle] = useState(""),
    [interactiveAnalysisDescription, setInteractiveAnalysisDescription] =
      useState(""),
    [interactiveAnalysisType, setInteractiveAnalysisType] = useState("image"),
    [interactiveAnalysisFile, setInteractiveAnalysisFile] = useState(undefined),
    [interactiveCategory, setInteractivityCategory] = useState("");

  const [publicationTitle, setPublicationTitle] = useState(""),
    [publicationBody, setPublicationBody] = useState(""),
    [publicationIframe, setPublicationIframe] = useState(""),
    [publicationBanner, setPublicationBanner] = useState(undefined),
    [publicationFile, setPublicationFile] = useState(undefined);

  const [teamName, setTeamName] = useState(""),
    [teamPosition, setTeamPosition] = useState(""),
    [teamOffice, setTeamOffice] = useState(""),
    [teamImage, setTeamImage] = useState(undefined);

  const [keyword, setKeyword] = useState([]),
    [question, setQuestion] = useState(""),
    [questionDescription, setQuestionDestription] = useState(""),
    [questionFinding, setQuestionFinding] = useState(""),
    [questionType, setQuestionType] = useState("file"),
    [analytics, setAnalytics] = useState(""),
    [showQuestionLoader, setShowQuestionLoader] = useState(false);

  const [sectionName, setSectionName] = useState(""),
    [sectionBody, setSectionBody] = useState(undefined),
    [sectionPosition, setSectionPosition] = useState("right"),
    [sectionType, setSectionType] = useState("image"),
    [showSectionLoader, setShowSectionLoader] = useState(false);

  const [sectionOptions, setSectionOptions] = useState([]),
    [pageTitle, setPageTitle] = useState(""),
    [pageBody, setPageBody] = useState(""),
    [pageBanner, setPageBanner] = useState(undefined),
    [pageSections, setPageSections] = useState(""),
    [showPageLoader, setShowPageLoader] = useState(false),
    [pageType, setPageType] = useState("pages"),
    [datasetFile, setDatasetFile] = useState(undefined),
    [iFrame, setPageIframe] = useState(undefined);

  const [budgets, setBudget] = useState("");

  const [sliceAndDiceFile, setSliceAndDiceFile] = useState(undefined),
    [showSliceAndDiceLoader, setShowSliceAndDiceLoader] = useState(false);

  const handleCloseSliceAndDice = () => {
    document.querySelector(".dismiss-sliceAndDice-modal").click();
  };

  const handleSubmitSliceAndDice = async () => {
    setShowSliceAndDiceLoader(true);
    try {
      if (!sliceAndDiceFile)
        throw { message: "Please attach a corresponding file" };
      const formData = new FormData();
      formData.append("file", sliceAndDiceFile);

      const data = await uploadSliceAndDice(formData, token);

      toast.success(`${data.message}`);
      handleCloseSliceAndDice();
    } catch (error) {
      errorHandler(error, toast);
    } finally {
      setShowSliceAndDiceLoader(false);
    }
  };

  useEffect(() => {
    window.$(function () {
      window.$(".showButton").click(function () {
        window.$(".hidden1").show();
        window.$(".show1").hide();
        window.$(".__hidden2").hide();
      });

      window.$(".hideButton").click(function () {
        window.$(".hidden1").hide();
        window.$(".hidden2").hide();
        window.$(".show1").show();
        window.$(".__hidden2").show();
      });

      window.$(".showButton1").click(function () {
        window.$(".hidden2").show();
        window.$(".show2").show();
        window.$(".hidden1").hide();
        window.$(".__hidden2").hide();
      });

      window.$(".hideButton1").click(function () {
        window.$(".__hidden2").hide();
        window.$(".hidden2").hide();
        window.$(".show2").show();
        window.$(".hidden1").show();
      });

      window.$(".showButton2").click(function () {
        window.$(".__hidden2").hide();
        window.$(".hidden1").hide();
        window.$(".hidden2").hide();
        window.$(".show4").show();
      });
    });
  }, []);

  useEffect(() => {
    window.$(".js-select2").select2({
      closeOnSelect: true,
      placeholder: "Select Sections",
      templateSelection: iformat,
      templateResult: iformat,
      allowHtml: true,
      allowClear: true,
      width: "100%",
    });

    function iformat(icon, badge) {
      var originalOption = icon.element;
      var originalOptionBadge = window.$(originalOption).data("badge");

      return window.$(
        '<span><i className="fa ' +
          window.$(originalOption).data("icon") +
          '"></i> ' +
          icon.text +
          '<span className="badge">' +
          originalOptionBadge +
          "</span></span>"
      );
    }
  }, []);

  const handleFetchAllSections = async () => {
    try {
      const data = await fetchAllSections();

      const options = data.allSections.map((section) => ({
        value: section.id,
        label: section.name,
      }));

      setSectionOptions(options);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  useEffect(() => {
    handleFetchAllSections();
  }, []);

  const handleSubmitQuestion = async (event) => {
    event.preventDefault();
    setShowQuestionLoader(true);
    try {
      const formData = new FormData();

      formData.append("question", question);
      formData.append("description", questionDescription);
      formData.append("keywords", keyword.join(","));
      formData.append("analytics", analytics);
      formData.append("findings", questionFinding);
      formData.append("type", questionType);

      const data = await uploadQuestionLogic(formData, token);
      setTimeout(() => {
        toast.success(`${data.message}`);
      }, 300);
      dispatch(addQuestion(data.data));
      resetQuestionForm();
      document.querySelector(".question-modal").click();
      setShowQuestionLoader(false);
    } catch (error) {
      setShowQuestionLoader(false);
      errorHandler(error, toast);
    }
  };

  const resetQuestionForm = () => {
    setKeyword([]);
    setQuestion("");
    setQuestionDestription("");
    setQuestionFinding("");
    setQuestionType("file");
    setAnalytics("");
    setShowQuestionLoader(false);
  };

  const handleSubmitDataset = async () => {
    setShowLoader(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", datasetName);
      formData.append("description", descriptionValue);
      formData.append("title", title);
      formData.append("email", email);
      formData.append("consent", consent);
      formData.append("type", datasetType);
      formData.append("category", datasetCategory);
      formData.append(
        "fullName",
        JSON.parse(localStorage.getItem("eko-360-p2-user")).name
      );

      const dataset = await uploadDataset(formData, token);

      dispatch(addDataset(dataset.data));
      dispatch(
        storeMetrics({ ...metrics, datasetCount: metrics.datasetCount + 1 })
      );
      resetDatasetForm();
      resetModalForm();
      setShowLoader(false);
      document.querySelector(".dismiss-dataset-modal").click();
      toast.success(`${dataset.message}`);
    } catch (error) {
      setShowLoader(false);
      errorHandler(error, toast);
    }
  };

  const resetModalForm = () => {
    setTimeout(() => {
      window.$(".hidden1").hide();
      window.$(".__hidden2").show();
      window.$(".show1").show();
      window.$(".hidden2").hide();
      window.$(".show4").show();
    }, 300);
  };

  const resetPageModal = () => {
    setPageTitle("");
    setPageBody("");
    setPageBanner(undefined);
    setPageSections("");
    setPageIframe(undefined);
  };

  const resetDatasetForm = () => {
    setFile(null);
    setDatasetName("");
    setDescriptionValue("");
    setTitle("");
    setEmail("");
    setConsent(false);
    setDatasetType("file");
  };

  const resetPublicationForm = () => {
    setPublicationTitle("");
    setPublicationBody("");
    setPublicationIframe("");
    setPublicationBanner(undefined);
    setPublicationFile(undefined);
  };

  const resetTeamForm = () => {
    setTeamName("");
    setTeamPosition("");
    setTeamOffice("");
    setTeamImage(undefined);
  };

  const resetInteractiveAnalysisForm = () => {
    setInteractiveAnalysisFile(undefined);
    setInteractiveAnalysisType("image");
    setInteractiveAnalysisDescription("");
    setInteractiveAnalysisTitle("");
  };

  const handleSubmitInteractivity = async (event) => {
    event.preventDefault();
    setShowInteractiveAnalysisLoader(true);
    try {
      if (!interactiveAnalysisFile?.length)
        throw new Error("Please attach corrensponding file");

      const formData = new FormData();
      formData.append("title", interactiveAnalysisTitle);
      formData.append("description", interactiveAnalysisDescription);
      formData.append("type", interactiveAnalysisType);
      formData.append("category", interactiveCategory);

      if (interactiveAnalysisType === "image") {
        const multipleFiles = Array.from(interactiveAnalysisFile);
        multipleFiles.forEach((file) => {
          formData.append("files", file);
        });
      } else if (interactiveAnalysisType === "iframe") {
        formData.append("url", interactiveAnalysisFile);
      } else {
        formData.append("files", interactiveAnalysisFile[0]);
      }

      const interactiveAnalysis = await uploadInteractiveAnalysis(
        formData,
        token
      );

      dispatch(addInteractiveAnalysis(interactiveAnalysis.data));
      dispatch(
        storeMetrics({
          ...metrics,
          interactivityCount: metrics.interactivityCount + 1,
        })
      );
      toast.success(`${interactiveAnalysis.message}`);
      resetInteractiveAnalysisForm();

      setShowInteractiveAnalysisLoader(false);
      document.querySelector(".btn-close").click();
    } catch (error) {
      setShowInteractiveAnalysisLoader(false);
      errorHandler(error, toast);
    }
  };

  const handleSubmitPublication = async (event) => {
    event.preventDefault();
    setShowPublicationLoader(true);
    try {
      const formData = new FormData();
      formData.append("title", publicationTitle);
      formData.append("body", publicationBody);
      formData.append("banner", publicationBanner);
      formData.append("file", publicationFile);

      if (publicationIframe.trim().length > 0) {
        formData.append("iframe", publicationIframe);
      }

      const publication = await uploadPublication(formData, token);

      setShowPublicationLoader(false);
      toast.success(`${publication.message}`);
      resetPublicationForm();
      dispatch(addPublication(publication.data));

      document.getElementById("modal-idpub").click();
    } catch (error) {
      setShowPublicationLoader(false);
      errorHandler(error, toast);
    }
  };

  const handleSubmitTeam = async (event) => {
    event.preventDefault();
    setShowTeamLoader(true);
    try {
      const formData = new FormData();
      formData.append("name", teamName);
      formData.append("position", teamPosition);
      formData.append("office", teamOffice);
      formData.append("image", teamImage);

      const team = await createTeamMember(formData, token);

      setShowTeamLoader(false);
      toast.success(`${team.message}`);
      resetTeamForm();
      dispatch(addTeamMember(team.data));

      document.getElementById("modal-team").click();
    } catch (error) {
      setShowPublicationLoader(false);
      errorHandler(error, toast);
    }
  };

  const handleRemoveChip = (_, index) => {
    let keywordToUpdate = [...keyword];
    keywordToUpdate.splice(index, 1);
    setKeyword(keywordToUpdate);
  };

  const handleAddKeyword = (event) => {
    event.preventDefault();
    if (event.key === "Enter") {
      setKeyword([...keyword, event.target.value]);
      document.querySelector(".keyword").value = "";
    }
  };

  const resetSectionForm = () => {
    setSectionPosition("");
    setSectionType("image");
    setSectionBody("");
    setSectionName("");
  };

  const handleCreateSection = async () => {
    setShowSectionLoader(true);
    try {
      const formData = new FormData();

      formData.append("name", sectionName);
      formData.append("position", sectionPosition);
      formData.append("body", sectionBody);
      formData.append("type", sectionType);

      const data = await createSection(formData, token);

      dispatch(addSection(data.data));

      toast.success(`${data.message}`);
      resetSectionForm();
      document.querySelector(".dismiss-section-modal").click();
      setShowSectionLoader(false);
    } catch (error) {
      setShowSectionLoader(false);
      errorHandler(error, toast);
    }
  };

  const handleCreatePage = async () => {
    setShowPageLoader(true);
    try {
      let sectionValues = "";
      if (pageSections.length > 0) {
        sectionValues = pageSections.map((section) => section.value).join(",");
      }

      const formData = new FormData();

      if (pageType === "datasets" && datasetFile) {
        formData.append("file", datasetFile);
      }

      if (pageType === "analysis") {
        formData.append("iFrame", iFrame);
      }

      if (datasetFile) {
        formData.append("budget", budgets);
      }

      formData.append("type", pageType);
      formData.append("title", pageTitle);
      formData.append("body", pageBody);
      formData.append("banner", pageBanner);
      formData.append("sections", sectionValues);

      const data = await createPage(formData, token);

      toast.success(`${data.message}`);

      dispatch(addPage(data.data));
      resetPageModal();
      document.querySelector(".page-modal").click();
    } catch (error) {
      errorHandler(error, toast);
    } finally {
      setShowPageLoader(false);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="modal-id"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close dismiss-dataset-modal"
                id="dismiss-dataset-modal"
                data-dismiss="modal"
                aria-hidden="Close"
                onClick={() => resetModalForm()}
              ></button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps">
                    Step
                    <span className="__hidden2">1</span>
                    <span className="hidden1">2</span>
                    <span className="hidden2">3</span>
                    of 3
                  </p>
                  <p className="__modseps1">Dataset Details</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
                  <div className="text-center __hidden2">
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden1">
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden2">
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form encType="multipart/form-data">
                    <div className="__hidden2">
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Dataset Name
                        </label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="E.g. Child Care"
                          value={datasetName}
                          onChange={(event) =>
                            setDatasetName(event.target.value)
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="__modlab">Type</label>
                            <select
                              className="form-control __regdivform"
                              onChange={(event) =>
                                setDatasetType(event.target.value)
                              }
                            >
                              <option value="file">File</option>
                              <option value="link">Link</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Description
                        </label>
                        <textarea
                          className="form-control __regdivform"
                          rows="5"
                          value={descriptionValue}
                          onChange={(event) =>
                            setDescriptionValue(event.target.value)
                          }
                        ></textarea>
                      </div>
                      <div className="__spacet7p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <a className="btn btn-block __autbtn showButton show1">
                            Proceed
                          </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>

                    <div className="hidden1">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control __regdivform"
                              placeholder="Title"
                              value={title}
                              onChange={(event) => setTitle(event.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">
                              Category
                            </label>
                            <select
                              className="form-control __regdivform"
                              onChange={(event) => {
                                setDatasetCategory(event.target.value);
                              }}
                            >
                              <option value="" selected disabled>
                                Please select a category
                              </option>
                              <option value="education">Education</option>
                              <option value="health">Health</option>
                              <option value="economic affairs">
                                Economic Affairs
                              </option>
                            </select>
                          </div>
                        </div>

                        {datasetType === "link" && (
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control __regdivform"
                                placeholder="Embed Url"
                                value={file}
                                onChange={(event) =>
                                  setFile(event.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="__spacet5p"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn1 hideButton">
                            Back
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn showButton1 show2">
                            Continue
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="hidden2">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control __regdivform"
                          placeholder="Email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                      <div
                        className="form-group"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="checkbox"
                          id="consent"
                          // className="form-control __regdivform"
                          placeholder="Email"
                          value={consent}
                          onChange={(event) => setConsent(event.target.checked)}
                          style={{ width: "15px", cursor: "pointer" }}
                          checked={consent === true}
                        />

                        <label
                          htmlFor="consent"
                          className="__modlab"
                          style={{
                            marginBottom: 0,
                            marginTop: "5px",
                            marginLeft: "10px",
                          }}
                        >
                          I confirm that all the information that I have
                          provided are genuine and valid.
                        </label>
                      </div>
                      <div className="__spacet5p"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn1 hideButton1 ">
                            Back
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a
                            className="btn btn-block __autbtn"
                            onClick={handleSubmitDataset}
                          >
                            Finish{" "}
                            {showLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className=" __it">
                    <div className="uploadDoc">
                      <div className="fileUpload btn btn-orange">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        {datasetType === "file" && (
                          <span className="__upl">
                            Browse <span>file here</span>
                          </span>
                        )}
                        {datasetType !== "file" && (
                          <span className="__upl">
                            <span>
                              Select "file" as type of dataset to enable
                            </span>
                          </span>
                        )}
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          id="dataset-file"
                          onChange={(event) => setFile(event.target.files[0])}
                          disabled={datasetType !== "file"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-quesId"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close question-modal"
                data-dismiss="modal"
                aria-hidden="Close"
                onClick={() => resetModalForm()}
              ></button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps">
                    Step
                    <span className="__hidden2">1</span>
                    <span className="hidden1">2</span>
                    of 2
                  </p>
                  <p className="__modseps1">Question Logic</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
                  <div className="text-center __hidden2">
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden1">
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden2">
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form encType="multipart/form-data">
                    <div className="__hidden2">
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Question
                        </label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="E.g. How many buses are owned by Lagos state Govt."
                          value={question}
                          onChange={(event) => setQuestion(event.target.value)}
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">
                              Type
                            </label>
                            <select
                              className="form-control __regdivform"
                              onChange={(event) => {
                                setQuestionType(event.target.value);
                                setAnalytics("");
                              }}
                            >
                              <option value="file">File</option>
                              <option value="iframe">Link</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Description
                        </label>
                        <textarea
                          className="form-control __regdivform"
                          rows="5"
                          value={questionDescription}
                          onChange={(event) =>
                            setQuestionDestription(event.target.value)
                          }
                        ></textarea>
                      </div>
                      <div className="__spacet7p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <a className="btn btn-block __autbtn showButton show1">
                            Proceed
                          </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>

                    <div className="hidden1">
                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control __regdivform"
                          placeholder="Findings"
                          rows="5"
                          value={questionFinding}
                          onChange={(event) =>
                            setQuestionFinding(event.target.value)
                          }
                        ></textarea>
                      </div>
                      {questionType === "iframe" && (
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control __regdivform"
                            placeholder="Iframe"
                            value={analytics}
                            onChange={(event) =>
                              setAnalytics(event.target.value)
                            }
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Keywords
                        </label>
                        <div
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            {keyword.length
                              ? keyword.map((label, index) => (
                                  <Chip
                                    label={label}
                                    removeChip={(_) =>
                                      handleRemoveChip(_, index)
                                    }
                                    key={index}
                                  />
                                ))
                              : null}
                          </div>
                          <input
                            type="text"
                            className="form-control1 __regdivform1 keyword"
                            placeholder="Add keyword"
                            style={{
                              marginTop: "8px",
                              outline: "none",
                              width: "50%",
                              borderRight: "none",
                              borderTop: "none",
                              borderLeft: "none",
                              borderBottom: "1px solid #00000033",
                            }}
                            onKeyUp={handleAddKeyword}
                          />
                        </div>
                      </div>
                      <div className="__spacet5p"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn1 hideButton">
                            Back
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a
                            className="btn btn-block __autbtn"
                            onClick={handleSubmitQuestion}
                          >
                            Finish{" "}
                            {showQuestionLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className=" __it">
                    <div className="uploadDoc">
                      <div className="fileUpload btn btn-orange">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        {questionType === "file" && (
                          <span className="__upl">
                            Browse <span>file here</span>
                          </span>
                        )}
                        {questionType !== "file" && (
                          <span className="__upl">
                            <span>
                              Select "file" as type of Analytics to enable
                            </span>
                          </span>
                        )}
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          id="dataset-file"
                          onChange={(event) =>
                            setAnalytics(event.target.files[0])
                          }
                          disabled={questionType !== "file"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-id"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close del-page-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetModalForm()}
              ></button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps">
                    Step
                    <span className="__hidden2">1</span>
                    <span className="hidden1">2</span>
                    <span className="hidden2">3</span>
                    of 3
                  </p>
                  <p className="__modseps1">Dataset Details</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
                  <div className="text-center __hidden2">
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden1">
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden2">
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form encType="multipart/form-data">
                    <div className="__hidden2">
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Dataset Name
                        </label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="E.g. Child Care"
                          value={datasetName}
                          onChange={(event) =>
                            setDatasetName(event.target.value)
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">
                              Type
                            </label>
                            <select
                              className="form-control __regdivform"
                              onChange={(event) =>
                                setDatasetType(event.target.value)
                              }
                            >
                              <option value="file">File</option>
                              <option value="link">Link</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Description
                        </label>
                        <textarea
                          className="form-control __regdivform"
                          rows="5"
                          value={descriptionValue}
                          onChange={(event) =>
                            setDescriptionValue(event.target.value)
                          }
                        ></textarea>
                      </div>
                      <div className="__spacet7p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <a className="btn btn-block __autbtn showButton show1">
                            Proceed
                          </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>

                    <div className="hidden1">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control __regdivform"
                              placeholder="Title"
                              value={title}
                              onChange={(event) => setTitle(event.target.value)}
                            />
                          </div>
                        </div>
                        {datasetType === "link" && (
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control __regdivform"
                                placeholder="Embed Url"
                                value={file}
                                onChange={(event) =>
                                  setFile(event.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="__spacet5p"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn1 hideButton">
                            Back
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn showButton1 show2">
                            Continue
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="hidden2">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control __regdivform"
                          placeholder="Email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                      <div
                        className="form-group"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="checkbox"
                          id="consent"
                          className="form-control __regdivform"
                          placeholder="Email"
                          value={consent}
                          onChange={(event) => setConsent(event.target.checked)}
                          style={{ width: "15px" }}
                          checked={consent === true}
                        />

                        <label
                          htmlFor="consent"
                          className="__modlab"
                          style={{
                            marginBottom: 0,
                            marginTop: "5px",
                            marginLeft: "10px",
                          }}
                        >
                          I confirm that all the information that I have
                          provided are genuine and valid.
                        </label>
                      </div>
                      <div className="__spacet5p"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn1 hideButton1 ">
                            Back
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a
                            data-dismiss="modal"
                            data-toggle="modal"
                            className="btn btn-block __autbtn"
                            onClick={handleSubmitDataset}
                          >
                            Finish{" "}
                            {showLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className=" __it">
                    <div className="uploadDoc">
                      <div className="fileUpload btn btn-orange">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        {datasetType === "file" && (
                          <span className="__upl">
                            Browse <span>file here</span>
                          </span>
                        )}
                        {datasetType !== "file" && (
                          <span className="__upl">
                            <span>
                              Select "file" as type of dataset to enable
                            </span>
                          </span>
                        )}
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          id="dataset-file"
                          onChange={(event) => setFile(event.target.files[0])}
                          disabled={datasetType !== "file"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DATASET ENDS HERE */}

      <div
        className="modal fade"
        id="modal-idpub"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close del-page-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetPublicationForm()}
              >
                &times;
              </button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps1">Publications</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form>
                    <div className="__hidden2">
                      <div className="form-group">
                        <label className="__modlab">Publication Title</label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="E.g. Child Care"
                          value={publicationTitle}
                          onChange={(event) =>
                            setPublicationTitle(event.target.value)
                          }
                        />
                      </div>
                      <div className="row"></div>
                      <div className="form-group">
                        <label className="__modlab">Iframe</label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="Optional"
                          value={publicationIframe}
                          onChange={(event) =>
                            setPublicationIframe(event.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label className="__modlab">Description</label>
                        <textarea
                          className="form-control __regdivform"
                          rows="5"
                          value={publicationBody}
                          onChange={(event) =>
                            setPublicationBody(event.target.value)
                          }
                        ></textarea>
                      </div>
                      <div className="__spacet7p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <a
                            data-toggle="modal"
                            className="btn btn-block __autbtn"
                            onClick={handleSubmitPublication}
                          >
                            Finish
                            {showPublicationLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className=" __it" style={{ marginTop: "35px" }}>
                    <div className="uploadDoc" style={{ marginBottom: "20px" }}>
                      <div className="fileUpload btn btn-orange p-0">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        <span className="__upl">
                          Upload
                          <span>Banner</span>
                        </span>
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          onChange={(event) =>
                            setPublicationBanner(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                    <div className="uploadDoc">
                      <div className="fileUpload btn btn-orange p-0">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        <span className="__upl">
                          Upload
                          <span>file</span>
                        </span>
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          onChange={(event) =>
                            setPublicationFile(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-team"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close del-page-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetTeamForm()}
              >
                &times;
              </button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps1">Team</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form>
                    <div className="__hidden2">
                      <div className="form-group">
                        <label className="__modlab">Member Name</label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          value={teamName}
                          onChange={(event) => setTeamName(event.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label className="__modlab">Member role</label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="e.g Personal Assistant"
                          value={teamPosition}
                          onChange={(event) =>
                            setTeamPosition(event.target.value)
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label className="__modlab">Member Office</label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="e.g Ministry of Education"
                          value={teamOffice}
                          onChange={(event) =>
                            setTeamOffice(event.target.value)
                          }
                        />
                      </div>
                      <div className="__spacet7p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <button
                            data-toggle="modal"
                            className="btn btn-block __autbtn"
                            onClick={handleSubmitTeam}
                          >
                            Create Member
                            {showTeamLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className=" __it" style={{ marginTop: "35px" }}>
                    <div className="uploadDoc" style={{ marginBottom: "20px" }}>
                      <div className="fileUpload btn btn-orange p-0">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        <span className="__upl">
                          Upload{" "}
                          <span>{teamImage ? teamImage.name : "Image"}</span>
                        </span>
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          onChange={(event) =>
                            setTeamImage(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-idia"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                class="btn-close __close "
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetInteractiveAnalysisForm()}
              >
                &times;
              </button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps"></p>
                  <p className="__modseps1">Interactive Analysis</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
                  <div className="text-center hidden1">
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden2">
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form encType="multipart/form-data">
                    <div className="__hidden2">
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="Interactive Analysis Title"
                          value={interactiveAnalysisTitle}
                          onChange={(event) =>
                            setInteractiveAnalysisTitle(event.target.value)
                          }
                        />
                      </div>
                      {interactiveAnalysisType === "iframe" && (
                        <div className="form-group">
                          <label htmlFor="" className="__modlab">
                            iFrame
                          </label>
                          <input
                            type="text"
                            className="form-control __regdivform"
                            placeholder="Interactive Analysis Title"
                            value={interactiveAnalysisFile}
                            onChange={(event) =>
                              setInteractiveAnalysisFile(event.target.value)
                            }
                          />
                        </div>
                      )}

                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Category
                        </label>
                        <select
                          className="form-control __regdivform"
                          onChange={(event) => {
                            setInteractivityCategory(event.target.value);
                          }}
                        >
                          <option value="" selected disabled>
                            Please select a category
                          </option>
                          <option value="education">Education</option>
                          <option value="health">Health</option>
                          <option value="economic affairs">
                            Economic Affairs
                          </option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Description
                        </label>
                        <textarea
                          className="form-control __regdivform"
                          rows="5"
                          value={interactiveAnalysisDescription}
                          onChange={(event) =>
                            setInteractiveAnalysisDescription(
                              event.target.value
                            )
                          }
                        ></textarea>
                      </div>
                      <div className="__spacet20p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <a
                            className="btn btn-block __autbtn"
                            onClick={handleSubmitInteractivity}
                          >
                            Finish{" "}
                            {showInteractiveAnalysisLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className="form-group __seleimgdiv">
                    <label htmlFor="" className="__modlab">
                      Select file to Upload
                    </label>
                    <select
                      className="form-control __regdivform"
                      onChange={(event) => {
                        setInteractiveAnalysisFile(undefined);
                        setInteractiveAnalysisType(event.target.value);
                      }}
                      value={interactiveAnalysisType}
                    >
                      <option value="video">MP4</option>
                      <option value="image">Image</option>
                      <option value="iframe">iFrame</option>
                    </select>
                  </div>
                  <div className=" __it">
                    <div className="uploadDoc">
                      <div className="fileUpload btn btn-orange">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        {interactiveAnalysisType !== "iframe" ? (
                          <span className="__upl">
                            Browse <span>file here</span>
                          </span>
                        ) : (
                          <span className="__modlab">
                            Select Images or mp4 to enable
                          </span>
                        )}
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          name="files"
                          multiple
                          onChange={(event) =>
                            setInteractiveAnalysisFile(event.target.files)
                          }
                          disabled={interactiveAnalysisType == "iframe"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-section"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close "
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetModalForm()}
              >
                &times;
              </button>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-5">
                  <p className="__modseps">
                    Step
                    <span className="__hidden2">1</span>
                    <span className="hidden1">2</span>
                    of 2
                  </p>
                  <p className="__modseps1">CMS section</p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                  <div className="text-center __hidden2">
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden1">
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form encType="multipart/form-data">
                    <div className="__hidden2">
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Section Name
                        </label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="statistics of private owned buses"
                          value={sectionName}
                          onChange={(event) =>
                            setSectionName(event.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Type
                        </label>
                        <select
                          className="form-control __regdivform"
                          onChange={(event) => {
                            setSectionType(event.target.value);
                            setSectionBody("");
                          }}
                        >
                          <option value="image">Image</option>
                          <option value="content">Content</option>
                          <option value="url">Url</option>
                        </select>
                      </div>

                      <div
                        className="__spacet7p"
                        style={{ marginTop: "27%" }}
                      ></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <a className="btn btn-block __autbtn showButton show1">
                            Proceed
                          </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>

                    <div className="hidden1">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">
                              Position
                            </label>
                            <select
                              className="form-control __regdivform"
                              onChange={(event) =>
                                setSectionPosition(event.target.value)
                              }
                            >
                              <option value="right">Right</option>
                              <option value="top">Top</option>
                              <option value="bottom">Bottom</option>
                              <option value="left">Left</option>
                              <option value="middle">Middle</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          {sectionType === "content" && (
                            <div className="form-group">
                              <label htmlFor="" className="__modlab">
                                Body
                              </label>
                              <textarea
                                className="form-control __regdivform"
                                rows="5"
                                value={sectionBody}
                                onChange={(event) =>
                                  setSectionBody(event.target.value)
                                }
                              ></textarea>
                            </div>
                          )}
                          {sectionType === "url" && (
                            <div className="form-group">
                              <label htmlFor="" className="__modlab">
                                Iframe
                              </label>
                              <input
                                type="text"
                                className="form-control __regdivform"
                                placeholder="https://www.power-bi...."
                                value={sectionBody}
                                onChange={(event) =>
                                  setSectionBody(event.target.value)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="__spacet5p"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn1 hideButton">
                            Back
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a
                            className="btn btn-block __autbtn"
                            onClick={handleCreateSection}
                          >
                            Finish{" "}
                            {showSectionLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className=" __it">
                    <div className="uploadDoc">
                      <div className="fileUpload btn btn-orange">
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        {sectionType === "image" ? (
                          <span className="__upl">
                            Browse <span>file here</span>
                          </span>
                        ) : (
                          <span className="__upl">
                            <span>Select type "image" to enable</span>
                          </span>
                        )}
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          id="dataset-file"
                          onChange={(event) =>
                            setSectionBody(event.target.files[0])
                          }
                          disabled={sectionType !== "image"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-page"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close page-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetModalForm()}
              >
                &times;
              </button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps">
                    Step
                    <span className="__hidden2">1</span>
                    <span className="hidden1">2</span>
                    <span className="hidden2">3</span>
                    of 3
                  </p>
                  <p className="__modseps1">CMS Page Details</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
                  <div className="text-center __hidden2">
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden1">
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden2">
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form encType="multipart/form-data">
                    <div className="__hidden2">
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="E.g. Child Care"
                          value={pageTitle}
                          onChange={(event) => setPageTitle(event.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Type
                        </label>
                        <select
                          className="form-control __regdivform"
                          onChange={(event) => setPageType(event.target.value)}
                        >
                          <option value="pages">Pages</option>
                          <option value="quick_reads">Quick Reads</option>
                          <option value="analysis">Analysis</option>
                          <option value="budgets">Budgets</option>
                          <option value="datasets">Datasets</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Body
                        </label>
                        <div className="form-group">
                          <CKEditor
                            editor={ClassicEditor}
                            data={pageBody}
                            onChange={(_, editor) =>
                              setPageBody(editor.getData())
                            }
                          />
                        </div>
                      </div>
                      <div className="__spacet7p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <a className="btn btn-block __autbtn showButton show1">
                            Proceed
                          </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>

                    <div className="hidden1">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">
                              Sections
                            </label>
                            <Select
                              isMulti
                              options={sectionOptions}
                              onChange={(value) => setPageSections(value)}
                            />
                          </div>
                          {pageType === "budgets" && (
                            <div className="form-group">
                              <label htmlFor="" className="__modlab">
                                Budget
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={budgets}
                                onChange={(_, editor) =>
                                  setBudget(editor.getData())
                                }
                              />
                            </div>
                          )}
                          {pageType === "analysis" && (
                            <div className="form-group">
                              <label htmlFor="" className="__modlab">
                                iFrame
                              </label>
                              <input
                                type="text"
                                className="form-control __regdivform"
                                placeholder="http://www.powerbi..."
                                value={iFrame}
                                onChange={(event) =>
                                  setPageIframe(event.target.value)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="__spacet5p"
                        style={{ marginTop: "39%" }}
                      ></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn1 hideButton">
                            Back
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a
                            className="btn btn-block __autbtn"
                            onClick={handleCreatePage}
                          >
                            Finish{" "}
                            {showPageLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div
                    className=" __it"
                    style={{ marginTop: pageType === "datasets" ? 35 : null }}
                  >
                    <div
                      className="uploadDoc"
                      style={{
                        marginBottom: pageType === "datasets" ? 20 : null,
                      }}
                    >
                      <div
                        className={`fileUpload btn btn-orange ${
                          pageType === "datasets" ? "p-0" : ""
                        }`}
                      >
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        <span className="__upl">
                          Upload
                          <span>Banner</span>
                        </span>
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          onChange={(event) =>
                            setPageBanner(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                    {pageType === "datasets" && (
                      <div className="uploadDoc">
                        <div className="fileUpload btn btn-orange p-0">
                          <img
                            src="https://image.flaticon.com/icons/svg/136/136549.svg"
                            className="icon"
                            alt=""
                          />
                          <br />
                          <span className="__upl">
                            Upload
                            <span>file</span>
                          </span>
                          <input
                            type="file"
                            className="upload up"
                            required="required"
                            onChange={(event) =>
                              setDatasetFile(event.target.files[0])
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-sliceAndDice"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close dismiss-sliceAndDice-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseSliceAndDice}
              >
                &times;
              </button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps1">Slice and Dice</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-12 col-md-12 col-sm-12 __mobiup">
                  <div style={{ width: "50%", margin: "0 auto" }}>
                    <div className=" __it">
                      <div className="uploadDoc">
                        <div className="fileUpload btn btn-orange">
                          <img
                            src="https://image.flaticon.com/icons/svg/136/136549.svg"
                            className="icon"
                            alt=""
                          />
                          <br />
                          <span className="__upl">
                            Browse <span>file here</span>
                          </span>
                          <p style={{ fontSize: 12 }}>
                            {sliceAndDiceFile?.name}
                          </p>
                          <input
                            type="file"
                            className="upload up"
                            required="required"
                            id="dataset-file"
                            onChange={(event) =>
                              setSliceAndDiceFile(event.target.files[0])
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <form encType="multipart/form-data">
                      <div className="__spacet5p"></div>
                      <div
                        className="row"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="col-lg- col-md-6 col-sm-6 col-xs-6">
                          <a
                            className="btn btn-block __autbtn"
                            onClick={handleSubmitSliceAndDice}
                          >
                            Finish{" "}
                            {showSliceAndDiceLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer closeOnClick pauseOnHover></ToastContainer>
    </>
  );
};

export default Upload;
