import { SET_SECTIONS, ADD_SECTION } from "../../../actions/actiontypes";

const initialState = {
  sections: [],
};

const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };

    case ADD_SECTION: {
      const update = [action.payload, ...state.sections];
      return {
        ...state,
        sections: update,
      };
    }

    default:
      return state;
  }
};

export default pagesReducer;
