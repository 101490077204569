import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { saveAs } from "file-saver";
import Select from "react-select";

import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SideMenu from "../../../common/SideMenu";
import DashboardNav from "../../component/DashboardNav";

import UploadComponent from "../../../common/Upload";
import { errorHandler } from "../../../../utils/helpers/errorHandler";
import {
  fetchPage,
  updatePage,
  deletePage,
} from "../../../../utils/api/cms/page";

import { fetchAllSections } from "../../../../utils/api/cms/section";

import {
  updateStoredPage,
  updatePageStatus,
} from "../../../../store/actions/actions/cms/pages";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const OverViewPage = (props) => {
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));

  const selectedPage = useSelector((state) => state.pages.selected_page);

  const dispatch = useDispatch();
  const history = useHistory();
  const { slug } = useParams();

  const [fetchedPage, setFetchedPage] = useState(undefined);
  const [showDenyCmsLoader, setShowDenyCmsLoader] = useState(false);
  const [showAcceptCmsLoader, setShowAcceptCmsLoader] = useState(false);

  const [sectionOptions, setSectionOptions] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [pageBanner, setPageBanner] = useState(undefined);
  const [pageSections, setPageSections] = useState("");
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [pageType, setPageType] = useState("pages");
  const [datasetFile, setDatasetFile] = useState(undefined);

  const [pageBody, setPageBody] = useState("");

  const [budgets, setBudget] = useState("");

  const [showDeletePageLoader, setShowDeletePageLoader] = useState(false);

  const handleFetchAllSections = async () => {
    try {
      const data = await fetchAllSections();

      const options = data.allSections.map((section) => ({
        value: section.id,
        label: section.name,
      }));

      setSectionOptions(options);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  useEffect(() => {
    handleFetchAllSections();
  }, []);

  const resetModalForm = () => {
    setTimeout(() => {
      window.$(".hidden1").hide();
      window.$(".__hidden2").show();
      window.$(".show1").show();
      window.$(".hidden2").hide();
      window.$(".show4").show();
    }, 300);
  };

  const handleDeletePage = async () => {
    setShowDeletePageLoader(true);
    try {
      const data = await deletePage(fetchedPage.slug, token);

      setTimeout(() => {
        toast.success(`${data.message}`);
      }, 300);
      setShowDeletePageLoader(false);
      document.querySelector(".del-page-modal").click();
      history.push("/admin/resources");
    } catch (error) {
      setShowDeletePageLoader(false);
      errorHandler(error, toast);
    }
  };

  const handleUpdatePage = async () => {
    setShowPageLoader(true);
    try {
      let sectionValues = "";
      if (pageSections.length > 0) {
        sectionValues = pageSections.map((section) => section.value).join(",");
      }

      const formData = new FormData();
      if (pageBanner) {
        formData.append("banner", pageBanner);
      }

      if (pageType === "budgets") {
        formData.append("budget", budgets);
      }

      if (pageType === "datasets") {
        formData.append("file", datasetFile);
      }

      formData.append("type", pageType);
      formData.append("title", pageTitle);
      formData.append("body", pageBody);
      formData.append("sections", sectionValues);

      const data = await updatePage(slug, formData, token);

      toast.success(`${data.message}`);

      const sections = [...fetchedPage.sections];
      const newPage = { ...data.data, sections };
      const { title, body, type, budget } = newPage;

      setPageTitle(title);
      setPageType(type);
      if (budget) {
        setBudget(budget);
      }

      setPageBody(body);

      setFetchedPage(newPage);
      dispatch(updateStoredPage(newPage));
      document.querySelector(".page-modal").click();
    } catch (error) {
      errorHandler(error, toast);
    } finally {
      setShowPageLoader(false);
    }
  };

  const handleFetchPage = async () => {
    try {
      const data = await fetchPage(slug, token);

      setPageTitle(data.title);
      setPageBody(data.body);
      setPageType(data.type);
      if (data.budget) setBudget(data.body);

      setPageBody(data.body);

      setFetchedPage(data);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  useEffect(() => {
    handleFetchPage();
  }, [selectedPage]);

  const gotoHome = () => {
    history.push("/admin/resources");
  };

  const handleFileDownload = async () => {
    try {
      if (!fetchedPage?.banner)
        return toast.error("This page has no uploaded banner");
      const fileName = fetchedPage?.banner.substr(
        fetchedPage?.banner.lastIndexOf("/") + 1
      );

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/datasets/${fileName}/download/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      const blob = await res.blob();
      saveAs(blob, fileName);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  };

  const handleAcceptOrDeny = async (status) => {
    status === "published"
      ? setShowAcceptCmsLoader(true)
      : setShowDenyCmsLoader(true);
    try {
      let sectionValues = "";
      // console.log("WHAT DO WE HAVE IN SESSIONS -------->", fetchedPage);
      if (fetchedPage?.sections[0]) {
        sectionValues = fetchedPage?.sections
          .map((section) => section.id)
          .join(",");
      }

      const formData = new FormData();

      formData.append("status", status);
      formData.append("body", fetchedPage?.body);
      formData.append("type", fetchedPage?.type);
      formData.append("sections", sectionValues);
      formData.append("title", fetchedPage?.title);

      const data = await updatePage(slug, formData, token);

      setShowDenyCmsLoader(false);
      setShowAcceptCmsLoader(false);
      const page = { ...fetchedPage, status };
      dispatch(updatePageStatus(status));
      setFetchedPage(page);
      toast.success(`${data.message}`);
    } catch (error) {
      setShowDenyCmsLoader(false);
      setShowAcceptCmsLoader(false);
      errorHandler(error, toast);
    }
  };

  const title = "Overview";

  return (
    <>
      <div className="section body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              <SideMenu />
            </div>

            <div className="col-md-10">
              <DashboardNav page={title} />

              <section>
                <div className="__house">
                  <div className="__btcard">
                    <div className="__spacet3p"></div>
                    <div className="__btcardin1">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <p className="__subp">
                            Submission Date:{" "}
                            {moment(fetchedPage?.createdAt).format("LLLL")}
                            <br />{" "}
                            <span className="__subpen">
                              {fetchedPage?.status === "draft"
                                ? "Pending"
                                : fetchedPage?.status}
                            </span>
                          </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3"></div>
                      </div>
                      <hr />
                      <form>
                        <div className="row __flexsmail">
                          <div className="col-lg-6 col-md-6 col-sm-6 __mobidown">
                            <div className="form-group">
                              <label htmlFor="" className="__modlab">
                                Page Title
                              </label>
                              <p>{fetchedPage?.title}</p>
                            </div>

                            <div className="form-group">
                              <label htmlFor="" className="__modlab">
                                Body
                              </label>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: fetchedPage?.body,
                                }}
                              ></div>
                            </div>

                            <div className="__spacet7p"></div>
                            <div
                              className="form-group"
                              style={{ height: "45px" }}
                            ></div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 __mobiup">
                            <div className="__spacet7p"></div>
                            <div className=" __it">
                              <div className="uploadDoc">
                                <div className="fileUpload btn btn-orange">
                                  <img
                                    src="https://image.flaticon.com/icons/svg/136/136549.svg"
                                    className="icon"
                                    alt=""
                                  />
                                  <br />
                                  <br />
                                  <span
                                    style={{ color: "#07A0AB" }}
                                    onClick={handleFileDownload}
                                  >
                                    Click to download file
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <a
                              href=""
                              className="btn __profbtn"
                              onClick={(event) => {
                                event.preventDefault();
                                handleAcceptOrDeny("published");
                              }}
                            >
                              Accept & publish{" "}
                              {showAcceptCmsLoader && (
                                <img src="/assets/img/loader.gif" alt="" />
                              )}
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href=""
                              className="btn __dnbtn"
                              onClick={(event) => {
                                event.preventDefault();
                                handleAcceptOrDeny("unpublished");
                              }}
                            >
                              Deny{" "}
                              {showDenyCmsLoader && (
                                <img
                                  src="/assets/img/loader-dark-theme.gif"
                                  alt=""
                                />
                              )}
                            </a>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="text-end">
                              <a
                                className="btn __dnbtn1"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-pageId"
                              >
                                Edit
                              </a>
                              &nbsp;&nbsp;
                              <a
                                className="btn __dnbtnp"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-deletePage"
                              >
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="__spacet5p"></div>
                    </div>
                  </div>

                  <div className="__spacet7p"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-pageId"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close page-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetModalForm()}
              ></button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps">
                    Step
                    <span className="__hidden2">1</span>
                    <span className="hidden1">2</span>
                    <span className="hidden2">3</span>
                    of 3
                  </p>
                  <p className="__modseps1">CMS Page Details</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
                  <div className="text-center __hidden2">
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden1">
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden2">
                    <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                  <form encType="multipart/form-data">
                    <div className="__hidden2">
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="E.g. Child Care"
                          value={pageTitle}
                          onChange={(event) => setPageTitle(event.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">
                          Type
                        </label>
                        <select
                          className="form-control __regdivform"
                          onChange={(event) => setPageType(event.target.value)}
                        >
                          <option value="pages" selected={pageType === "pages"}>
                            Pages
                          </option>
                          <option
                            value="quick_reads"
                            selected={pageType === "quick_reads"}
                          >
                            Quick Reads
                          </option>
                          <option
                            value="analysis"
                            selected={pageType === "analysis"}
                          >
                            Analysis
                          </option>
                          <option
                            value="budgets"
                            selected={pageType === "budgets"}
                          >
                            Budgets
                          </option>
                          <option
                            value="datasets"
                            selected={pageType === "datasets"}
                          >
                            Datasets
                          </option>
                        </select>
                      </div>
                      <div className="form-group">
                        <CKEditor
                          editor={ClassicEditor}
                          data={pageBody}
                          onChange={(_, editor) =>
                            setPageBody(editor.getData())
                          }
                        />
                      </div>
                      <div className="__spacet7p"></div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                          <a className="btn btn-block __autbtn showButton show1">
                            Proceed
                          </a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                      </div>
                    </div>

                    <div className="hidden1">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">
                              Sections
                            </label>
                            <Select
                              isMulti
                              options={sectionOptions}
                              onChange={(value) => setPageSections(value)}
                            />
                          </div>
                          {pageType === "budgets" && (
                            <div className="form-group">
                              <label htmlFor="" className="__modlab">
                                Budget
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={budgets}
                                onChange={(_, editor) =>
                                  setBudget(editor.getData())
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="__spacet5p"
                        style={{ marginTop: "39%" }}
                      ></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a className="btn btn-block __autbtn1 hideButton">
                            Back
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4"></div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <a
                            className="btn btn-block __autbtn"
                            onClick={handleUpdatePage}
                          >
                            Finish{" "}
                            {showPageLoader && (
                              <img src="/assets/img/loader.gif" alt="" />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div
                    className=" __it"
                    style={{ marginTop: pageType === "datasets" ? 35 : null }}
                  >
                    <div
                      className="uploadDoc"
                      style={{
                        marginBottom: pageType === "datasets" ? 20 : null,
                      }}
                    >
                      <div
                        className={`fileUpload btn btn-orange ${
                          pageType === "datasets" ? "p-0" : ""
                        }`}
                      >
                        <img
                          src="https://image.flaticon.com/icons/svg/136/136549.svg"
                          className="icon"
                          alt=""
                        />
                        <br />
                        <span className="__upl">
                          Upload
                          <span>Banner</span>
                        </span>
                        <input
                          type="file"
                          className="upload up"
                          required="required"
                          onChange={(event) =>
                            setPageBanner(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                    {pageType === "datasets" && (
                      <div className="uploadDoc">
                        <div className="fileUpload btn btn-orange p-0">
                          <img
                            src="https://image.flaticon.com/icons/svg/136/136549.svg"
                            className="icon"
                            alt=""
                          />
                          <br />
                          <span className="__upl">
                            Upload
                            <span>file</span>
                          </span>
                          <input
                            type="file"
                            className="upload up"
                            required="required"
                            onChange={(event) =>
                              setDatasetFile(event.target.files[0])
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-deletePage"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close __close del-page-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="row __flexsmaila">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 __mobidown"
                  style={{ paddingTop: "1.5rem" }}
                >
                  <p>
                    Are you sure you want to delete this Page -{" "}
                    <strong>{fetchedPage?.title}</strong>?
                  </p>
                  <form
                    style={{
                      marginTop: "1.5rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      className="btn __autbtn"
                      onClick={handleDeletePage}
                      style={{
                        width: "120px",
                        marginRight: "15px",
                        marginTop: "2rem",
                      }}
                    >
                      Delete
                      {showDeletePageLoader && (
                        <img src="/assets/img/loader.gif" alt="" />
                      )}
                    </a>
                    <a
                      className="btn __dnbtn1"
                      style={{
                        width: "120px",
                        backgroundColor: "#fff",
                        border: "1px solid #0C9EA9",
                      }}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UploadComponent />

      <ToastContainer closeOnClick pauseOnHover></ToastContainer>
    </>
  );
};

export default OverViewPage;
