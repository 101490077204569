import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import { NavLink as Link} from "react-router-dom";

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SideMenu from '../../common/SideMenu';
import UploadComponent from '../../common/Upload.jsx';
import { fetchAllDataset } from '../../../utils/api/dataset';
import { setSelectedDataSet } from '../../../store/actions/actions/dataset';
// import UserAvatar from '../../common/UserAvatar';
import DashboardNav from '../component/DashboardNav';
import { downloadDataset } from '../../../utils/api/survey';
import Pagination from "../../../common/components/Pagination";

const SubmissionsPage = (props) => {
  const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;
  
  const dispatch = useDispatch();

  const token = JSON.parse(localStorage.getItem("eko-360-p2-token")); 
  const dataset = useSelector(state => state.dataset.dataset);
  const [datasets, setDatasets ] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isFiltering, setFiltering] = useState();
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);

  const fetchDataset = async (page) => {
    try {
      const data = await fetchAllDataset(token, page);

      setPage(data.page);
      setMaxPage(data.count);
      setDatasets(data.allDatasets);
      dispatch(setSelectedDataSet(data.allDatasets));
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  }

  useEffect(() => {
    if(dataset && dataset.length <= 1) {
      fetchDataset();
    }
  },[]);

  useEffect(() => {
		window.$('#openSidebar').click(function(){
		  window.$('.leftbar').addClass('biger');
		  });
		  window.$('#closebtn').click(function(){
			  window.$('.leftbar').removeClass('biger');
		  });
	  },[]);

  useEffect(() => {
    if(dataset && dataset.length > 0) {
      setDatasets(dataset);
    }
  },[dataset]);
  

  const handleDownloadFile = async (dataset) => {
    try {
      const fileName = dataset.file.substr(dataset.file.lastIndexOf('/')+1)
      await downloadDataset(fileName);
  
    } catch (error) {
      return toast.error('An unexpected error has occured, Please check your internet connection');
    }
  }

  const handleSearch = (event) => {
    event.preventDefault();

    let searchWord = event.target.value.toLowerCase();
    const datasetClone = [...dataset];
    const searchResult = datasetClone.filter(dataset => dataset.name.toLowerCase().includes(searchWord));

    setSearchResult(searchResult);
  }

  const handleFilterbyStatus = (event) => {
    event.preventDefault();

    let searchWord = event.target.value.toLowerCase();
    const datasetClone = [...dataset];

    const searchResult = datasetClone.filter(dataset => dataset.status.toLowerCase() === searchWord);

    setSearchResult(searchResult);
  }

  const handleFilterbyMonth = (event) => {
    event.preventDefault();
    setFiltering()

    event.target.value.toLowerCase().length > 0 ? setFiltering(true) : setFiltering(false);
    
    let searchWord = event.target.value.toLowerCase();
    const datasetClone = [...dataset];
    const searchResult = datasetClone.filter(dataset => (+dataset.createdAt.substr(dataset.createdAt.indexOf('-') + 1, dataset.createdAt.lastIndexOf('-') - dataset.createdAt.indexOf('-') - 1) === +searchWord));
    
    setSearchResult(searchResult);
  }

  const handleUpdateDataset = (data) => {
    dispatch(setSelectedDataSet(data));
  }

  const handleNextPagination = async (event) => {
    event.preventDefault();
    await fetchDataset(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await fetchDataset(page - 2);
  }

  const title = "Submissions"

return (
      <>
        <div className="section body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              <SideMenu />
            </div>

            <div className="col-md-10">
              <DashboardNav 
                page={title}
              />
              

              <section>
                <div className="__house">
                  <div className="__btcard">
                    <div className="__btcardin">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                          <p className="__btp">Submitted datasets</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3">
                          <select className="form-control __allsel" onChange={handleFilterbyStatus}>
                            <option value="">All Datasets</option>
                            <option value="draft">Draft</option>
                            <option value="published">Published</option>
                            <option value="declined">Rejected</option>
                          </select>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2">
                          <select className="form-control __allsel" onChange={handleFilterbyMonth}>
                            <option value="">Monthly</option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                          </select>
                        </div>
                        <div className="col-lg-1 col-md-1"></div>
                        <div className="col-lg-3 col-md-4 col-sm-4">
                          <form>
                            <div className="form-group">
                              <input type="text" className="form-control __btform" placeholder="Search" onChange={handleSearch} />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table __table table-striped'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Submitted date</th>
                            <th>Owner</th>
                            <th>File</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          { (datasets.length > 0 && searchResult.length === 0) ? (datasets.map(dataset => (
                            
                              <tr onClick={() => handleUpdateDataset(dataset)} key={dataset.uuid}>
                                <td>
                                  <span>Name</span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    <p className='__tablp'>
                                      <img
                                        src='/assets/img/clock.png'
                                        className='__tabimg'
                                        alt=''
                                      />
                                      &nbsp;&nbsp;{dataset.name}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span>
                                    Submitted date
                                  </span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    <p className='__tablp'>
                                      {moment(dataset.createdAt).format('LLLL')}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span>Owner</span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    <p className='__tablp'>
                                      <img
                                        src='/assets/img/clock.jpg'
                                        className='__tabimg2'
                                        alt=''
                                      />
                                      &nbsp;&nbsp;{dataset.email}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span>File format</span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    <p className='__tablp1'>
                                      <img
                                        src='/assets/img/attachment.png'
                                        className='__tabimg1'
                                        alt=''
                                      />
                                      &nbsp;&nbsp;
                                      {dataset.type}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span>Status</span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    {dataset.status === 'draft' && (<p
                                      className='__allstatus btn'
                                      style={{ color: '#464646', background: '#F0F0F0' }}>draft
                                    </p>)}

                                    {dataset.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{dataset.status}</p>)}

                                    {dataset.status === 'declined' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Rejected</p>)}
                                  </Link>
                                </td>
                              </tr>
                          ))): (
                            searchResult.map(dataset => (
                              <tr onClick={() => handleUpdateDataset(dataset)} key={dataset.uuid}>
                              <td>
                                <span>Name</span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  <p className='__tablp'>
                                    <img
                                      src='/assets/img/clock.png'
                                      className='__tabimg'
                                      alt=''
                                    />
                                    &nbsp;&nbsp;{dataset.name}
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <span>
                                  Submitted date
                                </span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  <p className='__tablp'>
                                    {moment(dataset.createdAt).format('LLLL')}
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <span>Owner</span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  <p className='__tablp'>
                                    <img
                                      src='/assets/img/clock.jpg'
                                      className='__tabimg2'
                                      alt=''
                                    />
                                    &nbsp;&nbsp;{dataset.email}
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <span>File format</span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  <p className='__tablp1'>
                                    <img
                                      src='/assets/img/attachment.png'
                                      className='__tabimg1'
                                      alt=''
                                    />
                                    &nbsp;&nbsp;
                                    {dataset.type}
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <span>Status</span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  {dataset.status === 'draft' && (<p
                                    className='__allstatus btn'
                                    style={{ color: '#464646', background: '#F0F0F0' }}>draft
                                  </p>)}

                                  {dataset.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{dataset.status}</p>)}

                                  {dataset.status === 'declined' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Rejected</p>)}
                                </Link>
                              </td>
                            </tr>
                          )))}
                        </tbody>
                      </table> 
                      { datasets.length === 0 && 
												<p style={{textAlign: "center" }}>There are no Datasets yet</p>}
                    </div>
                    <Pagination
                      page={+page}
                      maxPage={+maxPage}
                      SIZE_PER_PAGE={+SIZE_PER_PAGE}
                      handleNextPagination={handleNextPagination}
                      handlePreviousPagination={handlePreviousPagination}
                    />
                  </div>

                  <div className="__spacet7p"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <UploadComponent />

        <ToastContainer
          closeOnClick
          pauseOnHover
        ></ToastContainer>
      </>
    )
  }

export default SubmissionsPage;