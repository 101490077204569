import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { resetPassword } from '../../utils/api/authentication';

const ResetPasswordPage = () => {
  const history = useHistory();
  const params = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showLoader, setShowLoader] = useState(false);

  const handleResetPassword = async (event) => {
    setShowLoader(true);
    event.preventDefault();
    const {secret} = params;
    try {
      const data = await resetPassword({password, confirm_password: confirmPassword, secret });

      setTimeout(() => {
        toast.success(`${data.message}`);
      }, 500);

      setPassword('');
      setConfirmPassword('');
      setShowLoader(false);
      history.push('/login');
    } catch ({error}) {
      setShowLoader(false);
      if(Array.isArray(error.message)) {
        return toast.error(`${Object.values(error.message[0])[0]}`);
      }
      return toast.error(`${error.message}`);
    }
  }

  return (
    <>
      <header>
        <div className="__landbgex" style={{height: '100vh'}}>
          <Nav/>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="__captionex text-center">Register</p>
                <div className="__spacet3p"></div>
                <div className="__regdiv">
                  <form>

                    <div className="form-group">
                      <input type="password" className="form-control __regdivform" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="New passoword" />
                    </div>

                    <div className="form-group">
                      <input type="password" className="form-control __regdivform" value={confirmPassword} onChange={ e => setConfirmPassword(e.target.value)} placeholder="Confirm Password" />
                    </div>

                    <p className="__reg text-center">Already registered? <Link to="/login">Sign in</Link></p>


                    <div className="__spacet5p"></div>
                    <button type="submit" className="btn btn-block __regbtn" onClick={handleResetPassword}>RESET PASSWORD { showLoader && <img src="/assets/img/loader.gif" alt="" /> }</button>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
            </div>
          </div>
        </div>
      </header>

       <ToastContainer
        closeOnClick
        pauseOnHover
      >
      </ToastContainer>
    </>
  )
}

export default ResetPasswordPage;