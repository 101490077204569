import { 
  SET_SELECTED_DATASET, 
  SET_DATASET, ADD_DATASET, 
  UPDATE_DATASET_STATUS,
  UPDATE_DATASET
} from '../../actiontypes';


export const setSelectedDataSet = (payload) => ({
  type: SET_SELECTED_DATASET,
  payload
});

export const setAllDatasets = (payload) => ({
  type: SET_DATASET,
  payload
});

export const addDataset = (payload) => ({
  type: ADD_DATASET,
  payload
});

export const updateDatasetStatus = (status) => ({
  type: UPDATE_DATASET_STATUS,
  payload: {status}
});

export const updateDataset = (payload) => ({
  type: UPDATE_DATASET,
  payload
})