import React from 'react'
import Header from './Header'

const Nav = () => {
  return (
    <nav class="navbar navbar-expand-lg __navbar  navbar-dark">
        <div class="container">
              <a class="navbar-brand" href="#">
                <div className="display-flex">
                <a href="/"><img src="/assets/img/logo-alt.png" className="__logoimg_mobil" /></a>
                </div>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navmenu" aria-controls="navmenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            
            <Header />
        </div>

    </nav>
  )
}

export default Nav