import {SEARCH} from '../../actions/actiontypes';

const initialState = {
  query: '',
  // ministryId: null,
  // organizationId: null,
  // lgaId: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case SEARCH:
    return {
      ...state,
      ...action.payload
    }

    default: return state;
  }
}

export default reducer;