import React, { useEffect, useState } from 'react';
import {NavLink as Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { fetchAllSections } from "../../../../utils/api/cms/section";

import SideMenu from '../../../common/SideMenu';
import UploadComponent from '../../../common/Upload.jsx'
// import UserAvatar from '../../../common/UserAvatar.jsx';
import DashboardNav from '../../component/DashboardNav';

import Pagination from "../../../../common/components/Pagination";
import { errorHandler } from '../../../../utils/helpers/errorHandler';

import { deleteSection, updateSection } from "../../../../utils/api/cms/section";

import { setSections } from "../../../../store/actions/actions/cms/sections";

const UsersPage = (props) => {
  const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;
  
  const storedSections = useSelector(state => state.sections.sections);
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token")); 
  const dispatch = useDispatch();

	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);
	const [localSections, setLocalSections] = useState([]);


  const [searchResult, setSearchResult] = useState([]);
  const [selectedSection, setSelectedSection] = useState(undefined);

  const [sectionName, setSectionName] = useState('');
  const [sectionBody, setSectionBody] = useState(undefined);
  const [sectionPosition, setSectionPosition] = useState('right');
  const [sectionType, setSectionType] = useState('image');
  const [showSectionLoader, setShowSectionLoader] = useState(false);

  const [showDeleteSectionLoader, setShowDeleteSectionLoader] = useState(false);

  const handleFetchAllSections = async () => {
    try {
      const data = await fetchAllSections(page);

      setPage(data.page);
      setMaxPage(data.count);
      setLocalSections(data.allSections);

      dispatch(setSections(data.allSections));

    } catch (error) {
      errorHandler(error, toast);
    }
  }

  const resetModalForm = () => {
    setTimeout(() => {
      window.$('.hidden1').hide();
      window.$('.__hidden2').show();
      window.$('.show1').show();
      window.$('.hidden2').hide();
      window.$('.show4').show();
    }, 300)
  }

  useEffect(() => {
    if(storedSections.length === 0) {
      handleFetchAllSections();
    } else {
      setLocalSections(storedSections);
    }
  }, [storedSections]);

  useEffect(() => {
    window.$('#openSidebar').click(function(){
      window.$('.leftbar').addClass('biger');
      });
      window.$('#closebtn').click(function(){
          window.$('.leftbar').removeClass('biger');
      });
  },[])

  const resetSectionForm = () => {
    setSectionPosition('');
    setSectionType('image');
    setSectionBody('');
    setSectionName('');
  }

  const handleUpdateSection = async () => {
    setShowSectionLoader(true);
    try {
      const formData = new FormData();

      formData.append('name', sectionName);
      formData.append('position', sectionPosition);
      formData.append('body', sectionBody);
      formData.append('type', sectionType);

      const data = await updateSection(formData, selectedSection?.uuid, token);

      const indexToUpdate = localSections.findIndex(section => section.uuid === selectedSection?.uuid);

      localSections.splice(indexToUpdate, 1, data.data[0]);

      toast.success(`${data.message}`);
      resetSectionForm();
      setShowSectionLoader(false);
      document.querySelector('.section-modal').click();
    } catch (error) {
      setShowSectionLoader(false);
      errorHandler(error, toast) 
    }
  }

  const handleSetSection = (section) => {
    setSelectedSection(section);
    
    setSectionName(section?.name);
    setSectionBody(section?.body);
    setSectionPosition(section?.position);
    setSectionType(section?.type);

  }

  const handleDeleteSection = async () => {
    setShowDeleteSectionLoader(true);
    try {
      const data = await deleteSection(selectedSection?.uuid, token);

      const indexToRemove = localSections.findIndex(section => section.uuid === selectedSection?.uuid);

      localSections.splice(indexToRemove, 1);

      toast.success(`${data.message}`);
      resetSectionForm();
      setShowDeleteSectionLoader(false);
      document.querySelector('.del-section-modal').click();
    } catch (error) {
      setShowDeleteSectionLoader(false);
      errorHandler(error, toast);
    }
  }

  const handleNextPagination = async (event) => {
    event.preventDefault();
    await fetchAllSections(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await fetchAllSections(page - 2);
  }

  const title = "CMS"
  
  return (
    <>
      <div className='section body'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-2'>
              <SideMenu />
            </div>

            <div className='col-md-10'>
                <DashboardNav 
                  page={title}
                />
              

              <section>
                <div className='__house'>
                  <div className='__btcard'>
                    <div className='__btcardin'>
                      <div className='row'>
                        <div className='col-lg-8 col-md-8 col-sm-8'>
                          <p className='__btp'>Content Management System</p>
                        </div>
                        <div className='col-lg-5 col-md-3 col-sm-3'></div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table __table table-striped'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Registration date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          { (localSections.length > 0 && searchResult.length === 0) ? (localSections.map(section => (
                          <tr key={section.id}>
                          <td>
                            <span>Name</span>
                              <p className='__tablp'>
                                {section.name}
                              </p>
                          </td>
                          <td>
                            <span>
                              Creation date
                            </span>
                              <p className='__tablp'>
                                {moment(section.createdAt).format('LLLL')}
                              </p>
                          </td>
                          <td>
                            <a
                            data-bs-toggle="modal" data-bs-target="#modal-deleteSection"
                            onClick={() => handleSetSection(section)}
                           >
                            <i className="fa fa-trash" aria-hidden="true" style={{ color: "#ff00009e", marginRight: "10px", cursor: "pointer"}}></i>
                           </a>
                           <a
                            data-bs-toggle="modal" data-bs-target="#modal-editSection"
                            onClick={() => handleSetSection(section)}
                           >
                            <i className="fa fa-pencil-square-o" aria-hidden="true" style={{ color: "#07A0AB", cursor: "pointer" }}></i>
                          </a>
                          </td>
                        </tr>
                        ))): null}
                        </tbody>
                      </table>
                      { localSections.length === 0 && 
												<p style={{textAlign: "center" }}>There are no Sections yet</p>}
                    </div>
                    <Pagination
                      page={+page}
                      maxPage={+maxPage}
                      SIZE_PER_PAGE={+SIZE_PER_PAGE}
                      handleNextPagination={handleNextPagination}
                      handlePreviousPagination={handlePreviousPagination}
                    />
                  </div>

                  <div className='__spacet7p'></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal fade" id="modal-editSection" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close __close del-page-modal" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetModalForm()}></button>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-5">
                  <p className="__modseps">Step 
                    <span className="__hidden2">1</span>
                    <span className="hidden1">2</span>
                  of 2</p>
                  <p className="__modseps1">CMS section</p>
                </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                  <div className="text-center __hidden2">
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                  </div>

                  <div className="text-center hidden1">
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                      <form encType="multipart/form-data">
                        <div className="__hidden2">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">Section Name</label>
                            <input
                              type="text"
                              className="form-control __regdivform"
                              placeholder="statistics of private owned buses"
                              value={sectionName}
                              onChange={event => setSectionName(event.target.value)}
                            />
                          </div>
                              <div className="form-group">
                                <label htmlFor="" className="__modlab">Type</label>
                                <select
                                  className="form-control __regdivform"
                                  onChange={(event) => {
                                    setSectionType(event.target.value);
                                    setSectionBody('');
                                  }}
                                >
                                  <option value="image">Image</option>
                                  <option value="content">Content</option>
                                  <option value="url">Url</option>
                                </select>
                              </div>

                          <div className="__spacet7p" style={{ marginTop: "27%" }}></div>
                            <div className="row">
                              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <a className="btn btn-block __autbtn showButton show1">Proceed</a>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                            </div>
                          </div>

                        <div className="hidden1">
                      <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="" className="__modlab">Position</label>
                                <select
                                  className="form-control __regdivform"
                                  onChange={(event) => setSectionPosition(event.target.value)}
                                >
                                  <option value="right" selected={selectedSection?.position === 'right'}>Right</option>
                                  <option value="top" selected={selectedSection?.position === 'top'}>Top</option>
                                  <option value="bottom" selected={selectedSection?.position === 'bottom'}>Bottom</option>
                                  <option value="left" selected={selectedSection?.position === 'left'}>Left</option>
                                </select>
                              </div>
                            </div>

                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              {sectionType === 'content' && (<div className="form-group">
                                <label htmlFor="" className="__modlab">Body</label>
                                <textarea 
                                  className="form-control __regdivform"
                                  rows="5"
                                  value={sectionBody}
                                  onChange={event => setSectionBody(event.target.value)}
                                ></textarea>
                              </div>)}
                              {sectionType === 'url' && (<div className="form-group">
                                <label htmlFor="" className="__modlab">Iframe</label>
                                <input
                                  type="text"
                                  className="form-control __regdivform"
                                  placeholder="https://www.power-bi...."
                                  value={sectionBody}
                                  onChange={event => setSectionBody(event.target.value)}
                                />
                              </div>)}

                            </div>

                          </div>
                      <div className="__spacet5p"></div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                              <a className="btn btn-block __autbtn1 hideButton">Back</a>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4"></div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <a
                                  className="btn btn-block __autbtn"
                                  onClick={handleUpdateSection}
                                >
                                  Update { showSectionLoader && <img src="/assets/img/loader.gif" alt="" /> }
                                </a>
                            </div>
                        </div>
                      </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                  <div className=" __it">
                    <div className="uploadDoc">
                      <div className="fileUpload btn btn-orange">
                          <img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" alt="" /><br />
                          {sectionType === 'image' ? (
                            <span className="__upl">Browse <span>file here</span></span>
                          ):(
                            <span className="__upl"><span>Select type "image" to enable</span></span>
                          )}
                          <input type="file" className="upload up" required="required" id="dataset-file" onChange={event => setSectionBody(event.target.files[0])} disabled={sectionType !== 'image'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       <div className="modal fade" id="modal-deleteSection" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close __close del-page-modal" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="row __flexsmaila">
                <div className="col-lg-12 col-md-12 col-sm-12 __mobidown" style={{ paddingTop: "1.5rem" }}>
                  <p>Are you sure you want to delete this Section - <strong>{selectedSection?.name}</strong>?</p>
                  <form style={{ marginTop: "1.5rem", display: "flex", justifyContent: "center" }}>
                    <a
                      className="btn __autbtn"
                      onClick={handleDeleteSection}
                      style={{ width: "120px", marginRight: "15px", marginTop: "2rem" }}
                    >
                      Delete
                      { showDeleteSectionLoader && <img src="/assets/img/loader.gif" alt="" /> }
                    </a>
                    <a
                      className="btn __dnbtn1"
                      style={{
                        width: "120px",
                        backgroundColor: "#fff",
                        border: "1px solid #0C9EA9",
                      }}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UploadComponent />

      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>

    </>
  )
}

export default UsersPage;