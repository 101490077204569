import React, { useEffect, useState, useRef } from 'react';

import ReactToPrint from 'react-to-print';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ResponsiveContainer } from 'recharts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  fetchCofogs,
  fetchAllLocations,
  fetchDataTables,
  fetchGraphNodes
} from "../../utils/api/sliceAndDice";
// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';
import { errorHandler } from "../../utils/helpers/errorHandler";
import DownloadApp from "../../common/components/DownloadApp";
import "./index.css";

import {
  Accordion,
  AccordionItem,
  // AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


const SliceAndDice = () => {

  

  const componentRef = useRef();

  const [cofogs, setCofogs] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [dataTables, setDataTables] = useState({});
  const [years, setYears] = useState([]);
  const [queryForVariables, setQueryForVariables] = useState('');
  const [graph, setGraph] = useState([]);
  const [legends, setLegends] = useState([]);

  const [selectedTable, setSelectedTable] = useState('');

  const [loc, setLoc] = useState([]);
  const [variable, setVariable] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);

  const [locationIsLoading, setLocationIsLoading] = useState(false);
  const [dataTableIsLoading, setDataTableIsLoading] = useState(false);

  const setLegendColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;


  const handleFetchGraphNodes = async (query) => {
    try {
      const { data } = await fetchGraphNodes(query);
      const graph = [];

      let years = Object.keys(data).map(variable => Object.keys(data[variable])).flat();
      years = Array.from(new Set(years)).sort((prev, next) => prev - next);

      setYears(years);

      years.map(year => {
        const vertice = { name: +year };
        for (let key in data) {
          if (data[key][+year]) {
            vertice[key] = data[key][+year];
          }
        }
        graph.push(vertice);
      });

      setGraph(graph);
      return graph;

    } catch (error) {
      errorHandler(error, toast);
    }
  }

  const handleFetchCofogs = async () => {
    try {
      const { data } = await fetchCofogs();

      setCofogs(data);
    } catch (error) {
      errorHandler(error, toast)
    }
  }

  const handleFetchAllLocations = async (query) => {
    setLocationIsLoading(true);
    try {
      const { data: { locations } } = await fetchAllLocations(query);


      setAllLocations(locations);
      setLocationIsLoading(false)
    } catch (error) {
      setLocationIsLoading(false);
      errorHandler(error, toast);
    }
  }

  const handleFetchDataTables = async (query) => {
    setDataTableIsLoading(true);
    try {
      const { data: { data_table } } = await fetchDataTables(query);

      setDataTables(data_table);
      setDataTableIsLoading(false);
    } catch (error) {
      setDataTableIsLoading(false);
      errorHandler(error, toast);
    }
  }

  useEffect(() => {
    handleFetchCofogs();
  }, []);

  const deselectTable = () => {
    Object.keys(dataTables).map(data => {
      document.getElementById(`${data}`).checked = false;
      if (dataTables[data].length) {
        dataTables[data].map(variable => document.getElementById(`${variable}`).checked = false);
      }
    });
  }

  const handlePopulateTopics = async (event) => {
    try {
      const checkedCofogs = cofogs.filter(data => document.getElementById(`${data.cofog}`).checked);
      let query = '';

      setDataTables([]);
      setYears([]);
      setAllLocations([]);

      cofogs.map(data => {
        document.getElementById(`${data.cofog}`).checked = document.getElementById(`${data.cofog}`).checked;
        if (data.organisations.length) {
          data.organisations.map(organisation => document.getElementById(`${organisation}`).checked = document.getElementById(`${data.cofog}`).checked);
        }
      });

      query = checkedCofogs.map(data => `cofog=${data.cofog}`).join('&');

      const organisationQuery = cofogs.map(data => {
        if (data.organisations.length) {
          return data.organisations.filter(organisation => document.getElementById(`${organisation}`).checked).join('$');
        }
      }).filter(value => value.length).join('$');

      setQueryForVariables(`${query}&organisation=${organisationQuery}`);

      handleFetchAllLocations(`${query}&organisation=${organisationQuery}`);

    } catch (error) {
      errorHandler(error, toast)
    }
  }

  const handlePopulateVariables = (location) => {
    deselectTable();
    try {
      if (loc.includes(location)) {
        const index = loc.findIndex(value => value === location);
        loc.splice(index, 1);
        setLoc(loc);
      } else {
        const newLoc = [...loc, location];
        setLoc(newLoc);
      }

      let locationQuery = [...loc, location].map(value => `location=${value}`).join('&');

      setQueryForVariables(`${queryForVariables}&${locationQuery}`);

      handleFetchDataTables(`${queryForVariables}&${locationQuery}`);
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  const handleFetchTableNodes = async (table) => {
    setYears([]);
    setSelectedYear([]);
    const tableValues = dataTables[table];
    setLegends(tableValues);

    if (selectedTable !== table) {
      setSelectedTable(table);
      deselectTable();
      document.getElementById(`${table}`).checked = true;
    }

    if (tableValues.length && document.getElementById(`${table}`).checked) {
      tableValues.map(value => document.getElementById(`${value}`).checked = true);
    } else {
      tableValues.map(value => document.getElementById(`${value}`).checked = false);
    }

    handleFetchGraphNodes(`${queryForVariables}&data_tables=${table}`);
  }

  const handleSelectOrganisation = async (event, cofog) => {
    setAllLocations([]);
    setDataTables([]);
    setYears([]);
    let query = '';

    document.getElementById(`${event.target.value}`).checked = document.getElementById(`${event.target.value}`).checked;
    document.getElementById(`${cofog}`).checked = true;

    const checkedCofogs = cofogs.filter(data => document.getElementById(`${data.cofog}`).checked);

    query = checkedCofogs.map(data => `cofog=${data.cofog}`).join('&');

    const organisationQuery = cofogs.map(data => {
      if (data.organisations.length) {
        return data.organisations.filter(organisation => document.getElementById(`${organisation}`).checked).join('$');
      }
    }).filter(value => value.length).join('$');

    setQueryForVariables(`${query}&organisation=${organisationQuery}`);
    
    handleFetchAllLocations(`${query}&organisation=${organisationQuery}`);
  }

  const handleFetchTableNodeForVariables = async (table, localVariable) => {
    setSelectedYear([]);
    if (variable.length === 0 && selectedTable !== table) {
      setVariable(dataTables[table]);
      setSelectedTable(table);
      document.getElementById(`${table}`).checked = true;
      document.getElementById(`${localVariable}`).checked = true;
    }

    if (variable.includes(localVariable)) {
      document.getElementById(`${localVariable}`).checked = document.getElementById(`${localVariable}`).checked;
    }

    if (variable.length > 0 && selectedTable !== table) {
      deselectTable();
      setVariable(dataTables[table]);
      setSelectedTable(table);
      document.getElementById(`${table}`).checked = true;
      document.getElementById(`${localVariable}`).checked = true;
    }

    let checkedBoxes = dataTables[table].filter(record => document.getElementById(`${record}`).checked);

    setLegends(checkedBoxes);
    handleFetchGraphNodes(`${queryForVariables}&data_tables=${table}`);
  }

  const handleSelectYear = async (year) => {
    try {
      if (selectedYear.includes(+year)) {
        const index = selectedYear.findIndex(value => +value === +year);
        selectedYear.splice(index, 1);
        setSelectedYear(selectedYear);
        if (selectedYear.length === 0) {
          const globalGraph = await handleFetchGraphNodes(`${queryForVariables}&data_tables=${selectedTable}`);
          setSelectedYear(globalGraph);
          return;
        } else {
          const globalGraph = await handleFetchGraphNodes(`${queryForVariables}&data_tables=${selectedTable}`);

          const yearlyGraph = globalGraph.filter(value => [...selectedYear].includes(+value.name));
          setGraph(yearlyGraph);
          return;
        }
      } else {
        const newYear = [...selectedYear, +year];
        setSelectedYear(newYear);
      }

      const globalGraph = await handleFetchGraphNodes(`${queryForVariables}&data_tables=${selectedTable}`);

      const yearlyGraph = globalGraph.filter(value => [...selectedYear, +year].includes(+value.name));
      setGraph(yearlyGraph);
    } catch (error) {
      errorHandler(error, toast);
    }
  }


  const handleDownloadExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(graph);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, selectedTable, `${Date.now()}-eko360-data-file}${fileExtension}`);
  }
  


  return (
    <>
      {/* <header className="__nehead"> */}
      <header>
        {/* <Nav/> */}
        <div className="__landbgex">
        <Nav/>
        
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-1 col-sm-1"></div>
              <div className="col-lg-6 col-md-10 col-sm-10">
                <p className="__captionex text-center">Slice & Dice</p>
                <p className="__captionex1 text-center">This facilitates access to data tables from health, education and economic affairs to provide a comprehensive statistical view of Lagos from these 3 sectors. Users can use these charts to make various inferences, deductions and download the dataset in PDF or Excel format.</p>
              </div>
              <div className="col-lg-3 col-md-1 col-sm-1"></div>
            </div>
          </div>
        </div>
      </header>

      <section>
        <div class="container">
        {/* <p class="__slicndp">This facilitates access to data tables from health, education and economic affairs to provide a comprehensive statistical view of Lagos from these 3 sectors. Users can use these charts to make various inferences, deductions and download the dataset in PDF or Excel format.</p> */}
        <p class="__slicndp">To interact with the Slice and Dice functionality; Select a Sector or Organization, select a topic of choice from the displayed topics, check the data variable(s) of interest and select a year to visualize or visualize all the years by default.</p>
        </div>
        <div className="container">
          <div className="__slicflx">
            <div className="__slicwid">
            <div>
                <h4 className='__slicdic'>Select Sector and Organizations</h4>
                
                <Accordion allowZeroExpanded>
                  
                <ul className="column">

                  {cofogs.length > 0 && cofogs.map((data, index) => (
                    <>
                      <AccordionItem key={index}>
                        <li  className='__secmb' style={{ fontWeight: "bold", display: "inline-block" }}> 
                          <AccordionItemButton>
                            <input
                              type="checkbox"
                              value={data.cofog}
                              id={data.cofog}
                              onChange={event => handlePopulateTopics(event, index)}
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                            /><label style={{ cursor: "pointer" }} htmlFor={data.cofog}>{data.cofog}</label>
                          </AccordionItemButton>
                          
                          <AccordionItemPanel>
                            <ul style={{ fontWeight: "normal", fontSize: "12px" }} className="truncate">
                              {data.organisations.length > 0 && data.organisations.map(organisation => (<li>
                                <div className='__congflex'>
                                <input
                                  type="checkbox"
                                  onChange={event => handleSelectOrganisation(event, data.cofog)}
                                  value={organisation}
                                  id={organisation}
                                  style={{
                                    display: "inline-block",
                                    marginRight: "2px",
                                    width: "10px",
                                    height: "10px",
                                    cursor: "pointer",
                                  }}
                                /><label className='__congflp' style={{ fontWeight: "normal", cursor: "pointer" }} htmlFor={organisation}>{organisation}</label></div></li>))}
                            </ul>
                          </AccordionItemPanel>
                        </li>
                      </AccordionItem>
                    </>
                  ))}
                </ul>
                
                </Accordion>
              </div>
            </div>
            <div className="__slicwid">
              <div>
                <h4 className='__slicdic'>Select Topic</h4>
                <ul className="column" style={{ fontSize: "12px" }}>
                  {allLocations.length > 0 && allLocations.map((location, index) => (
                    <>
                      <li
                        style={{
                          cursor: "pointer",
                          backgroundColor: loc.includes(location) ? '#175C7D' : '',
                          color: loc.includes(location) ? '#fff' : '#000',
                          display: loc.includes(location) ? 'block' : 'inherit'
                        }}
                        key={index}
                        onClick={() => handlePopulateVariables(location)}
                      >
                        {location}
                      </li>
                    </>
                  ))}
                  {(allLocations.length === 0 && locationIsLoading) && (<img src="/assets/img/slice-and-dice-loader.svg" alt="" />)}
                  {(allLocations[0] === null && locationIsLoading === false) && (<p>There are no locations for this organization</p>)}
                </ul>
              </div>
            </div>
            <div className="__slicwid">
              <div>
                <h4 className='__slicdic'>Data Tables and Variables</h4>
                <Accordion allowZeroExpanded>
                  <ul className="column">
                    {Object.keys(dataTables).length > 0 && Object.keys(dataTables).map((table, index) => (
                      <>
                      <AccordionItem key={index}>
                        <li style={{ fontWeight: "bold" }} className='__accodbefor'>
                          <AccordionItemButton>
                          <div className='__congflex'>
                            <input
                              type="checkbox"
                              value={table}
                              id={table}
                              onChange={() => handleFetchTableNodes(table)}
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                cursor: "pointer"
                              }} /><label className='__congflpt' htmlFor={table} style={{ cursor: "pointer", }}>{table}</label>
                              </div>
                          </AccordionItemButton>
                          <AccordionItemPanel>
                            <ul style={{ fontWeight: "normal", fontSize: "12px" }} className="truncate">
                              {dataTables[table].length > 0 && dataTables[table].map(variable => (<li className='__dislid'>
                              <div className='__congflex'>
                                <input
                                  type="checkbox"
                                  value={variable}
                                  id={variable}
                                  onChange={() => handleFetchTableNodeForVariables(table, variable)}
                                  style={{
                                    display: "inline-block",
                                    marginRight: "3px",
                                    width: "10px",
                                    height: "10px",
                                    cursor: "pointer"
                                  }} />
                                <label className='__congflp' htmlFor={variable} style={{ fontWeight: "normal", cursor: "pointer" }}>{variable}</label></div>
                              </li>))}
                            </ul>
                          </AccordionItemPanel>
                        </li>
                      </AccordionItem>
                      </>
                    ))}
                    {(Object.keys(dataTables).length === 0 && dataTableIsLoading) && (<img src="/assets/img/slice-and-dice-loader.svg" alt="" />)}
                  </ul>
                </Accordion>
              </div>
            </div>
            <div className="__slicwid">
              <div>
                <h4 className='__slicdic'>Years</h4>
                <ul className="column">
                  {years.length > 0 && years.map(year => (
                    <>
                      <li
                        style={{
                          cursor: "pointer",
                          backgroundColor: selectedYear.includes(+year) ? '#175C7D' : '',
                          color: selectedYear.includes(+year) ? '#fff' : '#000',
                        }}
                        onClick={() => handleSelectYear(year)}
                      >
                        {year}
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="container" style={{ marginTop: "5rem" }}>
            <div className="col-md-12">
              
              {graph.length > 0 && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: "3rem" }}>
                    <ReactToPrint
                      trigger={() => <button style={{
                        backgroundColor: '#175C7D',
                        color: "#fff",
                        border: 'none',
                        outline: 'none',
                        marginRight: '10px'
                      }}>Print PDF</button>}
                      content={() => componentRef.current}
                    />
                    <button
                      style={{
                        color: '#175C7D',
                        backgroundColor: "#fff",
                        border: '#175C7D',
                        outline: 'none'
                      }}
                      onClick={handleDownloadExcel}
                    >
                      Download Excel
                    </button>
                  </div>
                  
                  <div className='__chart-bg' style={{ background: `url(/assets/img/logo-alt1.png)` }} ref={componentRef}>
                  <p className='__chart-caption'>{selectedTable}</p>
                    <ResponsiveContainer minWidth={260} minHeight={240}>
                    <BarChart height={400} data={graph}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {legends.length > 0 && legends.map(legend => <Bar dataKey={legend} fill={setLegendColor()} />)}
                    </BarChart>
                    </ResponsiveContainer>
                  </div>
                </>
              )}
              </div>
          </div>
        </div>
        <div className="__spacet10p"></div>
        {/* <DownloadApp /> */}
      </section>
      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
      <Footer />
    </>
  );
};

export default SliceAndDice;