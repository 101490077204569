import React, { useEffect, useState, useContext, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {signupUser, signinUser as loginUser} from '../utils/api/authentication';
import { signin as signInUser } from '../store/actions/actions/user'


const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
};

export const useAuth = () => useContext(authContext);

const useProvideAuth = () => {
  
  const history = useHistory();
  
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  
  const signin = async (credentials) => {
    try {
      const { data: {user, token} } = await loginUser(credentials);

      setUser(user);
      localStorage.setItem('eko-360-p2-user', JSON.stringify(user));
      localStorage.setItem('eko-360-p2-token', JSON.stringify(token));
      dispatch(signInUser(user))
      return user;
    } catch (error) {
      throw error;
    }
  }

  const registerUser = async (credentials) => {
    try {
      const { user } = await signupUser(credentials);

      setUser(user);
      return user;
    } catch (error) {
      throw error;
    }
  }

  const signoutUser = () => {
    localStorage.removeItem('eko-360-p2-user');
    setUser(null);
    dispatch(signInUser(null));
    history.push('/');
  }

  useEffect(() => {
    const loggedInUser = user || JSON.parse(localStorage.getItem('eko-360-p2-user'));
    if(loggedInUser != null) {
      setUser(loggedInUser);
      dispatch(signInUser(loggedInUser));
    }
  }, []);

  return {
    user,
    signin,
    registerUser,
    signoutUser
  }
}