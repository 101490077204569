import React from 'react';

import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';

const ExploreDataPage = () => {
  return (
    <>
      	<header>
			<div className="__landbgex">
				<Nav/>
				<div className="container">
					<div className="row">
						<div className="col-lg-1 col-md-1 col-sm-1"></div>
						<div className="col-lg-10 col-md-10 col-sm-10">
							<p className="__captionex text-center">Explore Data in 4 Different Categories</p>
							<p className="__captionex1 text-center">Select a category to view the sub section, click on a sub section to view dataset. Open a dataset and scroll down to the question section to access the featured visualisations.</p>
							<p className="__captionex2 text-center">456,345,989,000 Datasets</p>
						</div>
						<div className="col-lg-1 col-md-1 col-sm-1"></div>
					</div>
				</div>
			</div>
		</header>

			<section>
				<div className="__explbg">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6">
								<a href=""><div className="__explcard text-center">
									<p className="__expp">MINISTRIES</p>
									<p className="__expp1">Select ministry to view the sub section which includes the three other categories, click on a sub section to view dataset.</p>
									<p className="__expp2">345,989,000 Datasets</p>
									<span className="btn __explcardbtn">View all</span>
								</div></a>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6">
								<a href=""><div className="__explcard1 text-center">
									<p className="__expp">ORGANIZATIONS</p>
									<p className="__expp1">Select ministry to view the sub section which includes the three other categories, click on a sub section to view dataset.</p>
									<p className="__expp2">345,989,000 Datasets</p>
									<span className="btn __explcardbtn1">View all</span>
								</div></a>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6">
								<a href=""><div className="__explcard2 text-center">
									<p className="__expp">LOCAL GOVERNMENTS</p>
									<p className="__expp1">Select ministry to view the sub section which includes the three other categories, click on a sub section to view dataset.</p>
									<p className="__expp2">345,989,000 Datasets</p>
									<span className="btn __explcardbtn2">View all</span>
								</div></a>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6">
								<a href=""><div className="__explcard3 text-center">
									<p className="__expp">ASSIGNED TAGS</p>
									<p className="__expp1">Select ministry to view the sub section which includes the three other categories, click on a sub section to view dataset.</p>
									<p className="__expp2">345,989,000 Datasets</p>
									<span className="btn __explcardbtn3">View all</span>
								</div></a>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6">
							<img src="/assets/img/sideimg1.png" className="__latflotimg hidden-xs" />
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 text-right">
							<img src="assets/img/sideimg.png" className="__latflotimg1 hidden-xs" />
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2 col-md-1 col-sm-1"></div>
						<div className="col-lg-8 col-md-10 col-sm-10">
							<p className="__latst text-center">Lastest Datasets</p>
							<p className="__latst1 text-center">Select a theme to view visualisations under various topics or scroll down to quickly access the featured visualisations.</p>
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="__latimg" style={{ background: 'linear-gradient(360deg, #175C7D 0%, rgba(255, 255, 255, 0) 100%), url(/assets/img/proj.png)' }}>
										<p className="__latimgp">Projections for the 2020/21 fiscal year</p>
									</div>
									<p className="__latp">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>
									<div className="row">
										<div className="col-xs-6">
											<a href="" className="btn btn-block hvr-float-shadow __latbtn"><img src="assets/img/downloadimg.png" /> PDF (232kB)</a>
										</div>
										<div className="col-xs-6">
											<a href="" className="btn btn-block hvr-float-shadow __latbtn1">Visualize Data</a>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="__latimg" style={{ background: 'linear-gradient(360deg, #175C7D 0%, rgba(255, 255, 255, 0) 100%), url(/assets/img/proj1.png)' }}>
										<p className="__latimgp">Shaping people’s responses</p>
									</div>
									<p className="__latp">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>
									<div className="row">
										<div className="col-xs-6">
											<a href="" className="btn btn-block hvr-float-shadow __latbtn"><img src="/assets/img/downloadimg.png" /> PDF (1MB)</a>
										</div>
										<div className="col-xs-6">
											<a href="" className="btn btn-block hvr-float-shadow __latbtn1">Visualize Data</a>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="__latimg" style={{ background: 'linear-gradient(360deg, #175C7D 0%, rgba(255, 255, 255, 0) 100%), url(assets/img/proj2.png)' }}>
										<p className="__latimgp">Post-COVID Recovery for Lagos</p>
									</div>
									<p className="__latp">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<a href="" className="btn btn-block hvr-float-shadow __latbtn"><img src="assets/img/downloadimg.png" /> PDF (232kB)</a>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="__latimg" style={{ background: 'linear-gradient(360deg, #175C7D 0%, rgba(255, 255, 255, 0) 100%), url(/assets/img/proj3.png)' }}>
										<p className="__latimgp">Population growth analysis</p>
									</div>
									<p className="__latp">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<a href="" className="btn btn-block hvr-float-shadow __latbtn"><img src="/assets/img/downloadimg.png" /> PDF (1MB)</a>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="__latimg" style={{ background: 'linear-gradient(360deg, #175C7D 0%, rgba(255, 255, 255, 0) 100%), url(/assets/img/proj4.png)' }}>
										<p className="__latimgp">Learning from home for pupils</p>
									</div>
									<p className="__latp">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<a href="" className="btn btn-block hvr-float-shadow __latbtn"><img src="assets/img/downloadimg.png" /> PDF (232kB)</a>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="__latimg" style={{ background: 'linear-gradient(360deg, #175C7D 0%, rgba(255, 255, 255, 0) 100%), url(assets/img/proj5.png)' }}>
										<p className="__latimgp">School resumption at a glance</p>
									</div>
									<p className="__latp">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<a href="" className="btn btn-block hvr-float-shadow __latbtn"><img src="/assets/img/downloadimg.png" /> PDF (1MB)</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-1 col-sm-1"></div>
					</div>
				</div>
				<div className="__spacet7p"></div>
				<div className="__appbgc">
					<div className="container">
						<div className="row __flexsmail">
							<div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
								<p className="__appp">Download our app</p>
								<p className="__appp1">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>

								<a href="" className="btn hvr-float-shadow __appbtn">Download</a>
							</div>
							<div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
								<img src="/assets/img/appimg.png" className="__appimg" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
    </>
  )
}

export default ExploreDataPage;