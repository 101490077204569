export const errorHandler = (error, notifier) => {
  const { statusCode } = error;
  if (typeof error.message === "string") {
    return notifier.error(`${error.message}`);
  }
  const { error: { message } } = error;

  if (typeof message === "string") {
    if (message.toLowerCase().includes('jwt expired') || statusCode === 401) {
      window.localStorage.removeItem('eko-360-p2-token');
      window.localStorage.removeItem('eko-360-p2-user');
      setTimeout(() => {
        notifier.error('The login has expired please login to continue');
        window.location.pathname = '/login';
      }, 500);

      return;
    }
    return notifier.error(`${message}`);
  }

  if (Array.isArray(message)) {
    const nextedErrorMsg = message[0][Object.keys(message[0])];
    return notifier.error(`${nextedErrorMsg}`);
  }
  if (message.toLowerCase().includes('jwt expired') || statusCode === 401) {
    window.localStorage.removeItem('eko-360-p2-token');
    window.localStorage.removeItem('eko-360-p2-user');
    setTimeout(() => {
      notifier.error('The login has expired please login to continue');
      window.location.pathname = '/login';
    }, 500);

    return;
  }
  if (typeof message !== "string" && Object.keys(message).length > 0) {
    const nextedErrorMsg = message[0][Object.keys(message[0])];
    return notifier.error(`${nextedErrorMsg}`);
  }
  return notifier.error(`${error.error.message}`);
}