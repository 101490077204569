import { 
  SET_PAGES,
  ADD_PAGE,
  UPDATE_PAGE_STATUS,
  UPDATE_PAGE,
  SET_SELECTED_PAGE
} from '../../../actiontypes';


export const setPages = (payload) => ({
  type: SET_PAGES,
  payload
});

export const addPage = (payload) => ({
  type: ADD_PAGE,
  payload
});

export const updatePageStatus = (payload) => ({
  type: UPDATE_PAGE_STATUS,
  payload
});

export const updateStoredPage = (payload) => ({
  type: UPDATE_PAGE,
  payload
});

export const setSelectedPage = (payload) => ({
  type: SET_SELECTED_PAGE,
  payload
});