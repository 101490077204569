import { SEARCH, SET_DATASET_TO_VISUALIZE } from '../../actiontypes';

export const storeSearchParams = (payload) => ({
  type: SEARCH,
  payload
})

export const setDataSetToVisualize = (dataset) => ({
  type: SET_DATASET_TO_VISUALIZE,
  payload: dataset
})