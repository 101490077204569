import {
  SET_PUBLICATIONS,
  SET_SELECTED_PUBLICATION,
  ADD_PUBLICATION,
  UPDATE_PUBLICATION_STATUS,
  UPDATE_PUBLICATION_DATA,
  DELETE_PUBLICATION,
  SET_PUBLICATION_TO_BE_VISUALIZED
} from "../../actiontypes";

export const setAllPublications = (payload) => ({
  type: SET_PUBLICATIONS,
  payload
});

export const setSelectedPublication = (payload) => ({
  type: SET_SELECTED_PUBLICATION,
  payload
});

export const addPublication = (payload) => ({
  type: ADD_PUBLICATION,
  payload
});

export const updatePublicationStatus = (status) => ({
  type: UPDATE_PUBLICATION_STATUS,
  payload: {status}
});

export const updatePublicationData = (payload) => ({
  type: UPDATE_PUBLICATION_DATA,
  payload
});

export const deletePublicationData = (uuid) => ({
  type: DELETE_PUBLICATION,
  payload: { uuid }
});

export const setPublicationToBeVisualized = (payload) => ({
  type: SET_PUBLICATION_TO_BE_VISUALIZED,
  payload
});