import { 
  SET_SELECTED_INTERACTIVE_ANALYSIS, 
  SET_INTERACTIVE_ANALYSIS,
  ADD_INTERACTIVE_ANALYSIS, 
  UPDATE_INTERACTIVE_ANALYSIS_STATUS,
  UPDATE_INTERACTIVE_ANALYSIS,
  DELETE_INTERACTIVE_ANALYSIS,
  SET_PUBLIC_INTERACTIVE_ANALYSIS_TO_BE_VISUALIZED
} from '../../actiontypes';


export const setSelectedInteractiveAnalysis = (payload) => ({
  type: SET_SELECTED_INTERACTIVE_ANALYSIS,
  payload
});

export const setAllInteractiveAnalysis = (payload) => ({
  type: SET_INTERACTIVE_ANALYSIS,
  payload
});

export const addInteractiveAnalysis = (payload) => ({
  type: ADD_INTERACTIVE_ANALYSIS,
  payload
});

export const updateInteractiveStatus = (status) => ({
  type: UPDATE_INTERACTIVE_ANALYSIS_STATUS,
  payload: {status}
});

export const updateInteractiveAnalysisData = (payload) => ({
  type: UPDATE_INTERACTIVE_ANALYSIS,
  payload
});

export const deleteInteractiveAnalysisData = (uuid) => ({
  type: DELETE_INTERACTIVE_ANALYSIS,
  payload: { uuid }
});

export const publicInteractiveAnalysisToBeVisualized = (payload) => ({
  type: SET_PUBLIC_INTERACTIVE_ANALYSIS_TO_BE_VISUALIZED,
  payload
});