import React from 'react';
import { Link } from 'react-router-dom';

const Missing = () => {
  return (
    <missing>
        <div className='container'>
            <div className="__mis-container">
                <p className='__mis-title'>Oops!</p>
                <p className='__mis-title-sub'>404 - PAGE NOT FOUND</p>
                <p className='__mis-discription'>The page you are looking for might have been removed <br/>had it's name changed or is temporarily unavailable.</p>
                <Link to='/' className='btn __mis-btn hvr-float-shadow'>GO TO HOMEPAGE</Link>
            </div>
        </div>
    </missing>
  )
};

export default Missing;