import React, { useEffect, useState } from 'react';

const WeatherSlider = () => {
  const [retrievedForcast, setRetrievedForcast] = useState([]),
    key = "cbbe0d7e6a7349e3b17230356202811",
    baseUrl = "https://api.weatherapi.com/v1/";

  const getHourFromDate = (dateString) => new Date(dateString).toLocaleString('en-US', { hour: 'numeric', hour12: true });

  const displayFutureForecast = (forecasts) => {
    return forecasts.map((weather, idx) => {
      if (idx === 0) {
        return (
          <div className="__weathercard col-md-2 slide" key={idx}>
            <img src={`https:${weather.condition.icon}`} className="__weathimg" />
            <p className="__weathp">{weather.temp_c}<sup>o</sup>C</p>
            <p className="__weathp1">{`Today, ${getHourFromDate(weather.last_updated)}`}</p>
            <p className="__weathp4">{weather.condition.text}</p>
          </div>
        );
      } else {
        return (<div className="__weathercard col-md-2 slide" key={idx}>
          <img src={`https:${weather.day.condition.icon}`} className="__weathimg" />
          <p className="__weathp">{weather.day.avgtemp_c}<sup>o</sup>C</p>
          <p className="__weathp1">{getDayFromDate(weather.date)}</p>
          <p className="__weathp4">{weather.day.condition.text}</p>
        </div>);
      }
    });
  }

  const getDayFromDate = (dateString) => {
    let date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  }

  const handleDisplay = (forecasts) => {
    setRetrievedForcast([forecasts.current, ...forecasts.forecast.forecastday.slice(1)]);
  }

  const getForecast = async (location) => {
    const getForecast = await fetch(`${baseUrl}forecast.json?key=${key}&q=${location}&days=5`),
          forecasts = await getForecast.json();

    handleDisplay(forecasts);
  }

  useEffect(() => {
    getForecast('Lagos');
  }, []);

  return (
    <>
      <div className="__weathbg">
        <div className="container">
          <p className="__weatherp">Lagos, Nigeria</p>
          {!retrievedForcast.length ? (<div className="" id="loader">
            <br />
            <center>
              <img src="assets/img/loading.svg" className="__weathimg" style={{ width: 50 }} />
              <p className="__weatherp" style={{ fontSize: 15 }}>Loading weather forecast</p>
            </center>
          </div>) : null}
          <div className="customer-logos slider row" id="weatherForecast">
            {retrievedForcast.length ? displayFutureForecast(retrievedForcast) : null}
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <span className="cust-arrow cust-next">
                <img src="assets/img/weather-left-arrow.png" alt="" />
              </span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <span className="cust-arrow1 cust-prev">
                <img src="assets/img/weather-right-arrow.png" alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WeatherSlider;