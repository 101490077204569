import {
  SET_QUESTION,
  SET_ALL_QUESTIONS,
  ADD_QUESTION
} from '../../actiontypes';

export const setAllQuestions = (payload) => ({
  type: SET_ALL_QUESTIONS,
  payload
});

export const addQuestion = (payload) => ({
  type: ADD_QUESTION,
  payload
});

export const setQuestion = (payload) => ({
  type: SET_QUESTION,
  payload
});