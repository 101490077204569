import React from 'react';
import { useSelector } from "react-redux";
import { Player } from 'video-react';
import { saveAs } from 'file-saver';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './InteractiveAnimation.css';
// import Header from '../../common/components/Header';
import Footer from '../../common/components/Footer';
import Nav from '../../common/components/Nav';

import { errorHandler } from "../../utils/helpers/errorHandler";

const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;

const InteractiveAnimationPage = () => {

  const publicInteractiveAnalysisToVisualize = useSelector(
    state => state.interactiveAnalysis.public_interactive_analysis_to_be_visualized
  );


  const handleFileDownload = async (event, interactiveAnalysisData) => {
		event.preventDefault();
		try {
			let files;

			if (interactiveAnalysisData.contentUrl.includes(",")) {
				files = interactiveAnalysisData.contentUrl
				.split(",")
				.map(file => file.substr(file.lastIndexOf('/')+1)).join("+");
			} else {
				files = interactiveAnalysisData.contentUrl.substr(interactiveAnalysisData.contentUrl.lastIndexOf('/')+1)
			}

			const res = await fetch(`${process.env.REACT_APP_BASE_URL}/interactivities/${files}/download/`);

			
			const blob = await res.blob();
			const name = interactiveAnalysisData.title.split(" ").join("-");
			saveAs(blob, `${name}`);
		} catch (error) {
			errorHandler(error, toast);
		}
	}

  return (
    <>
      <header className="__nehead">
        <Nav/>
        {/* <nav className="navbar __navbar" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/">
                <div className="display-flex">
                </div>
              </a>
            </div>

            <Header />
          </div>
        </nav> */}
      </header>

      <section>
        <div className="video-testimonial-block">
                <div className="video">
                    <Player>
                      <source src={publicInteractiveAnalysisToVisualize?.contentUrl.startsWith('/') ? `${rootUrl}/${publicInteractiveAnalysisToVisualize.contentUrl.substr(publicInteractiveAnalysisToVisualize.contentUrl.lastIndexOf('/')+1)}`: `${publicInteractiveAnalysisToVisualize?.contentUrl}`} />
                    </Player>
                </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-1 col-md-1 col-sm-1"></div>
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <div className="__assdiv text-right">
                    <a
                      href=""
                      className="btn __intbtn1"
                      onClick={event => handleFileDownload(event, publicInteractiveAnalysisToVisualize)}
                    >Download</a>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1"></div>
              </div>
            </div>
        </section>
        <ToastContainer
          closeOnClick
          pauseOnHover
        ></ToastContainer>
        <Footer />
    </>
  )
}

export default InteractiveAnimationPage;