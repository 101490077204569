import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const createPage = async (page, token) => {
  try {
    const { data } = await axios(`${baseUrl}/resources/pages`,{
      method: 'POST',
      data: page,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllPage = async (token, page = 0) => {
  try {
    const { data: { data } } = await axios(`${baseUrl}/resources/pages?page=${page}`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchPage = async (slug) => {
  try {
    const { data: { data } } = await axios(`${baseUrl}/resources/pages/${slug}`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const updatePage = async (slug, page, token) => {
  try {
    const { data } = await axios(`${baseUrl}/resources/pages/${slug}`,{
      method: 'PUT',
      data: page,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchPublicPages = async (page) => {
  try {
    const { data } = await axios(`${baseUrl}/resources/public/pages?page=${page}`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });

    return data;

  } catch (error) {
    throw error.response.data;
  }
}

export const deletePage = async (slug, token) => {
  try {
     const { data } = await axios(`${baseUrl}/resources/pages/${slug}`,{
      method: 'DELETE',
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data;

  } catch (error) {
    throw error.response.data;
  }
}
