import React from 'react';
// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from  '../../common/components/Footer';

const FAQPage = () => {
  return (
    <>
      <header>
      <Nav/>
      </header>
      <section>
      <div className='container'>
			  <div className='__all-banner' style={{background: `url(/assets/img/pagesbanner.png)`}}>
            <p className="__caption-banner text-center">Frequently Asked Questions</p>
            </div>
            <div className="__spacet3p"></div>
        </div>
        <div className="__abg">
          <div className="container">
          <div class="accordion __accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                What is eko360 portal?
              </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>The eko360 web portal is the Lagos Bureau of Statistics web application. It provides information on Lagos State. A user can search and retrieve available data in multiple formats that show insights, analytics, trends, etc. A user can also upload data to the web portal.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                Who is eligible to use eko360 portal?
              </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>The portal is open to the public, and anyone can access available data.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                Do I need to register or sign up to use this portal?
              </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>
                  No, the portal is open to the general use of the public.</p>
                </div>
              </div>
            </div>
            
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFour">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
              What type of data is available on this portal?
              </button>
              </h2>
              <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>A user can find data on economic affairs, health, education, and other statistical data relating to Lagos State.</p>
                </div>
              </div>
            </div>
            
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFive">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
              Can I download files from the web portal?
              </button>
              </h2>
              <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>Yes. You can download data files from the website portal available to the general public.</p>
                </div>
              </div>
            </div>
            
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingSix">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
              What format is the data on the website available to download?
              </button>
              </h2>
              <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>You can download available data in different forms that may be available on the portal, such as MS Excel (.xlsx), PDF, JPEG, and MS Word (.docx) formats.</p>
                </div>
              </div>
            </div>
            
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingSeven">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
              How safe is it to download files from the eko360 web portal?
              </button>
              </h2>
              <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>The eko360 web portal uses the highest level of web security. Files available on the portal are safe and secure.</p>
                </div>
              </div>
            </div>
            
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingEight">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
              May I redistribute the reports after I have downloaded them?
              </button>
              </h2>
              <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>Yes, you are free to use information from the portal, provided that the source is acknowledged.
                  Utilization for commercial purposes requires compliance with the terms of use on all data downloaded on this platform.</p>
                </div>
              </div>
            </div>
            
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingNine">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
              Do I have to pay to obtain statistical information?​ 
              </button>
              </h2>
              <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>No. Obtaining statistical data on the website is entirely free of charge. You can email the Lagos Bureau of Statistics team concerning any special data request.</p>
                </div>
              </div>
            </div>
            
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTen">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
              How often is statistical information/data updated?​ 
              </button>
              </h2>
              <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>There are regular updates to the information on the portal. Statistical data gets automatically updated through a defined in-house procedure, with updates to each indicator after the quality assurance and control process.</p>
                </div>
              </div>
            </div>
            
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingEleven">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
              Can I use the data published on this website for a report or publication?
              </button>
              </h2>
              <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>Yes. Y ou can use data for reporting or publishing, but it is strictly prohibited to modify any figures.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwelve">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
              How are suggestions treated?​
              </button>
              </h2>
              <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>We consider suggestions to improve the quality of our data portal services. Hence, all suggestions are welcome, and we shall try to use them to enhance the service it addresses.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThirteen">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
              How do I give suggestions/comments?
              </button>
              </h2>
              <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>There is a <strong>'Contact'</strong> tab on the web portal, where users can drop their suggestions/comments.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFourteen">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
              How do I download data?
              </button>
              </h2>
              <div id="flush-collapseFourteen" class="accordion-collapse collapse" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>Search for the data you want to download, click on the download tab, and the data will get downloaded.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFifteen">
              <button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
              How do I create a table builder (Slice and Dice)? 
              </button>
              </h2>
              <div id="flush-collapseFifteen" class="accordion-collapse collapse" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <p className='__polcy'>Navigate to interactivity on the home page (<strong>Home &gt; Explore &gt; Slice and Dice</strong>). Click on Slice and Dice. An interactive page will be displayed to click on any preferred indicators for your report. Using the print button, you can download the report either as a pdf or .xlsx file.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="__spacet5p"></div>
      </section>
      <Footer />
    </>
  )
}
export default FAQPage;