import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const uploadQuestionLogic = async (questionLogic, token) => {
  try {
    const { data } = await axios(`${baseUrl}/questions`,{
      method: 'POST',
      data: questionLogic,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllQuestion = async (page = 0) => {
  try {
    const {data: {data} } = await axios({
      method: 'GET',
      url: `${baseUrl}/questions?page=${page}`,
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const deleteQuestion = async (token, uuid) => {
  try {
    const {data } = await axios({
      method: 'DELETE',
      url: `${baseUrl}/questions/${uuid}`,
      headers: {'Content-Type': 'application/json', 'Authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const searchQuestions = async (question) => {
  try {
    const {data: {data} } = await axios({
      method: 'GET',
      url: `${baseUrl}/questions/search?question=${question}`,
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchQuestion = async (uuid) => {
  try {
    const {data: {data} } = await axios({
      method: 'GET',
      url: `${baseUrl}/questions/${uuid}`,
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
} 

export const updateQuestion = async (question, uuid, token) => {
  try {
    const {data} = await axios({
      method: 'PUT',
      data: question,
      url: `${baseUrl}/questions/${uuid}`,
      headers: {'Content-Type': 'application/json', 'Authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}