import {
  SET_QUESTION,
  SET_ALL_QUESTIONS,
  ADD_QUESTION
} from '../../actions/actiontypes';

const initialState = {
  selected_question: null,
  questions: []
}

const questionReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_ALL_QUESTIONS:
      return {
        ...state,
        questions: action.payload
      }
    case ADD_QUESTION: 
      return {
        ...state,
        questions: [action.payload,...state.questions]
      }

    case SET_QUESTION: 
    return {
      ...state,
      selected_question: action.payload
    }

    default: return state;
  }
}

export default questionReducer;