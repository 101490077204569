import { 
  SET_SELECTED_DATASET, 
  SET_DATASET, ADD_DATASET, 
  UPDATE_DATASET_STATUS,
  UPDATE_DATASET
} from '../../actions/actiontypes';

const initialState = {
  selected_dataset: null,
  dataset: []
}

const datasetReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_SELECTED_DATASET: 
    return {
      ...state,
      selected_dataset: action.payload
    }

    case SET_DATASET: 
    return {
      ...state,
      dataset: action.payload
    }
    
    case ADD_DATASET: {
      const update = [action.payload,...state.dataset];
      return {
        ...state,
        dataset: update,
      }
    }
    case UPDATE_DATASET_STATUS: {
      const index = state.dataset.findIndex(data => data.uuid === state.selected_dataset.uuid);
      const updateSelectedDataset = {...state.selected_dataset, status: action.payload.status};
      state.dataset.splice(index, 1, updateSelectedDataset);
      return {
        ...state,
        dataset: state.dataset,
      }
    }
    case UPDATE_DATASET: {
      const index = state.dataset.findIndex(data => data.uuid === action.payload.uuid);
      const updateSelectedDataset = action.payload;
      state.dataset.splice(index, 1, updateSelectedDataset);
      return {
        ...state,
        dataset: state.dataset,
        selected_dataset: action.payload,
      }
    }
    default: return state;
  }
}

export default datasetReducer;