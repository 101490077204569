import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const uploadDataset = async (dataset, token) => {
  console.log(dataset)
  try {
    const { data } = await axios(`${baseUrl}/datasets`,{
      method: 'POST',
      data: dataset,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllDataset = async (token, page = 0) => {
  try {
    const {data: {data} } = await axios({
      method: 'GET',
      url: `${baseUrl}/datasets?page=${page}`,
      headers: {'Content-Type': 'application/json', 'Authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllPublicDataset = async (page = 0) => {
  try {
    const {data: {data} } = await axios({
      method: 'GET',
      url: `${baseUrl}/datasets/public?page=${page}`,
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const acceptDataset = async ({datasetUUID, comment = undefined, status, token}) => {
  try {
    const {data: {data} } = await axios({
      method: 'PUT',
      url: `${baseUrl}/datasets/admin/${datasetUUID}`,
      data: {comment, status },
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const searchDataset = async (searchKeys, page = 0) => {
  console.log(searchKeys)
  try {
    const {data: { data }} = await axios({
      method: 'GET',
      url: `${baseUrl}/datasets/search?${searchKeys.join('&')}&page=${page}`,
      headers: {'Content-Type': 'application/json'}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const findDataset = async (uuid, token) => {
  try {
    const {data: { data }} = await axios({
      method: 'GET',
      url: `${baseUrl}/datasets/${uuid}`,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const deleteDataset = async (uuid, token) => {
  try {
    const {data} = await axios({
      method: 'DELETE',
      url: `${baseUrl}/datasets/${uuid}`,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}` }
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const updateDataset = async (dataset,uuid, token) => {
  try {
    const { data } = await axios(`${baseUrl}/datasets/${uuid}`,{
      method: 'PUT',
      data: dataset,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}
