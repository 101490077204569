import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const uploadPublication = async (publication, token) => {
  try {
    const { data } = await axios(`${baseUrl}/publications`,{
      method: 'POST',
      data: publication,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllPublications = async (token, page = 0) => {
  try {
    const {data: {data} } = await axios({
      method: 'GET',
      url: `${baseUrl}/publications?page=${page}`,
      headers: {'Content-Type': 'application/json', 'Authorization': `${token}`}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const acceptPublication = async ({ uuid, comment = undefined, status, token}) => {
  try {
    const {data} = await axios({
      method: 'PUT',
      url: `${baseUrl}/publications/admin/${uuid}`,
      data: {comment, status },
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const updatePublication = async (publication, uuid, token) => {
  try {
    const { data } = await axios(`${baseUrl}/publications/${uuid}`,{
      method: 'PUT',
      data: publication,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data
  } catch (error) {
    throw error.response.data;
  }
}

export const deletePublication = async (uuid, token) => {
  try {
    const { data } = await axios(`${baseUrl}/publications/${uuid}`,{
      method: 'DELETE',
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data
  } catch (error) {
    throw error.response.data;
  }
}

export const findPublication = async (uuid) => {
  try {
    const {data: {data}} = await axios({
      method: "GET",
      url: `${baseUrl}/publications/${uuid}`,
      headers: {'Content-Type': 'application/json' }
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const findPublishedPublication = async (page = 0) => {
  try {
    const {data: {data}} = await axios({
      method: "GET",
      url: `${baseUrl}/publications/public?page=${page}`,
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}