import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { saveAs } from 'file-saver';

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SideMenu from '../../common/SideMenu';
// import UserAvatar from '../../common/UserAvatar.jsx';
import DashboardNav from '../component/DashboardNav';

import { acceptDataset, findDataset, deleteDataset, updateDataset } from '../../../utils/api/dataset';
import { updateDatasetStatus, setSelectedDataSet,  updateDataset as updateDatasetInStore} from '../../../store/actions/actions/dataset';
import UploadComponent from '../../common/Upload';
import { errorHandler } from '../../../utils/helpers/errorHandler';


const OverViewPage = () => {
  const dataset = useSelector(state => state.dataset.selected_dataset);
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));

  const history = useHistory();
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const [datasetStatus, setDatasetStatus] = useState('Pending');
  const [datasetName, setDatasetName] = useState('');
  const [showPublishLoader, setShowPublishLoader] = useState(false);
  const [showDeclineLoader, setShowDeclineLoader] = useState(false);
  const [comment, setComment] = useState(undefined);
  const [showDeletingDataset, setShowDeletingDataset] = useState(false);

  const [descriptionValue, setDescriptionValue] = useState(''),
        [datasetType, setDatasetType] = useState("file"),
        [title, setTitle] = useState(""),
        [consent, setConsent] = useState(true),
        [email, setEmail] = useState(""),
        [file, setFile] = useState(null),
        [category, setCategory] = useState('')

  const [datasetFileType, setDatasetFileType] = useState(undefined);
  const [datasetFile, setDatasetFile] = useState(undefined);
  const [datasetComment, setDatasetComment] = useState(undefined);
  const [showUpdateLoader, setShowUpdateLoader] = useState(false);

  const handleDeleteDataset = async (props) => {
    setShowDeletingDataset(true);
    try {
      const data = await deleteDataset(uuid, token);

      setTimeout(() => {
        toast.success(`${data.message}`);
      }, 300);
      document.querySelector(".del-dataset-modal").click()
      setShowDeletingDataset(false);
      history.goBack();
    } catch (error) {
      setShowDeletingDataset(false);
      errorHandler(error, toast);
    }
  }

  const fetchDataset = async () => {
    try {
      const data = await findDataset(uuid, token);

      dispatch(setSelectedDataSet(data));
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  useEffect(() => {
    fetchDataset();
  }, []);

  useEffect(() => {
    window.$('#openSidebar').click(function(){
      window.$('.leftbar').addClass('biger');
      });
      window.$('#closebtn').click(function(){
          window.$('.leftbar').removeClass('biger');
      });
  },[])

  useEffect(() => {
    if (!dataset) {
      fetchDataset();
    } else {
      console.log()
      setDescriptionValue(dataset.description);
      setDatasetName(dataset.name);
      setDatasetType(dataset.type);
      setDatasetFile(dataset.file);
      setDatasetStatus(dataset.status);
      setDatasetComment(dataset.comment);
      setTitle(dataset.title);
      setEmail(dataset.email);
      setCategory(dataset.category);

      // console.log("WHAT DO WE HAVE IN CATEGORY ------>", dataset);
    }
  }, [dataset]);

  const gotoHome = () => {
    history.push('/admin/dashboard');
  }

  const acceptOrDeclineDataset = async (status) => {
    try {
      await acceptDataset({ datasetUUID: dataset.uuid, comment, status, token });
      setShowPublishLoader(false);
      setShowDeclineLoader(false);

      setDatasetStatus(status);
      dispatch(updateDatasetStatus(status));

      setTimeout(() => {
        toast.success(`Dataset was ${status} successfully`);
      }, 500);

      history.goBack();
    } catch (error) {
      setShowPublishLoader(false);
      setShowDeclineLoader(false);
      const { message } = error;
      if (Array.isArray(message) && Object.keys(message).length > 0) {
        const nextedErrorMsg = message[0][Object.keys(message[0])];
        return toast.error(`${nextedErrorMsg}`);
      }
      return toast.error(`${error.error.message}`);
    }
  }

  const handleAcceptDataset = async (event) => {
    event.preventDefault();
    setShowPublishLoader(true);
    await acceptOrDeclineDataset("published");
  }

  const handleDeclineDataset = async (event) => {
    event.preventDefault();
    setShowDeclineLoader(true);
    await acceptOrDeclineDataset("declined");
  }

  const handleFileDownload = async () => {
    try {
      const fileName = dataset.file.substr(dataset.file.lastIndexOf('/') + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/datasets/${fileName}/download/`,
        {
          headers: {
            'Content-Type': 'application/json', Authorization: `${token}`
          }
        });

      const blob = await res.blob()
      saveAs(blob, fileName);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  }

  const handleSubmitDataset = async () => {
    setShowUpdateLoader(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', datasetName);
      formData.append('description', descriptionValue);
      formData.append('title', title);
      formData.append('email', email);
      formData.append('consent', consent);
      formData.append('type', datasetType);
      formData.append('category', category);

      const dataset = await updateDataset(formData, uuid, token);

      dispatch(updateDatasetInStore(dataset.data));
      setShowUpdateLoader(false);
      document.querySelector(".dismiss-update-modal").click();
      toast.success(`${dataset.message}`);
    } catch (error) {
      setShowUpdateLoader(false);
      errorHandler(error, toast);
    }
  }

  const resetDatasetForm = () => {
    setFile(null);
    setDatasetName('');
    setDescriptionValue('');
    setTitle('');
    setEmail('');
    setConsent(false);
    setDatasetType('file');
  }

  const resetModalForm = () => {
    setTimeout(() => {
      window.$('.hidden1').hide();
      window.$('.__hidden2').show();
      window.$('.show1').show();
      window.$('.hidden2').hide();
      window.$('.show4').show();
    }, 300)
  }

  const titles = "Overview";

  return (
    <>
      <div className="section body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              <SideMenu />
            </div>

            <div className="col-md-10">

              <DashboardNav  
                page={titles}
              />

              <section>
                <div className="__house">
                  <div className="__btcard">
                    <div className="__spacet3p"></div>
                    <div className="__btcardin1">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <p className="__subp">Submission Date: {moment(dataset?.createdAt).format('LLLL')}<br /> <span className="__subpen">{datasetStatus === 'draft' ? 'Pending' : datasetStatus}</span></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3"></div>
                        <div className="col-lg-3 col-md-3 col-sm-3">
                          <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                              <p className="__viewown">Owner</p>
                              <p className="__viewown1">{dataset?.email}</p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                              <img src="/assets/img/clock.jpg" className="__viewimg" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <form>
                        <div className="row __flexsmail">
                          <div className="col-lg-6 col-md-6 col-sm-6 __mobidown">
                            <div className="form-group">
                              <label htmlFor="" className="__modlab">Dataset Name</label>
                              <p>{dataset?.name}</p>
                            </div>

                            <div className="form-group">
                              <label htmlFor="" className="__modlab">Description</label>
                              <p>{dataset?.description}</p>
                            </div>
                            {datasetComment && datasetStatus !== "published" ? (
                              <div className="form-group">
                                <label htmlFor="" className="__modlab">Comment</label>
                                <textarea className="form-control __regdivform" rows="5" value={datasetComment} disabled></textarea>
                              </div>
                            ) : null}

                            {!datasetComment || datasetStatus === "published" ? (
                              <div className="form-group">
                                <label htmlFor="" className="__modlab">Comment</label>
                                <textarea className="form-control __regdivform" rows="5" value={comment} onChange={event => setComment(event.target.value)}></textarea>
                              </div>
                            ) : null}

                            <div className="__spacet7p"></div>
                            <div className="form-group" style={{ height: '45px' }}></div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 __mobiup">
                            <div className="__spacet7p"></div>
                            <div className=" __it">
                              <div className="uploadDoc">
                                <div className="fileUpload btn btn-orange">
                                  {dataset?.type === 'link' &&
                                    <>
                                      <img src="/assets/img/link-icon.svg" className="icon" alt="" />
                                      <br /><br />
                                      <span style={{ color: "#07A0AB" }}>
                                        <a href={dataset?.file} target="_blank">Click to visit submitted address</a></span>
                                    </>
                                  }
                                  {dataset?.type === 'file' &&
                                    <>
                                      <img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" alt="" /><br /><br />
                                      <span style={{ color: "#07A0AB" }} onClick={handleFileDownload}>Click to download file</span>
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <a href="" className="btn __profbtn" onClick={handleAcceptDataset}>Accept & publish {showPublishLoader && <img src="/assets/img/loader.gif" alt="" />}</a>
                            &nbsp;&nbsp;
                            <a href="" className="btn __dnbtn" onClick={handleDeclineDataset}>Deny {showDeclineLoader && <img src="/assets/img/loader-dark-theme.gif" alt="" />}</a>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="text-end">
                              <a
                                data-toggle="modal"
                                className='btn __dnbtn1'
                                data-bs-toggle="modal" data-bs-target="#update-dataset-modal"
                                
                              >Edit</a>
															&nbsp;&nbsp;
															<a
                                className='btn __dnbtnp'
                                data-bs-toggle="modal" data-bs-target="#modal-deleteDataset"
                              >
                                Delete
															</a>
                            </div>
                          </div>

                        </div>
                      </form>
                      <div className="__spacet5p"></div>
                    </div>
                  </div>

                  <div className="__spacet7p"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>



        <div class="modal fade" id="modal-deleteDataset" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog __modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="btn-close  __close del-dataset-modal" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="row __flexsmaila">
                            <div className="col-lg-12 col-md-12 col-sm-12 __mobidown" style={{ paddingTop: "1.5rem" }}>
                                <p>Are you sure you want to delete this Page - <strong>{dataset?.name}</strong>?</p>
                                <form style={{ marginTop: "1.5rem", display: "flex", justifyContent: "center" }}>
                                    <a
                                    className="btn __autbtn"
                                    onClick={handleDeleteDataset}
                                    style={{ width: "120px", marginRight: "15px", marginTop: "2rem" }}
                                    >
                                    Delete
                                    {showDeletingDataset && <img src="/assets/img/loader.gif" alt="" />}
                                    </a>
                                    <a
                                    className="btn __dnbtn1"
                                    style={{
                                        width: "120px",
                                        backgroundColor: "#fff",
                                        border: "1px solid #0C9EA9",
                                    }}
                                    data-bs-dismiss="modal"
                                    >
                                    Cancel
                                    </a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <div className="modal fade" id="update-dataset-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog __modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                  <button type="button" class="btn-close __close dismiss-update-modal" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetModalForm()}></button>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                      <p className="__modseps">Step
                        <span className="__hidden2">1</span>
                        <span className="hidden1">2</span>
                        <span className="hidden2">3</span>
                      of 3</p>
                      <p className="__modseps1">Dataset Details </p>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
                      <div className="text-center __hidden2">
                        <span className="__kangproga"></span>
                        <span className="__kangprog"></span>
                        <span className="__kangprog"></span>
                      </div>

                      <div className="text-center hidden1">
                        <span className="__kangprog"></span>
                        <span className="__kangproga"></span>
                        <span className="__kangprog"></span>
                      </div>

                      <div className="text-center hidden2">
                        <span className="__kangprog"></span>
                        <span className="__kangprog"></span>
                        <span className="__kangproga"></span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4"></div>
                  </div>
                  <div className="__spacet7p"></div>
                  <div className="row __flexsmail">
                    <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                      <form encType="multipart/form-data">
                        <div className="__hidden2">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">Dataset Name</label>
                            <input type="text" className="form-control __regdivform" placeholder="E.g. Child Care" value={datasetName} onChange={event => setDatasetName(event.target.value)} />
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="" className="__modlab">Type</label>
                                <select
                                  className="form-control __regdivform"
                                  onChange={(event) => {
                                    setFile("");
                                    setDatasetType(event.target.value);
                                  }}>
                                  <option value="file" selected={dataset?.type === "file"}>File</option>
                                  <option value="link" selected={dataset?.type === "link"}>Link</option>
                                </select>
                              </div>
                            </div>

                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">Description</label>
                            <textarea className="form-control __regdivform" rows="5" value={descriptionValue} onChange={event => setDescriptionValue(event.target.value)}></textarea>
                          </div>
                          <div className="__spacet7p"></div>
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              <a className="btn btn-block __autbtn showButton show1">Proceed</a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                          </div>
                        </div>
                        <div className="hidden1">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <input type="text" className="form-control __regdivform" placeholder="Title" value={title} onChange={(event) => setTitle(event.target.value)} />
                              </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="" className="__modlab">Category</label>
                                <select
                                  className="form-control __regdivform"
                                  onChange={(event) => {
                                    setCategory(event.target.value);
                                  }}
                                >
                                  <option value="" selected disabled>Please select a category</option>
                                  <option value="education" selected={category === 'education'}>Education</option>
                                  <option value="health" selected={category === 'health'}>Health</option>
                                  <option value="economic affairs" selected={category === 'economic affairs'}>Economic Affairs</option>
                                </select>
                              </div>
                            </div>

                            {datasetType === 'link' && (<div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <input type="text" className="form-control __regdivform" placeholder="Embed Url" value={file} onChange={event => setFile(event.target.value)} />
                              </div>
                            </div>)}
                          </div>

                          <div className="__spacet5p"></div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                              <a className="btn btn-block __autbtn1 hideButton">Back</a>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4"></div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                              <a className="btn btn-block __autbtn showButton1 show2">Continue</a>
                            </div>
                          </div>
                        </div>
                        <div className="hidden2">

                          <div className="form-group">
                            <input type="email" className="form-control __regdivform" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                          </div>
                          <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                id="consent"
                                placeholder="Email"
                                value={consent}
                                onChange={(event) => setConsent(event.target.checked)}
                                style={{ width: "15px", height: "18px" }}
                                checked={consent === true}
                            />
                            <label class="form-check-label __modlab" htmlFor="consent" style={{ marginBottom: 0, marginTop: "5px", marginLeft: "10px" }}>I confirm that all the information that I have provided are genuine and valid.
                            </label>
                            </div>

                          <div className="__spacet5p"></div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                              <a className="btn btn-block __autbtn1 hideButton1 ">Back</a>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4"></div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                              <a className="btn btn-block __autbtn" onClick={handleSubmitDataset}>Update {showUpdateLoader && <img src="/assets/img/loader.gif" alt="" />}</a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                      <div className=" __it">
                        <div className="uploadDoc">
                          <div className="fileUpload btn btn-orange">
                            <img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" alt="" /><br />
                            {datasetType === 'file' && (<span className="__upl">Browse <span>file here</span></span>)}
                            {datasetType !== 'file' && (<span className="__upl"><span>Select "file" as type of dataset to enable</span></span>)}
                            <input type="file" className="upload up" required="required" id="dataset-file" onChange={event => setFile(event.target.files[0])} disabled={datasetType !== 'file'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>

      <UploadComponent />

      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>

    </>
  )
}

export default OverViewPage;