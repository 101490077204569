import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { saveAs } from 'file-saver';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SideMenu from '../../../common/SideMenu';
// import UserAvatar from '../../../common/UserAvatar.jsx';
import DashboardNav from '../../component/DashboardNav';

import Chip from "../../../common/chip";
import UploadComponent from '../../../common/Upload';

import { errorHandler } from "../../../../utils/helpers/errorHandler";

import {
  fetchQuestion,
  deleteQuestion,
  updateQuestion
} from "../../../../utils/api/questionLogic";

const QuestionOverView = () => {
  const dataset = useSelector(state => state.dataset.selected_dataset);
  const question = useSelector(state => state.question.selected_question);
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));

  const history = useHistory();
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const [showPublishLoader, setShowPublishLoader] = useState(false);
  const [showDeclineLoader, setShowDeclineLoader] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);
  const [showDeleteQuestionLoader, setShowDeleteQuestionLoader] = useState(false);

  const [keyword, setKeyword] = useState([]);
  const [questionToEdit, setQuestion] = useState('');
  const [questionDescription, setQuestionDestription] = useState('');
  const [questionFinding, setQuestionFinding] = useState('');
  const [questionType, setQuestionType] = useState('file');
  const [analytics, setAnalytics] = useState('');
  const [showQuestionLoader, setShowQuestionLoader] = useState(false);

  const handleFetchQuestion = async () => {
    try {
      const data = await fetchQuestion(uuid);

      setSelectedQuestion(data);
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  const handleDeleteQuestion = async () => {
    setShowDeleteQuestionLoader(true);
    try {
      const data = await deleteQuestion(token, selectedQuestion.uuid);

      setTimeout(() => {
        toast.success(`${data.message}`);
      }, 300);

      document.querySelector(".del-question-modal").click();
      history.push('/admin/dashboard/question-logic');
    } catch (error) {
      setShowDeleteQuestionLoader(false);
      errorHandler(error, toast);
    }
  }

  const handleUpdateQuestion = async (payload) => {
    try {
      const data = await updateQuestion(payload, selectedQuestion.uuid, token);

      toast.success(`${data.message}`);
      setSelectedQuestion(data.data);
      setShowQuestionLoader(false);
      document.querySelector('.question-modal').click();
    } catch (error) {
      setShowQuestionLoader(false);
      errorHandler(error, toast);
    }
  }

  const handlePublisQuestion = (event, value) => {
    event.preventDefault();
    handleUpdateQuestion({ status: value });
  }

  useEffect(() => {
    if (!question) {
      handleFetchQuestion();
    } else {
      setSelectedQuestion(question);
    }
  }, [question]);

  useEffect(() => {
    if (selectedQuestion) {
      setKeyword(selectedQuestion?.keywords.split(','));
      setQuestion(selectedQuestion?.question);
      setQuestionDestription(selectedQuestion?.description);
      setQuestionFinding(selectedQuestion?.findings);
      setQuestionType(selectedQuestion?.type);
      setAnalytics(selectedQuestion?.analytics);
    }
  }, [selectedQuestion]);

   const handleRemoveChip = (_,index) => {
    let keywordToUpdate = [...keyword];
    keywordToUpdate.splice(index, 1);
    setKeyword(keywordToUpdate);
  }

  const handleAddKeyword = (event) => {
    event.preventDefault();
    if(event.key === "Enter") {
      setKeyword([...keyword, event.target.value]);
      document.querySelector('.keyword').value = '';
    }
  }

  const handleUpdate = async () => {
    setShowQuestionLoader(true);
    const formData = new FormData();
      
    formData.append('question', questionToEdit);
    formData.append('description', questionDescription);
    formData.append('keywords', keyword.join(','));
    formData.append('analytics', analytics);
    formData.append('findings', questionFinding);
    formData.append('type', questionType);

    handleUpdateQuestion(formData);
  }

  const resetQuestionForm = () => {
    setKeyword([]);
    setQuestion('');
    setQuestionDestription('');
    setQuestionFinding('');
    setQuestionType('file');
    setAnalytics('');
    setShowQuestionLoader(false);
  }

  const gotoHome = () => {
    history.push('/admin/dashboard/question-logic');
  }

  const handleFileDownload = async () => {
    try {
      if(!dataset?.file) return toast.error("This page has no uploaded file");
      const fileName = dataset.file.substr(dataset.file.lastIndexOf('/') + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/datasets/${fileName}/download/`,
        {
          headers: {
            'Content-Type': 'application/json', Authorization: `${token}`
          }
        });

      const blob = await res.blob()
      saveAs(blob, fileName);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  }

  const resetModalForm = () => {
    setTimeout(() => {
      window.$('.hidden1').hide();
      window.$('.__hidden2').show();
      window.$('.show1').show();
      window.$('.hidden2').hide();
      window.$('.show4').show();
    }, 300)
  }

  const title = "Overview"

  return (
    <>
      <div className="section body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              <SideMenu />
            </div>

            <div className="col-md-10">
                <DashboardNav 
                  page={title}
                />
              

              <section>
                <div className="__house">
                  <div className="__btcard">
                    <div className="__spacet3p"></div>
                    <div className="__btcardin1">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <p
                            className="__subp"
                          >
                            Submission Date: {moment(selectedQuestion?.createdAt).format('LLLL')}<br />
                            <span className="__subpen">
                              {selectedQuestion?.status === 'draft' ? 'draft' : `${selectedQuestion?.status}` }
                            </span>
                          </p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3"></div>
                      </div>
                      <hr />
                      <form>
                        <div className="row __flexsmail">
                          <div className="col-lg-6 col-md-6 col-sm-6 __mobidown">
                            <div className="form-group">
                              <label htmlFor="" className="__modlab">Dataset Name</label>
                              <p>{selectedQuestion?.question}</p>
                            </div>

                            <div className="form-group">
                              <label htmlFor="" className="__modlab">Description</label>
                              <p>{selectedQuestion?.description}</p>
                            </div>

                            <div className="form-group">
                              <label htmlFor="" className="__modlab">Keywords</label>
                                <p>{selectedQuestion && selectedQuestion.keywords.split(',').map((keyword, index) => (
                                  <>
                                    {keyword.length > 0 && <Chip label={keyword} key={index} />}
                                  </>
                                )) }</p>
                                {!selectedQuestion?.keywords[0] && <p className="text small text-muted">There are no keywords.</p>}
                            </div>

                            <div className="__spacet7p"></div>
                            <div className="form-group" style={{ height: '45px' }}></div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 __mobiup">
                            <div className="__spacet7p"></div>
                            <div className=" __it">
                              <div className="uploadDoc">
                                <div className="fileUpload btn btn-orange">
                                  {selectedQuestion?.type === 'iframe' &&
                                    <>
                                      <img src="/assets/img/link-icon.svg" className="icon" alt="" />
                                      <br /><br />
                                      <span style={{ color: "#07A0AB" }}>
                                        <a href={selectedQuestion?.analytics} target="_blank">Click to visit submitted address</a>
                                      </span>
                                    </>
                                  }
                                  {selectedQuestion?.type === 'file' &&
                                    <>
                                      <img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" alt="" /><br /><br />
                                      <span style={{ color: "#07A0AB" }} onClick={handleFileDownload}>Click to download file</span>
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <a href="" className="btn __profbtn" onClick={(event) => handlePublisQuestion(event, 'published')}>Publish {showPublishLoader && <img src="/assets/img/loader.gif" alt="" />}</a>
                            &nbsp;&nbsp;
                            <a href="" className="btn __dnbtn" onClick={(event) => handlePublisQuestion(event, 'draft')}>Unpublish {showDeclineLoader && <img src="/assets/img/loader-dark-theme.gif" alt="" />}</a>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6" >
                            <div className="text-end">
															<a
																 data-bs-toggle="modal" data-bs-target="#modal-quesId"
																className='btn __dnbtn1'
															>Edit</a>
															&nbsp;&nbsp;
															<a
																className='btn __dnbtnp'
                                data-bs-toggle="modal" data-bs-target="#modal-deletequestion"
                                
															>
																Delete
															</a>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="__spacet5p"></div>
                    </div>
                  </div>

                  <div className="__spacet7p"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-quesId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close __close del-page-modal" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetModalForm()}></button>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                  <p className="__modseps">Step 
                    <span className="__hidden2">1</span>
                    <span className="hidden1">2</span>
                  of 2</p>
                  <p className="__modseps1">Question Logic</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-7">
                  <div className="text-center __hidden2">
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                            </div>

                            <div className="text-center hidden1">
                              <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                    <span className="__kangprog"></span>
                            </div>

                            <div className="text-center hidden2">
                              <span className="__kangprog"></span>
                    <span className="__kangprog"></span>
                    <span className="__kangproga"></span>
                            </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4"></div>
              </div>
              <div className="__spacet7p"></div>
              <div className="row __flexsmail">
                <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
                      <form encType="multipart/form-data">
                        <div className="__hidden2">
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">Question</label>
                            <input
                              type="text"
                              className="form-control __regdivform"
                              placeholder="E.g. How many buses are owned by Lagos state Govt."
                              value={questionToEdit}
                              onChange={event => setQuestion(event.target.value)}
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="" className="__modlab">Type</label>
                                <select
                                  className="form-control __regdivform"
                                  onChange={(event) => {
                                    setQuestionType(event.target.value);
                                    setAnalytics('');
                                  }}
                                >
                                  <option value="file">File</option>
                                  <option value="iframe">Link</option>
                                </select>
                              </div>
                            </div>

                          </div>
                          <div className="form-group">
                            <label htmlFor="" className="__modlab">Description</label>
                            <textarea 
                              className="form-control __regdivform"
                              rows="5"
                              value={questionDescription}
                              onChange={event => setQuestionDestription(event.target.value)}
                            ></textarea>
                          </div>
                          <div className="__spacet7p"></div>
                            <div className="row">
                              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <a className="btn btn-block __autbtn showButton show1">Proceed</a>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                            </div>
                          </div>

                          <div className="hidden1">

                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control __regdivform"
                          placeholder="Findings"
                          rows="5"
                          value={questionFinding}
                          onChange={(event) => setQuestionFinding(event.target.value)}
                        ></textarea>
                      </div>
                     { questionType === 'iframe' && ( <div className="form-group">
                        <input
                          type="text"
                          className="form-control __regdivform"
                          placeholder="Iframe"
                          value={analytics}
                          onChange={(event) => setAnalytics(event.target.value)}
                        />
                      </div>)}
                      <div className="form-group">
                        <label htmlFor="" className="__modlab">Keywords</label>
                        <div
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column"
                          }}>
                            <div>
                              {keyword.length ? keyword.map((label, index) => (
                                <Chip
                                  label={label}
                                  removeChip={_ => handleRemoveChip(_,index)}
                                  key={index}
                                />
                              )): null}
                              
                            </div>
                          <input
                            type="text"
                            className="form-control1 __regdivform1 keyword"
                            placeholder="Add keyword"
                            style={{
                              marginTop: "8px",
                              outline: "none",
                              width: "50%",
                              borderRight: "none",
                              borderTop: "none",
                              borderLeft: "none",
                              borderBottom: "1px solid #00000033",
                            }}
                            onKeyUp={handleAddKeyword}
                          />
                        </div>
                      </div>
                      <div className="__spacet5p"></div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                              <a className="btn btn-block __autbtn1 hideButton">Back</a>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4"></div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <a
                                  className="btn btn-block __autbtn"
                                  onClick={handleUpdate}
                                >
                                  Finish { showQuestionLoader && <img src="/assets/img/loader.gif" alt="" /> }
                                </a>
                            </div>
                        </div>
                      </div>
                  </form>
                </div>
                    <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
                      <div className=" __it">
                        <div className="uploadDoc">
                          <div className="fileUpload btn btn-orange">
                              <img src="https://image.flaticon.com/icons/svg/136/136549.svg" className="icon" alt="" /><br />
                              {questionType === 'file' && (<span className="__upl">Browse <span>file here</span></span>)}
                              {questionType !== 'file' && (<span className="__upl"><span>Select "file" as type of Analytics to enable</span></span>)}
                              <input type="file" className="upload up" required="required" id="dataset-file" onChange={event => setAnalytics(event.target.files[0])} disabled={questionType !== 'file'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-deletequestion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog __modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close __close del-page-modal" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="row __flexsmaila">
                <div className="col-lg-12 col-md-12 col-sm-12 __mobidown" style={{ paddingTop: "1.5rem" }}>
                  <p>Are you sure you want to delete this Question - <strong>{selectedQuestion?.question}</strong>?</p>
                  <form style={{ marginTop: "1.5rem", display: "flex", justifyContent: "center" }}>
                    <a
                      className="btn __autbtn"
                      onClick={handleDeleteQuestion}
                      style={{ width: "120px", marginRight: "15px", marginTop: "2rem" }}
                    >
                      Delete
                      { showDeleteQuestionLoader && <img src="/assets/img/loader.gif" alt="" /> }
                    </a>
                    <a
                      className="btn __dnbtn1"
                      style={{
                        width: "120px",
                        backgroundColor: "#fff",
                        border: "1px solid #0C9EA9",
                      }}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UploadComponent />

      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>

    </>
  )
}

export default QuestionOverView;