import React from 'react'
import {Link} from 'react-router-dom';


const Cookies = () => {
  return (
    <>
        {/* <p className='__aboup'>COOKIES POLICY</p>
		    <div className='__spacet2p'></div> */}

        <p className='__polcy'>This Cookies Policy (Policy) explains how we use cookies and the choices you have. Except as otherwise stated in this Policy, our Privacy Policy will apply to our processing of data that we collect via cookies. You can access our Privacy Policy via this <Link to="legal"><b>link</b></Link></p>

        <p className='__polcy'>By using our website (by clicking, navigating or scrolling), you are consenting to our use of cookies in accordance with this Policy. If you do not agree to our use of cookies, you can prevent the installation of cookies through the settings of your browser or not use our website at all. However, if you disable the use of cookies, this might cause some parts of this website not to function properly for you and it may impact your user experience on this site.</p>

        <p className='__polcybd'>What are Cookies?</p>

        <p className='__polcy'>Cookies are messages or small files that are placed on your web browser when you visit an internet site. Cookies are useful and do a lot of different things, including allowing a website to recognize a user’s device, improving online experience etc.</p>

        <p className='__polcybd'>How and Why do we Use Cookies?</p>
        <p className='__polcy'>We use cookies when you access our websites to make the site work better and to help us understand how you use our site. They are strictly used to monitor pages you find useful and which you do not so that we can provide a better experience for you.</p>
        <p className='__polcy'>Cookies enable us to understand the information we receive about you, including information about your use of other websites and apps. The data we collect by using cookies is used to customize our website to your needs.</p>

        <p className='__polcy'>Cookies help us provide, protect and improve our services, by personalizing, tailoring and measuring the services we provide for satisfactory and safe experience. We also use them to help authentication of user, assess performance and functionality of our services and analytical research.</p>
    </>
  )
}

export default Cookies