import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { ProvideAuth, useAuth } from "./hooks/auth";

import HomePage from "./pages/Home/HomePage.jsx";
// import ExplorePage from './pages/Explore/ExplorePage.jsx';
import InteractivityPage from "./pages/Interactivity/InteractivityPage.jsx";
import PublicationsPage from "./pages/Publications/PublicationsPage.jsx";
import FAQPage from "./pages/FAQs/FAQPage.jsx";
import DataSetsPage from "./pages/DataSetsPage/DataSetsPage";
import TermsOfUsePage from "./pages/TermsOfUse/TermsOfUsePage.jsx";
import ContactPage from "./pages/Contact/ContactPage.jsx";
import AboutPage from "./pages/About/AboutPage.jsx";
import SignInPage from "./pages/SignIn/SignInPage.jsx";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage.jsx";
import ResetPasswordPage from "./pages/ResetPassword/ResetPasswordPage.jsx";
import RegisterPage from "./pages/Register/RegisterPage.jsx";
import InteractiveSlidePage from "./pages/Interactive-slide/InteractiveSlidePage.jsx";
import InteractiveAnimationPage from "./pages/Interactive-animation/InteractiveAnimationPage.jsx";
import ExploreDataPage from "./pages/Explore-data/ExploreDataPage.jsx";
import SearchDatasetPage from "./pages/Search-dataset/SearchDatasetPage.jsx";
import CollectionsPage from "./pages/Collections/CollectionsPage.jsx";
import AdminDashboard from "./admin/dashboard/index.jsx";
import SubmissionsPage from "./admin/dashboard/submissions/SubmissionsPage.jsx";
import UsersPage from "./admin/dashboard/users/UsersPage.jsx";
import AdminPublicationsPage from "./admin/dashboard/publications/PublicationsPage.jsx";
import InteractiveAnalysisPage from "./admin/dashboard/interactive-analysis/InteractiveAnalysis.jsx";
import NotificationsPage from "./admin/dashboard/notifications/NotificationsPage.jsx";
import OverviewPage from "./admin/dashboard/over-view/OverViewPage.jsx";
import UserDetailPage from "./admin/dashboard/users/details/UserDetailsPage.jsx";
import InteractiveAnalysisDetailsPage from "./admin/dashboard/interactive-analysis/detail/InteractiveAnalysisDetailsPage.jsx";
import PublicationDetailPage from "./admin/dashboard/publications/detail/PublicationsDetailPage.jsx";
import PublicationVisaulizationPage from "./pages/Publications/visualization";
import PublicationViewPage from "./pages/Publications/view/index.jsx";
import SliceAndDicePage from "./pages/SliceAndDice/index";
import SubmitDataset from "./pages/SubmitDataset";
import QuestionLogicPage from "./admin/dashboard/question-logic/index.jsx";
import QuestionLogicDetailPage from "./admin/dashboard/question-logic/over-view/index.jsx";
// import PublicQuestionLogicPage from "./pages/Question-logic/index.jsx";
import QuestionOverview from "./pages/Question-logic/over-view/index.jsx";
import Resources from "./admin/dashboard/resources/index.jsx";
import Team from "./admin/dashboard/team/index.jsx";
import ResourcesSections from "./admin/dashboard/resources/sections/index.jsx";
import PageDetailPage from "./admin/dashboard/resources/detail/index";
import PublicPage from "./pages/Cms/pages.jsx";
import PublicPageDetail from "./pages/Cms/detail/index.jsx";
import PublicDatasetDetail from "./pages/Cms/detail/dataset.jsx";
import WebResources from "./pages/WebResources/index.jsx";
import Missing from "./pages/Missing/MissingPage";
import AnalysisPage from "./pages/AnalysisPage/AnalysisPage.jsx";

const Routes = () => (
  <ProvideAuth>
    <Switch>
      <Route exact path="/" component={HomePage} />
      {/* <Route path="/explore" component={ExplorePage} /> */}
      <Route path="/interactivity" component={InteractivityPage} />
      <Route path="/datasetspage" component={DataSetsPage} />
      <Route path="/analysispage" component={AnalysisPage} />
      <Route exact path="/publications" component={PublicationsPage} />
      <Route
        exact
        path="/publications/:uuid/visualise"
        component={PublicationVisaulizationPage}
      />
      <Route
        exact
        path="/publications/:uuid/view"
        component={PublicationViewPage}
      />
      <Route path="/contact" component={ContactPage} />
      <Route path="/about" component={AboutPage} />
      <Route path="/public/datasets" component={PublicPage} />
      {/* <Route path="/public" component={PublicPage} /> */}
      <Route
        exact
        path="/pages/dataset/:uuid"
        component={PublicDatasetDetail}
      />
      <Route exact path="/pages/:slug" component={PublicPageDetail} />
      <Route path="/faqs" component={FAQPage} />
      <Route path="/terms-of-use" component={TermsOfUsePage} />
      <Route path="/login" component={SignInPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/register" component={RegisterPage} />
      <Route path="/web-resources" component={WebResources} />
      <Route path="/:uuid/interactive-slide" component={InteractiveSlidePage} />
      <Route
        path="/:uuid/interactive-animation"
        component={InteractiveAnimationPage}
      />
      <Route path="/search-dataset" component={SearchDatasetPage} />
      <Route path="/explore-data" component={ExploreDataPage} />
      <Route path="/collections" component={CollectionsPage} />
      <Route path="/reset-password/:secret" component={ResetPasswordPage} />
      <Route path="/slice-and-dice" component={SliceAndDicePage} />
      <Route path="/submit-dataset" component={SubmitDataset} />
      {/* <Route exact path="/question-logic" component={PublicQuestionLogicPage} /> */}
      <Route path="/question-logic/:uuid/detail" component={QuestionOverview} />
      <Route
        path="/question-logic/:uuid/view"
        component={QuestionLogicDetailPage}
      />
      <PrivateRoute exact path="/admin/dashboard">
        <AdminDashboard />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/submissions">
        <SubmissionsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/dashboard/users">
        <UsersPage />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/dashboard/publications">
        <AdminPublicationsPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/publications/:uuid/detail">
        <PublicationDetailPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/resources/:slug/detail">
        <PageDetailPage />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/dashboard/interactive-analysis">
        <InteractiveAnalysisPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/interactive-analysis/:uuid/detail">
        <InteractiveAnalysisDetailsPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/notifications">
        <NotificationsPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/:uuid/over-view">
        <OverviewPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/users/detail">
        <UserDetailPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/question-logic">
        <QuestionLogicPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/dashboard/question-logic/detail">
        <QuestionLogicDetailPage />
      </PrivateRoute>
      <PrivateRoute path="/admin/resources">
        <Resources />
      </PrivateRoute>
      <PrivateRoute path="/admin/team">
        <Team />
      </PrivateRoute>
      <PrivateRoute path="/admin/sections">
        <ResourcesSections />
      </PrivateRoute>
      <Route path="*" component={Missing} />
    </Switch>
  </ProvideAuth>
);

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user || JSON.parse(localStorage.getItem("eko-360-p2-user")) ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default Routes;
