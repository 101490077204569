import React, {useEffect, useState} from 'react';
import {useHistory, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';

// import Header from '../../../common/components/Header';
import Nav from '../../../common/components/Nav';
import Footer from '../../../common/components/Footer';
import { errorHandler } from '../../../utils/helpers/errorHandler';
import { toast } from 'react-toastify';

import { fetchPage } from "../../../utils/api/cms/page";

const rootUrl = `${process.env.REACT_APP_ROOT_URL}`,
      token = JSON.parse(localStorage.getItem("eko-360-p2-token"));

const PageDetails = () => {
  const {slug} = useParams();

  const [publicPage, setPublicPage] = useState(undefined),
        [rightPosition, setRightPosition] = useState(),
        [leftPosition, setLeftPosition] = useState(),
        [bottomPosition, setBottomPosition] = useState(),
        [topPosition, setTopPosition] = useState(),
        [middlePosition, setMiddlePosition] = useState();

  const handleFetchPublicPage = async () => {
    try {
      const data = await fetchPage(slug);

      setPublicPage(data);
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  useEffect(() => {
    if(publicPage && publicPage.sections[0]) {
      const rightPosition = publicPage.sections.filter(section => section.position.toLowerCase() === 'right'),
            topPosition = publicPage.sections.filter(section => section.position.toLowerCase() === 'top'),
            middlePosition = publicPage.sections.filter(section => section.position.toLowerCase() === 'middle'),
            bottomPosition = publicPage.sections.filter(section => section.position.toLowerCase() === 'bottom'),
            leftPosition = publicPage.sections.filter(section => section.position.toLowerCase() === 'left');

      setTopPosition(topPosition[0]);
      setRightPosition(rightPosition[0]);
      setMiddlePosition(middlePosition[0]);
      setBottomPosition(bottomPosition[0]);
      setLeftPosition(leftPosition[0]);
    }
  }, [publicPage])


  useEffect(() => {
    handleFetchPublicPage()
  }, []);

  const handleFileDownload = async (event, dataset) => {
    event.preventDefault();
    try {
      const fileName = dataset.file.substr(dataset.file.lastIndexOf('/') + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/datasets/${fileName}/download/`,
        {
          headers: {
            'Content-Type': 'application/json', Authorization: `${token}`
          }
        });

      const blob = await res.blob()
      saveAs(blob, fileName);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  }

  return(
    <>
      <header>
        <div className="__landbgex" style={{
          background: publicPage?.banner.startsWith('/') ? `${rootUrl}/${publicPage?.banner.substr(publicPage?.banner.lastIndexOf('/')+1)}` : `linear-gradient(100deg, rgba(2, 31, 33, 0.7) 50%, rgba(2, 31, 33, 0.7) 50%), url(${(publicPage && publicPage?.banner) ? `${publicPage?.banner}` : '/assets/img/captbg.jpeg'})`,
          backgroundPosition: "center !important",
          backgroundRepeat: "no-repeat !important",
          backgroundSize: "cover !important",
          padding: "0 0 10%"
        }}>
          <Nav/>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-2 col-sm-1"></div>
              <div className="col-lg-6 col-md-8 col-sm-10">
                <p className="__captionex text-center">{ (publicPage && publicPage?.title) ? publicPage?.title : 'Public Pages'}</p>
              </div>
              <div className="col-lg-3 col-md-2 col-sm-1"></div>
            </div>
          </div>
        </div>
      </header>

      <section>

        <div className="__abg">
          <div className="container">
            <div className="__spacet3p"></div>
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-12" style={{ minHeight: "250px" }}>
                <div className="row" style={{display: "flex"}}>
                  <div style={{ maxWidth: "25%", padding: "10px" }}>
                    {leftPosition?.type === 'content' && (<p>{leftPosition?.body}</p>)}
                    {leftPosition?.type === 'image' && (<img
                      src={leftPosition?.body.startsWith('/') ? `${rootUrl}/${leftPosition?.body.substr(leftPosition?.body.lastIndexOf('/')+1)}`: `${leftPosition?.body}`}
                      alt=""
                      style={{ width: "100%", height: "100%"}} />)}
                    {leftPosition?.type === 'url' && (
                      <iframe
                        className="__ifrmv"
                        src={leftPosition?.body}
                        frameBorder="0"
                        allowFullScreen="true"
                        title="powerBi"
                      ></iframe>
                    )}
                  </div>
                  <div style={{ width: "50%", padding: "10px", flex: "1" }}>
                    {publicPage?.title && (<h2 style={{ fontWeight: "bold"}}>{publicPage?.title}</h2>)}
                    {publicPage?.type === "budgets" && (<div
											dangerouslySetInnerHTML={{__html: publicPage?.budget}}
											style={{
												fontSize: "13px",
												lineHeight: "1.1",
												color: "#000000",
                        marginBottom: "30px",
                      }}
											></div>)}

                      <div className="row">
                          {publicPage?.file && (<div className="col-xs-2">
                            <a
                              href="#"
                              className="btn btn-block hvr-float-shadow __latbtn1"
                              style={{ alignSelf: 'flex-ends', marginBottom: 20 }}
                              onClick={(event) => handleFileDownload(event, publicPage)}
                            >Download Dataset</a>
                          </div>)}
                      </div>

                    {publicPage?.body && (<div
											dangerouslySetInnerHTML={{__html: publicPage?.body}}
											style={{
												fontSize: "13px",
												lineHeight: "1.1",
												color: "#000000",
                        marginBottom: "30px",
                      }}
											></div>)}
                  </div>
                  <div style={{ maxWidth: "25%", padding: "10px" }}>
                    {rightPosition?.type === 'content' && (<p>{rightPosition?.body}</p>)}
                    {rightPosition?.type === 'image' && (<img
                      src={rightPosition?.body.startsWith('/') ? `${rootUrl}/${rightPosition?.body.substr(rightPosition?.body.lastIndexOf('/')+1)}` : `${rightPosition?.body}`}
                      alt=""
                      style={{ width: "100%", height: "100%"}} />)}
                    {rightPosition?.type === 'url' && (
                      <iframe
                        className="__ifrmv"
                        src={rightPosition?.body}
                        frameBorder="0"
                        allowFullScreen="true"
                        title="powerBi"
                      ></iframe>
                    )}
                  </div>
                </div>
                <div className="__spacet7p"></div>
              </div>
              {(bottomPosition && Object.keys(bottomPosition).length) && (<div className="col-lg-12" style={{width: "100%", minHeight: "250px"}}>
                <div style={{ maxWidth: "100%", padding: "10px", height: "100%" }}>
                    {bottomPosition?.type === 'content' && (<p>{bottomPosition?.body}</p>)}
                    {bottomPosition?.type === 'image' && (<img
                      src={bottomPosition?.body.startsWith('/') ? `${rootUrl}/${bottomPosition?.body.substr(bottomPosition?.body.lastIndexOf('/')+1)}`:`${bottomPosition?.body}`}
                      alt=""
                      style={{ width: "100%", height: "100%"}} />)}
                    {bottomPosition?.type === 'url' && (
                      <iframe
                        className="__ifrmv"
                        src={rightPosition?.body}
                        frameBorder="0"
                        allowFullScreen="true"
                        title="powerBi"
                      ></iframe>
                    )}
                  </div>
              </div>)}
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default PageDetails;