import { 
  SET_SECTIONS,
  ADD_SECTION
} from '../../../actiontypes';


export const setSections = (payload) => ({
  type: SET_SECTIONS,
  payload
});

export const addSection = (payload) => ({
  type: ADD_SECTION,
  payload
});