import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";

import { ToastContainer, toast } from "react-toastify";

import Footer from "../../common/components/Footer";
import Nav from "../../common/components/Nav";
import { fetchPublicPages } from "../../utils/api/cms/page";
import { errorHandler } from "../../utils/helpers/errorHandler";

import DownloadApp from "../../common/components/DownloadApp";
const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));
const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;

const DataSetsPage = () => {
  const [publicPages, setPublicPages] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const handleFetchPublicPages = async (page = 0) => {
    try {
      const data = await fetchPublicPages(page);

      setPublicPages(data.data.allPages);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  useEffect(() => {
    handleFetchPublicPages();
  }, []);

  useEffect(() => {
    if (publicPages) {
      const datasets = publicPages.filter((pages) => pages.type === "datasets");

      setDatasets(datasets);
    }
  }, [publicPages]);

  const handleFileDownload = async (event, dataset) => {
    event.preventDefault();
    try {
      const fileName = dataset.file.substr(dataset.file.lastIndexOf("/") + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/datasets/${fileName}/download/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      const blob = await res.blob();
      saveAs(blob, dataset.title);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  };

  return (
    <div>
      <header>
        <Nav />
      </header>

      <section>
        <div className="container">
          <div
            className="__all-banner"
            style={{ background: `url(/assets/img/pagesbanner.png)` }}
          >
            <p className="__caption-banner text-center">Latest Datasets</p>
          </div>
          <div className="__spacet5p"></div>
        </div>

        <div
          className="__datasetbg"
          style={{ background: `url(/assets/img/latestbg.png)` }}
        >
          {datasets.length > 0 && (
            <div className="container">
              <div className="__quipdiv">
                <p className="__latst1">
                  These shows the latest datasets from health, education and
                  Economic affairs. Click “download dataset” to get the
                  corresponding data associated with each dataset
                </p>
              </div>
              <div className="row">
                {datasets.length > 0
                  ? datasets.map((dataset) => (
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className=" __wokin">
                          <div
                            className="__latimg"
                            style={{
                              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), url(${dataset?.banner})`,
                            }}
                          ></div>
                          <div className="__latestcard">
                            <p className="__latimgp">{dataset.title}</p>
                            {dataset.body.length > 700 ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${dataset.body.substr(0, 700)}...`,
                                }}
                                style={{ flex: 1 }}
                              ></div>
                            ) : (
                              <div
                                className="__setpar"
                                dangerouslySetInnerHTML={{
                                  __html: dataset.body,
                                }}
                                style={{ flex: 1 }}
                              ></div>
                            )}
                            <div className="__morbtn">
                              {/* <div className='__btnbloc'>
										<Link to={`/pages/${dataset.slug}`} className="btn btn-block hvr-float-shadow __latbtn1">Read more</Link>
									</div> */}
                              {dataset.file && (
                                <div
                                  className="__btnbloc"
                                  onClick={(event) =>
                                    handleFileDownload(event, dataset)
                                  }
                                >
                                  <a
                                    href="#"
                                    className="btn  hvr-float-shadow __latbtn1"
                                    style={{ alignSelf: "flex-ends" }}
                                  >
                                    <img src="/assets/img/datadownload.png" />{" "}
                                    Download Dataset
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default DataSetsPage;
