import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import moment from "moment";
import { saveAs } from 'file-saver';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';
import Pagination from "../../common/components/Pagination";

import './search-dataset.css'

import { searchDataset } from "../../utils/api/dataset";
import { setDataSetToVisualize } from '../../store/actions/actions/survey';
import { errorHandler } from "../../utils/helpers/errorHandler";
import DownloadApp from "../../common/components/DownloadApp";


const SearchDatasetPage = () => {
  const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

  const searchParams = useSelector(state => state.search);

  const history = useHistory();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState(searchParams.query);
  const [monthValue, setMonthValue] = useState(undefined);
  const [datasetSurveyResult, setDatasetSurveyResult] = useState([]);
  const [year, setYear] = useState(moment(new Date()).format("YYYY"));

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);

  const monthOptions = [
    { value: 1, name: 'January' },
    { value: 2, name: 'February' },
    { value: 3, name: 'March' },
    { value: 4, name: 'April' },
    { value: 5, name: 'May' },
    { value: 6, name: 'June' },
    { value: 7, name: 'July' },
    { value: 8, name: 'August' },
    { value: 9, name: 'September' },
    { value: 10, name: 'October' },
    { value: 11, name: 'November' },
    { value: 12, name: 'December' }
  ];

  useEffect(() => {
    setSearchText(searchParams.query);
  }, []);

  useEffect(() => {
    advancedSearch();
  }, []);


  const handleSearch = async (searchQuery) => {
    try {
      const data = await searchDataset(searchQuery);

      setPage(data.page);
      setMaxPage(data.count);
      setDatasetSurveyResult(data.allDatasets);
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  useEffect(() => {
    var x, i, j, selElmnt, a, b, c;
    x = document.getElementsByClassName("custom-select");
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < selElmnt.length; j++) {
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function (e) {
          var y, i, k, s, h;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          h = this.parentNode.previousSibling;
          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              const event = new Event('change');
              selElmnt.dispatchEvent(event)
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }
    function closeAllSelect(elmnt) {
      var x, y, i, arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }
    document.addEventListener("click", closeAllSelect);
  }, []);

  const advancedSearch = async (page = 0) => {
    try {
      const searchParams = {
        query: searchText.trim(),
        month: monthValue,
        year,
        page
      };


      const validSearchKeys = [];

      for (let key in searchParams) {
        if (searchParams[key]) {
          validSearchKeys.push(`${key}=${searchParams[key]}`);
        }
      }

      await handleSearch(validSearchKeys);

    } catch (error) {
      errorHandler(error, toast);
    }
  }

  const handleAdvanceSearch = async (event) => {
    event.preventDefault();
    await advancedSearch();
  }

  const gotoExplore = (data, event) => {
    event.preventDefault();
    dispatch(setDataSetToVisualize(data));
    history.push('/explore');
  }

  const handleFileDownload = async (file, name) => {
    try {
      const fileName = file.substr(file.lastIndexOf('/') + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/publications/${fileName}/download/`,
        {
          headers: { 'Content-Type': 'application/json' }
        });

      const blob = await res.blob()
      saveAs(blob, name);
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  const handleNextPagination = async (event) => {
    event.preventDefault();
    await advancedSearch(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await advancedSearch(page - 2);
  }

  return (
    <>
      <header>
      <Nav/>
        <div className="__landschbg">
          
          <div className="container">
            <p className="__captions">You searched for “{searchText}”</p>

            <div className="__formdiv __serchfmdiv">
              <form>
                <div className="input-group">
                  <select 
                    required
                    className="form-control __ekoform"
                    placeholder="Search for information"
                    onChange={(event) => {
                      setSearchText(event.target.value)
                      // if (selected.length) setQuery(selected[0])
                    }}
                    >
                    <option value="" selected disabled>Search for information</option>
                    <option value="Education">Education</option>
                    <option value="Health">Health</option>
                    <option value="Economic Affairs">Economic Affairs</option>
                  </select>
                  
                  <span className="input-group-btn">
                    <button
                      className="btn __ekoformbtn"
                      type="submit"
                      onClick={handleAdvanceSearch}
                    >
                      Search
                    </button>
                  </span>
                </div>
              </form>

              {/* <div className="row">

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="input-group" style={{ width: '100%' }}>
                    <Datetime
                      className="date-time"
                      dateFormat="YYYY"
                      timeFormat=""
                      onChange={(date) => setYear(date.year())}
                      initialValue={moment(new Date()).format("YYYY")}
                    />

                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="input-group" style={{ width: '100%' }}>
                    <select className="form-control __ekoselect" onChange={event => setMonthValue(event.target.value)}>
                      <option value="" selected hidden>Month</option>
                      {monthOptions.map((month, idx) => (<option selected={+month.value === +monthValue} value={month.value} key={idx}>{month.name}</option>))}
                    </select>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </header>

      <section>
        <div className="container">
          <div className="__spacet3p"></div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4"></div>
          </div>
          <hr className="__datahr" />

          {datasetSurveyResult.length > 0 ? datasetSurveyResult.map((data) => (
            <>
              <p className="__datap">{data.name}</p>
              {data.type === "file" && (
                <p className="__datap1"><span className="__sp1">Downloadable Format:</span>&nbsp;&nbsp;
                  <a href="https://dsnai.sharepoint.com/:x:/s/EKO360Project/EfYeJsnZfLBLodZxmZirvQoBMb82sYexejVUi8HaLOKNrQ?e=lgAhjL" className="__badge">{data.file.substr(data.file.lastIndexOf(".") + 1) == "xlsx" ? "Excel" : data.file.substr(data.file.lastIndexOf(".") + 1)}
                  </a>
                </p>
              )}
              <p className="__datap2">{data.description}</p>

              <div className="row">
                {/* {data.file.startsWith("/") && (
                  <> */}
                    <div className="col-lg-2 col-md-3 col-sm-3 col-xs-6">
                      <a
                        href="#"
                        className="btn btn-block hvr-float-shadow __databtn"
                        onClick={event => {
                          event.preventDefault();
                          handleFileDownload(data.file, data.name);
                        }}
                      >
                        Download Data
                      </a>
                    </div>
                  {/* </> */}
                {/* )} */}
                {/* {dataset.file && (<div className="__btnbloc" onClick={(event) => handleFileDownload(event,dataset)}>
								<a href="#" className="btn btn-block hvr-float-shadow __latbtn1" style={{ alignSelf: 'flex-ends' }}>Download Dataset</a>
							</div>)} */}

                {/* {data.file.startsWith("http") || data.survey_responses ? (
                  <>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-xs-6">
                      <a
                        href="#"
                        onClick={(event) => gotoExplore(data, event)}
                        className="btn btn-block hvr-float-shadow __databtn"
                      >
                        Download data
                      </a>
                    </div>
                  </>
                ) : null} */}

              </div>


              <hr className="__datahr" />
            </>
          )) : <p style={{ fontSize: 24, textAlign: "center" }}>There are no search results</p>}

          <div className="__expmia1i">
            <div className="container">
              <Pagination
                page={+page}
                maxPage={+maxPage}
                SIZE_PER_PAGE={+SIZE_PER_PAGE}
                handleNextPagination={handleNextPagination}
                handlePreviousPagination={handlePreviousPagination}
              />
            </div>
          </div>
        </div>

        {/* <DownloadApp /> */}
      </section>
      <ToastContainer
        closeOnClick
        pauseOnHover
      >
      </ToastContainer>

      <Footer />
    </>
  )
}

export default SearchDatasetPage;