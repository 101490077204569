import React from 'react'
import UserAvatar from '../../common/UserAvatar'

const DashboardNav = (props) => {
  return (
    <>
        <nav class='navbar navbar-expand-lg navbar-dark __dashnavbar'>
            <div class='container'>
                <a
                href='#'
                className='navbar-brand __navbar-brand1'
                id='openSidebar'>
                <i
                    className=' fa fa-bars'
                    ></i>
                <span className='hidden-sm'>
                   {props.page}
                </span>
                </a>
                <p className='__dasovp'>{props.page}</p>
                <button class='navbar-toggler' type='button' data-bs-toggle="collapse" data-bs-target="#navmenu">
                    <span class='navbar-toggler-icon'></span>
                </button>

                <div class='collapse navbar-collapse' id='navmenu'>
                    <ul class="navbar-nav ms-auto">
                    <UserAvatar />
                    </ul>
                </div>
            </div>
        </nav>
    </>
  )
}

export default DashboardNav