/** @format */

import React from 'react';
import {Link} from 'react-router-dom';

const Legal = () => (
	<>
		{/* <p className='__abouplc'>PRIVACY POLICY</p>
		<div className='__spacet2p'></div> */}

		<div class="accordion __accordion accordion-flush" id="accordionFlushExample">
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingOne">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
					What is this Privacy Policy for?
				</button>
				</h2>
				<div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>This privacy policy (“Policy”) will help you understand how the eko360 project (“We”, “Us”, “Our”) uses and protects the personal data you provide us when you visit and use the eko360 website. We value your Personal Data and we are committed to protecting your privacy whenever you interact with us. Please read this Policy to understand our policies, processes and procedures regarding the processing of your personal data. </p>
						<p className='__polcy'>By this Policy, We explain to you how your Personal Data is collected, used, managed and transferred by us and also explain how you can update your Personal Data with us and exercise your rights in respect of the Personal Data provided to us.</p>
					</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingTwo">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
					When do we collect your personal data?
				</button>
				</h2>
				<div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>From our website, personal data can be collected through the following ways:</p>
						
						<p className='__polcy'>
							. Cookies: We may also automatically collect some technical information when you visit our website such as your IP address, and information about your visit, such as the pages that you viewed. This information helps us understand customer interests and helps us improve our website. When you visit our site, the pages that you look at, and a short text file called a cookie, are downloaded to your computer. By visiting and using our website, you agree to our use of cookies in line with our Cookies policy. For more details about Cookies, please read our Cookies Policy available through this <Link to="cookies"><b>link</b></Link>.
						</p>

						<p className='__polcy'>
							. Through the various products and services rendered by the eko360 Project: We render several services which necessitates the provision of personal data by the Data Subjects i.e. names, addresses, phone numbers etc.
						</p>

						<p className='__polcy'>
							. When you request for further information about our product or fill a form.
						</p>
						<p className='__polcy'>
							. When you subscribe to newsletters on our website.
						</p>
					</div>
				</div>
			</div>
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingThree">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
					What are the main purposes for processing your data?
				</button>
				</h2>
				<div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>
						We may process your Personal Data to communicate with you (including sending marketing or promotional materials to you) and to provide you with further information about our services and products and how we can serve you better. We may also process your Personal Data to comply with provisions of applicable laws. 
						</p>
					</div>
				</div>
			</div>
			
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingFour">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
					How long do we store your data?
				</button>
				</h2>
				<div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>The storing of your data will depend of the purpose of the processing: </p>
						<p className='__polcy'>(1) if we process it for sending you commercial or promotional communications, we will store it until your objection.</p>
						<p className='__polcy'>(2) if we use your data to analyze and manage your requests, questions and consultations, we will process and store your data as long as it takes us to answer your request, question and/or consultation.</p>
						<p className='__polcy'>In all scenarios, once the storage period has concluded, the data will be stored dully blocked for the period legally required for the compliance of any contractual and/or legal obligations.</p>
					</div>
				</div>
			</div>
			
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingFive">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
					What is the legal basis for processing your data?
				</button>
				</h2>
				<div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>Where processing of Personal Data is based on consent, We shall obtain the requisite consent at the time of collection of the Personal Data. In this regard, you consent to the processing of your Personal Data when you access our platforms, or use our services, content, features, technologies or functions offered on our website or other digital platforms. You can withdraw your consent at any time but such withdrawal will not affect the lawfulness of processing based on consent given before its withdrawal.</p>
					</div>
				</div>
			</div>
			
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingSix">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
					Who do we share your Personal Data with?
				</button>
				</h2>
				<div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>We respect your privacy and we limit disclosure of your Personal Data to third parties. We do not sell, give or trade any Personal Data that We obtain from you to any third party for data-mining or marketing purposes. However, We may share your Personal Data with service providers engaged by us to provide services subject to appropriate data security and protection. We may also share your information where there is a regulatory or statutory obligation to disclose such Personal Data in accordance with provisions of applicable laws.</p>
					</div>
				</div>
			</div>
			
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingSeven">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
					What are your rights when you provide us with your data?
				</button>
				</h2>
				<div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>eko360 collects Personal Data only for the purposes identified in this Policy and such information cannot be reused for another purpose that is incompatible with the original purpose. </p>
						<p className='__polcy'>You can exercise the following rights with respect to your Personal Data: </p>
						<p className='__polcy'>a) request for and access your Personal Data collected and stored; </p>
						<p className='__polcy'>b) withdraw consent at any time. For example, you can withdraw your consent to receipt of our marketing or promotional materials or unsubscribe to our newsletters;  </p>
						<p className='__polcy'>c) object to automated decision making;  </p>
						<p className='__polcy'>d) request rectification and modification of Personal Data kept by eko360;</p>
						<p className='__polcy'>e) request for deletion of your Personal Data;</p>
						<p className='__polcy'>f) be informed of and entitled to provide consent prior to the processing of Personal Data for purposes other than that for which the Personal Data were collected;</p>
						<p className='__polcy'>g) request that we restricts processing of your Personal Data; and </p>
						<p className='__polcy'>h) request for information regarding any specific processing of your personal data. </p>
						<p className='__polcy'>If you had given your consent for any specific purpose, you may withdraw that consent whenever you wish, without affecting the lawfulness of the processing based on the consent prior to withdrawal. If you haven’t obtained satisfaction in the exercise of your rights, you may file a complaint with the competent data protection authority.</p>
					</div>
				</div>
			</div>
			
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingEight">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
					Updates
				</button>
				</h2>
				<div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>We will only use your personal data as set out in this Privacy Policy which is in force at the time, we collect your information. If any revision or update is done that affects the processing of your personal data, we will inform you.</p>
					</div>
				</div>
			</div>
			
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingNine">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
					Security and Retention of your Personal Data
				</button>
				</h2>
				<div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>Your Personal Data is kept private and we make every effort to keep your Personal Data secure, including restricting access to your Personal Data with us on a need to know basis. We require our staff and any third parties who carry out any work on our behalf to comply with appropriate security standards to protect your Personal Data.</p>
						<p className='__polcy'>We take appropriate measures to ensure that your Personal Data is only processed for the minimum period necessary in line with the purposes set out in this Policy or as required by applicable laws, until a time it is no longer required or has no use. Once your Personal Data is no longer required, we destroy it in a safe and secure manner. </p>
					</div>
				</div>
			</div>
			
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingTen">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
					Changes to our Privacy Policy
				</button>
				</h2>
				<div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>Due to constant changes in technology and regulatory requirements, we may need to change our privacy policies or update this Policy from time to time. You will always be able to find the most recent version of our updated privacy policy on this site.</p>
					</div>
				</div>
			</div>
			
			<div class="accordion-item">
				<h2 class="accordion-header" id="flush-headingEleven">
				<button class="accordion-button collapsed __poltb" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
					Contact & Communication
				</button>
				</h2>
				<div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
					<div class="accordion-body">
						<p className='__polcy'>If you would like further information on this Policy, or to contact our Data Protection Officer, you may contact us at , Lagos, Nigeria or at <b><a href='mailto:info@eko360.ng'>info@eko360.ng</a></b></p>
					</div>
				</div>
			</div>
		</div>

		

		
	</>
);

export default Legal;
