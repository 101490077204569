import { 
  SET_INTERACTIVE_ANALYSIS,
  ADD_INTERACTIVE_ANALYSIS,
  SET_SELECTED_INTERACTIVE_ANALYSIS,
  UPDATE_INTERACTIVE_ANALYSIS_STATUS,
  UPDATE_INTERACTIVE_ANALYSIS,
  DELETE_INTERACTIVE_ANALYSIS,
  SET_PUBLIC_INTERACTIVE_ANALYSIS_TO_BE_VISUALIZED
} from '../../actions/actiontypes';

const initialState = {
  interactive_analysis: [],
  selected_interactive_analysis: null,
  public_interactive_analysis_to_be_visualized: null
}

const interactiveAnalysisReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_SELECTED_INTERACTIVE_ANALYSIS: 
    return {
      ...state,
      selected_interactive_analysis: action.payload
    }

    case SET_INTERACTIVE_ANALYSIS: 
    return {
      ...state,
      interactive_analysis: action.payload
    }
    
    case ADD_INTERACTIVE_ANALYSIS: {
      const update = [action.payload,...state.interactive_analysis];
      return {
        ...state,
        interactive_analysis: update,
      }
    }
    case UPDATE_INTERACTIVE_ANALYSIS_STATUS: {
      const index = state.interactive_analysis.findIndex(data => data.uuid === state.selected_interactive_analysis.uuid);
      const updateSelectedDataset = {...state.selected_interactive_analysis, status: action.payload.status};
      state.interactive_analysis.splice(index, 1, updateSelectedDataset);
      return {
        ...state,
        interactive_analysis: state.interactive_analysis,
      }
    }

    case UPDATE_INTERACTIVE_ANALYSIS: {
      const index = state.interactive_analysis.findIndex(data => data.uuid === state.selected_interactive_analysis.uuid);
      state.interactive_analysis.splice(index, 1, action.payload);
      return {
        ...state,
        interactive_analysis: state.interactive_analysis,
        selected_interactive_analysis: action.payload
      }
    }

    case DELETE_INTERACTIVE_ANALYSIS: {
      const index = state
      .interactive_analysis
      .findIndex(data => data.uuid === state.selected_interactive_analysis.uuid);
      
      state.interactive_analysis.splice(index, 1);
      return {
        ...state,
        interactive_analysis: state.interactive_analysis,
        selected_interactive_analysis: null
      }
    }

    case SET_PUBLIC_INTERACTIVE_ANALYSIS_TO_BE_VISUALIZED: {
      return {
        ...state,
        public_interactive_analysis_to_be_visualized: action.payload
      }
    }

    default: return state;
  }
}

export default interactiveAnalysisReducer;