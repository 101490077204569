export const SIGNIN_USER = "SIGNIN_USER";
export const STORE_ALL_USERS = "STORE_ALL_USERS";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";

export const SEARCH = "SEARCH";

export const SET_DATASET_TO_VISUALIZE = "SET_DATASET_TO_VISUALIZE";
export const SET_SELECTED_DATASET = "SET_SELECTED_DATASET";
export const SET_DATASET = "SET_DATASET";
export const ADD_DATASET = "ADD_DATASET";
export const UPDATE_DATASET_STATUS = "UPDATE_DATASET_STATUS";
export const UPDATE_DATASET = "UPDATE_DATASET";

export const ADD_METRICS = "ADD_METRICS";
export const STORE_METRICS = "STORE_METRICS";

export const SET_INTERACTIVE_ANALYSIS = "SET_INTERACTIVE_ANALYSIS";
export const ADD_INTERACTIVE_ANALYSIS = "ADD_INTERACTIVE_ANALYSIS";
export const SET_SELECTED_INTERACTIVE_ANALYSIS =
  "SET_SELECTED_INTERACTIVE_ANALYSIS";
export const UPDATE_INTERACTIVE_ANALYSIS_STATUS =
  "UPDATE_INTERACTIVE_ANALYSIS_STATUS";
export const UPDATE_INTERACTIVE_ANALYSIS = "UPDATE_INTERACTIVE_ANALYSIS";
export const DELETE_INTERACTIVE_ANALYSIS = "DELETE_INTERACTIVE_ANALYSIS";
export const SET_PUBLIC_INTERACTIVE_ANALYSIS_TO_BE_VISUALIZED =
  "SET_PUBLIC_INTERACTIVE_ANALYSIS_TO_BE_VISUALIZED";

export const SET_PUBLICATIONS = "SET_PUBLICATIONS";
export const SET_SELECTED_PUBLICATION = "SET_SELECTED_PUBLICATION";
export const ADD_PUBLICATION = "ADD_PUBLICATION";
export const UPDATE_PUBLICATION_STATUS = "UPDATE_PUBLICATION_STATUS";
export const UPDATE_PUBLICATION_DATA = "UPDATE_PUBLICATION_DATA";
export const DELETE_PUBLICATION = "DELETE_PUBLICATION";
export const SET_PUBLICATION_TO_BE_VISUALIZED =
  "SET_PUBLICATION_TO_BE_VISUALIZED";

export const SET_TEAM = "SET_TEAM";
export const SET_SELECTED_TEAM_MEMBER = "SET_SELECTED_TEAM_MEMBER";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const UPDATE_TEAM_MEMBER_DATA = "UPDATE_TEAM_MEMBER_DATA";
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER";
export const SET_TEAM_MEMBER_TO_BE_VISUALIZED =
  "SET_TEAM_MEMBER_TO_BE_VISUALIZED";

export const SET_QUESTION = "SET_QUESTION";
export const SET_ALL_QUESTIONS = "SET_ALL_QUESTIONS";
export const ADD_QUESTION = "ADD_QUESTION";

export const SET_PAGES = "SET_PAGES";
export const ADD_PAGE = "ADD_PAGE";
export const UPDATE_PAGE_STATUS = "UPDATE_PAGE_STATUS";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";

export const SET_SECTIONS = "SET_SECTIONS";
export const ADD_SECTION = "ADD_SECTION";
