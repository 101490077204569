import React, { useEffect, useState } from "react";
import { NavLink as Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

// import Header from '../../common/components/Header';
import Nav from "../../common/components/Nav";
import Footer from "../../common/components/Footer";
import { errorHandler } from "../../utils/helpers/errorHandler";

import {
  fetchAllPublishedInteractiveAnalysis,
  searchInteractivity,
} from "../../utils/api/interactiveAnalysis";
import { publicInteractiveAnalysisToBeVisualized } from "../../store/actions/actions/interactiveAnalysis";

import Pagination from "../../common/components/Pagination";
import DownloadApp from "../../common/components/DownloadApp";
const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;

const InteractivityPage = () => {
  const SIZE_PER_PAGE =
    `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
      ? 20
      : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

  const history = useHistory(),
    dispatch = useDispatch();

  const [interactiveAnalysis, setInteractiveAnalysis] = useState([]),
    [page, setPage] = useState(0),
    [maxPage, setMaxPage] = useState(SIZE_PER_PAGE),
    [showPageLoader, setShowPageLoader] = useState(true),
    [searchResult, setSearchResult] = useState([]),
    [searchValue, setSearchValue] = useState(""),
    [hasResult, setHasResult] = useState(true);

  const handleFetchPublishedInteractiveAnalysis = async (page) => {
    setShowPageLoader(true);
    try {
      const data = await fetchAllPublishedInteractiveAnalysis(page);

      setPage(data.page);
      setMaxPage(data.count);
      setInteractiveAnalysis(data.allInteractivities);
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      errorHandler(error, toast);
    }
  };

  useEffect(() => {
    handleFetchPublishedInteractiveAnalysis();
  }, []);

  const handleFileDownload = async (event, interactiveAnalysisData) => {
    event.preventDefault();
    try {
      let files;

      if (interactiveAnalysisData.contentUrl.includes(",")) {
        files = interactiveAnalysisData.contentUrl
          .split(",")
          .map((file) => file.substr(file.lastIndexOf("/") + 1))
          .join("+");
      } else {
        files = interactiveAnalysisData.contentUrl.substr(
          interactiveAnalysisData.contentUrl.lastIndexOf("/") + 1
        );
      }

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/interactivities/${files}/download/`
      );

      const blob = await res.blob();
      const name = interactiveAnalysisData.title.split(" ").join("-");
      saveAs(blob, `${name}`);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  const handleGotoToAnimation = (event, data) => {
    event.preventDefault();
    dispatch(publicInteractiveAnalysisToBeVisualized(data));
    history.push(`/${data.uuid}/interactive-animation`);
  };

  const handleGotoToSlide = (event, data) => {
    event.preventDefault();
    dispatch(publicInteractiveAnalysisToBeVisualized(data));
    history.push(`/${data.uuid}/interactive-slide`);
  };

  const handleNextPagination = async (event) => {
    event.preventDefault();
    await fetchAllPublishedInteractiveAnalysis(+page);
  };

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await fetchAllPublishedInteractiveAnalysis(page - 2);
  };

  const handleSeachInteractiveAnalysis = (event) => {
    event.preventDefault();
    const result = interactiveAnalysis.filter(
      (data) =>
        data.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        data.description.toLowerCase().includes(searchValue.toLowerCase())
    );

    result.length > 0 ? setHasResult(true) : setHasResult(false);

    setSearchResult(result);
  };

  const advancedSearch = async (page = 0) => {
    try {
      const searchParams = {
        query: searchValue.trim(),
        page,
      };

      const validSearchKeys = [];

      for (let key in searchParams) {
        if (searchParams[key]) {
          validSearchKeys.push(`${key}=${searchParams[key]}`);
        }
      }

      await handleSearch(validSearchKeys);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  const handleAdvanceSearch = async (event) => {
    event.preventDefault();
    await advancedSearch();
  };

  const handleSearch = async (searchQuery) => {
    try {
      const data = await searchInteractivity(searchQuery);

      setPage(data.page);
      setMaxPage(data.count);
      setInteractiveAnalysis(data.allInteractivities);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  return (
    <>
      <header>
        <div className="__landbgex">
          <Nav />
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-2 col-sm-2"></div>
              <div className="col-lg-6 col-md-8 col-sm-8">
                <p className="__captionex text-center">Interactive Analysis</p>
                <p className="__captionex1 text-center">
                  This section captures interactive dashboard of different
                  sectors. Click the dropdown button to view analytics across
                  health, education and economic affairs.
                </p>
                <div className="__formdiv">
                  <form>
                    <div className="input-group">
                      <select
                        required
                        className="form-control __ekoform"
                        placeholder="Search for information"
                        onChange={(event) => {
                          setSearchValue(event.target.value);
                          // if (selected.length) setQuery(selected[0])
                        }}
                      >
                        <option value="" selected disabled>
                          Search for information
                        </option>
                        <option value="education">Education</option>
                        <option value="health">Health</option>
                        <option value="economic affairs">
                          Economic Affairs
                        </option>
                      </select>

                      <span className="input-group-btn">
                        <button
                          type="submit"
                          className="btn __ekoformbtn"
                          onClick={handleAdvanceSearch}
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-2 col-sm-2"></div>
            </div>
          </div>
        </div>
      </header>

      <section>
        <div className="interactiveAnalysis">
          {interactiveAnalysis.length > 0 &&
          searchResult.length === 0 &&
          hasResult ? (
            interactiveAnalysis.map((data) => (
              <>
                {data.type === "image" && (
                  <div
                    className="__expmiao"
                    style={{ padding: "35px 0" }}
                    key={data.uuid}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-4">
                          <div
                            className="__exmimg"
                            style={{
                              background: data?.contentUrl.startsWith("/")
                                ? `url(${rootUrl}/${data.contentUrl.substr(
                                    data.contentUrl.lastIndexOf("/") + 1
                                  )})`
                                : `url(${data.contentUrl})`,
                            }}
                          ></div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-8">
                          <p className="__examp">{data.title}</p>
                          <p className="__examp2">{data.description}</p>

                          <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-9">
                              <div className="row">
                                <div className="col-xs-6">
                                  <Link
                                    to="/interactive-animation"
                                    className="btn btn-block hvr-float-shadow __intrabtn"
                                    onClick={(event) =>
                                      handleGotoToSlide(event, data)
                                    }
                                  >
                                    Open Slide
                                  </Link>
                                </div>
                                <div className="col-xs-6">
                                  <a
                                    href=""
                                    className="btn btn-block hvr-float-shadow __intrabtn1"
                                    onClick={(event) =>
                                      handleFileDownload(event, data)
                                    }
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {data.type === "iframe" && (
                  <div
                    className="__expmiao"
                    style={{ padding: "35px 0" }}
                    key={data.uuid}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-4">
                          <div
                            className="__exmimg fix-iframe-backgroundImage"
                            style={{
                              backgroundImage: "url(/assets/img/analytics.png)",
                            }}
                          ></div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-8">
                          <p className="__examp">{data.title}</p>
                          <p className="__examp2">{data.description}</p>

                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6">
                              <div className="row">
                                <div className="col-xs-6">
                                  <Link
                                    to="/interactive-animation"
                                    className="btn btn-block hvr-float-shadow __intrabtn"
                                    onClick={(event) =>
                                      handleGotoToSlide(event, data)
                                    }
                                  >
                                    View More
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {data.type === "video" && (
                  <div
                    className="__expmia11"
                    style={{ padding: "35px 0" }}
                    key={data.uuid}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-4">
                          <video
                            width="100%"
                            height="246px"
                            style={{
                              border: "1px solid #eee",
                              borderRadius: "10px",
                            }}
                          >
                            <source
                              src={
                                data?.contentUrl.startsWith("/")
                                  ? `${rootUrl}/${data.contentUrl.substr(
                                      data.contentUrl.lastIndexOf("/") + 1
                                    )}`
                                  : `${data.contentUrl}`
                              }
                              type="video/mp4"
                              style={{ width: "100%", height: "100%" }}
                            />

                            <source
                              src={
                                data?.contentUrl.startsWith("/")
                                  ? `${rootUrl}/${data.contentUrl.substr(
                                      data.contentUrl.lastIndexOf("/") + 1
                                    )}`
                                  : `${data.contentUrl}`
                              }
                              type="video/webm"
                            />

                            <source
                              src={
                                data?.contentUrl.startsWith("/")
                                  ? `${rootUrl}/${data.contentUrl.substr(
                                      data.contentUrl.lastIndexOf("/") + 1
                                    )}`
                                  : `${data.contentUrl}`
                              }
                              type="video/ogg"
                            />
                          </video>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-8">
                          <p className="__examp">{data.title}</p>
                          <p className="__examp2">{data.description}</p>

                          <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-9">
                              <div className="row">
                                <div className="col-xs-6">
                                  <Link
                                    to=""
                                    className="btn btn-block hvr-float-shadow __intrabtn"
                                    onClick={(event) =>
                                      handleGotoToAnimation(event, data)
                                    }
                                  >
                                    Open Animation
                                  </Link>
                                </div>
                                <div className="col-xs-6">
                                  <a
                                    href=""
                                    className="btn btn-block hvr-float-shadow __intrabtn1"
                                    onClick={(event) =>
                                      handleFileDownload(event, data)
                                    }
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))
          ) : (
            <>
              {searchResult.length > 0 &&
                searchResult.map((data) => (
                  <>
                    {data.type === "image" && (
                      <div
                        className="__expmiao"
                        style={{ padding: "35px 0" }}
                        key={data.uuid}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-4">
                              <div
                                className="__exmimg"
                                style={{
                                  background: `url(${data.contentUrl})`,
                                }}
                              ></div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8">
                              <p className="__examp">{data.title}</p>
                              <p className="__examp2">{data.description}</p>

                              <div className="row">
                                <div className="col-lg-5 col-md-6 col-sm-9">
                                  <div className="row">
                                    <div className="col-xs-6">
                                      <Link
                                        to="/interactive-animation"
                                        className="btn btn-block hvr-float-shadow __intrabtn"
                                        onClick={(event) =>
                                          handleGotoToSlide(event, data)
                                        }
                                      >
                                        Open Slide
                                      </Link>
                                    </div>
                                    <div className="col-xs-6">
                                      <a
                                        href=""
                                        className="btn btn-block hvr-float-shadow __intrabtn1"
                                        onClick={(event) =>
                                          handleFileDownload(event, data)
                                        }
                                      >
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.type === "iframe" && (
                      <div
                        className="__expmiao"
                        style={{ padding: "35px 0" }}
                        key={data.uuid}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-4">
                              <div
                                className="__exmimg fix-iframe-backgroundImage"
                                style={{
                                  backgroundImage:
                                    "url(/assets/img/gallery.svg)",
                                }}
                              ></div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8">
                              <p className="__examp">{data.title}</p>
                              <p className="__examp2">{data.description}</p>

                              <div className="row">
                                <div className="col-lg-5 col-md-6 col-sm-9">
                                  <div className="row">
                                    <div className="col-xs-6">
                                      <Link
                                        to="/interactive-animation"
                                        className="btn btn-block hvr-float-shadow __intrabtn"
                                        onClick={(event) =>
                                          handleGotoToSlide(event, data)
                                        }
                                      >
                                        View More
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.type === "video" && (
                      <div
                        className="__expmia11"
                        style={{ padding: "35px 0" }}
                        key={data.uuid}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-4">
                              <video
                                width="100%"
                                height="246px"
                                style={{
                                  border: "1px solid #eee",
                                  borderRadius: "10px",
                                  backgroundImage:
                                    "url(/assets/img/gallery.svg)",
                                }}
                              >
                                <source
                                  src={
                                    data?.contentUrl.startsWith("/")
                                      ? `${rootUrl}/${data.contentUrl.substr(
                                          data.contentUrl.lastIndexOf("/") + 1
                                        )}`
                                      : `${data.contentUrl}`
                                  }
                                  type="video/mp4"
                                  style={{ width: "100%", height: "100%" }}
                                />

                                <source
                                  src={
                                    data?.contentUrl.startsWith("/")
                                      ? `${rootUrl}/${data.contentUrl.substr(
                                          data.contentUrl.lastIndexOf("/") + 1
                                        )}`
                                      : `${data.contentUrl}`
                                  }
                                  type="video/webm"
                                />

                                <source
                                  src={
                                    data?.contentUrl.startsWith("/")
                                      ? `${rootUrl}/${data.contentUrl.substr(
                                          data.contentUrl.lastIndexOf("/") + 1
                                        )}`
                                      : `${data.contentUrl}`
                                  }
                                  type="video/ogg"
                                />
                              </video>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8">
                              <p className="__examp">{data.title}</p>
                              <p className="__examp2">{data.description}</p>

                              <div className="row">
                                <div className="col-lg-5 col-md-6 col-sm-9">
                                  <div className="row">
                                    <div className="col-xs-6">
                                      <Link
                                        to=""
                                        className="btn btn-block hvr-float-shadow __intrabtn"
                                        onClick={(event) =>
                                          handleGotoToAnimation(event, data)
                                        }
                                      >
                                        Open Animation
                                      </Link>
                                    </div>
                                    <div className="col-xs-6">
                                      <a
                                        href=""
                                        className="btn btn-block hvr-float-shadow __intrabtn1"
                                        onClick={(event) =>
                                          handleFileDownload(event, data)
                                        }
                                      >
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </>
          )}

          {!hasResult && (
            <p
              style={{
                textAlign: "center",
                fontSize: "24px",
                marginTop: "1rem",
              }}
            >
              There are no search Results
            </p>
          )}

          {!showPageLoader && interactiveAnalysis.length === 0 && hasResult && (
            <div className="row" style={{ background: "#fff" }}>
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      marginTop: "2rem",
                    }}
                  >
                    There are no interactivity Analysis Data available.
                  </p>
                </div>
              </div>
            </div>
          )}

          {showPageLoader && (
            <p style={{ display: "flex", justifyContent: "center" }}>
              <img src="/assets/img/page-loader.gif" className="icon" alt="" />
            </p>
          )}
        </div>

        <div className="__expmia1">
          <div className="container">
            <hr className="__datahr" />
            <Pagination
              page={+page}
              maxPage={+maxPage}
              SIZE_PER_PAGE={+SIZE_PER_PAGE}
              handleNextPagination={handleNextPagination}
              handlePreviousPagination={handlePreviousPagination}
            />
          </div>
        </div>
        {/* <DownloadApp /> */}
      </section>
      <ToastContainer closeOnClick pauseOnHover></ToastContainer>
      <Footer />
    </>
  );
};

export default InteractivityPage;
