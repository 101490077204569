import React from "react";

import "./index.css";

const DownloadApp = () => (
  <div className="__appbgc">
    <div className="container">
      <div className="row __flexsmail">
        <div className="col-lg-7 col-md-7 col-sm-7 __mobidown">
          <p className="__appp">Our mobile app</p>
          <p className="__appp1">A data collection platform with geofencing capabilities that ensures that data collected are of optimal accuracy and transparency.</p>

          <p>
            <a href="https://play.google.com/store/apps/details?id=com.eko.eko360" target="_blank" class="btn __dbtn"><span><i class="fab fa-google-play"></i></span> Android play store</a>&nbsp;&nbsp;
            <a href="https://apps.apple.com/us/app/eko360/id1581950732" target="_blank" class="btn __dbtn"><span><i class="fab fa-apple"></i></span> Apple app store</a>
          </p>
          {/* <p className="__appp1">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p> */}

          {/* <a href="#" className="btn hvr-float-shadow __appbtn">Download</a> */}
        </div>
        <div className="col-lg-5 col-md-5 col-sm-5 __mobiup">
          <img src="/assets/img/mobile-app.png" className="__appimg" alt='' />
        </div>
      </div>
    </div>
  </div>
);

export default DownloadApp;