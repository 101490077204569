import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}/auth`;

/**
 * Registerd a new user if the credentails are valid
 * @param {{name: string, email: string, password: string, confirmPassword: string}} credentials data to register a new user
 * @returns {Promise} that will resolve to a new user object or an error object if the supplied credentials are not valid
 */
export const signupUser = async ({ name, email, password, confirmPassword: confirm_password, phone }) => {
  try {
    const {data: { data }} = await axios({
      method: 'POST',
      url: `${baseUrl}/register`,
      data: { name, email, password, confirm_password, type: 'user', phone },
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
   throw error.response.data;
  }
}
/**
 * Sends an API request to a login endpoint
 * @param {{name: string, password: string }} credentials user login credentials
 * @returns {Object} a user or and object response
 */

export const signinUser = async ({email, password}) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${baseUrl}/login`,
      data: { email, password },
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const forgotPassword = async ({email, host}) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${baseUrl}/forgot-password`,
      data: { email, host },
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const resetPassword =  async ({password, confirm_password, secret }) => {
  try {
    const { data } = await axios({
      method: 'PATCH',
      url: `${baseUrl}/reset-password`,
      data: {password, confirm_password, secret },
      headers: {'Content-Type': 'application/json'}
    });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}