import React, {useEffect} from 'react';
import {NavLink as Link, Route, useHistory} from 'react-router-dom';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from  '../../common/components/Footer';
import Legal from './component/Legal';
import Cookies from './component/Cookies';

const TermsOfUsePage = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/terms-of-use/legal');
  }, []);

  

  return (
    <>
      <header>
        <Nav/>
        
        {/* <div className="__landbgex">
          
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-1"></div>
              <div className="col-lg-8 col-md-8 col-sm-10">
                <p className="__captionex text-center">Terms of Use</p>
                <div className="__spacet4p"></div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-1"></div>
            </div>
          </div>
        </div> */}
      </header>

      <section>
      <div className='container'>
          <div className='__all-banner' style={{background: `url(/assets/img/pagesbanner.png)`}}>
            <p className="__caption-banner text-center">Privacy Policy</p>
          </div>
          <div className="__spacet5p"></div>
        </div>
        <div className="__abg">
          <div className="container">
            <div className="__term-cook-flex">
              <Link to="legal" className="__abtab" activeClassName="__abtaba"><p className="__abtp">PRIVACY</p></Link>
              <Link to="cookies" className="__abtab" activeClassName="__abtaba"><p className="__abtp">COOKIES POLICY</p></Link>
            </div>
            <div className="__spacet4p"></div>
            <Route exact path="/terms-of-use/legal" component={Legal} />
            <Route exact path="/terms-of-use/cookies" component={Cookies} />
          </div>
        </div>
        <div className="__spacet5p"></div>
      </section>
    <Footer />
  </>
  )
}

export default TermsOfUsePage;
