import React, { useState, useEffect } from "react";
import { NavLink as Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../../common/components/Footer";
import Nav from "../../common/components/Nav";
import { storeSearchParams } from "../../store/actions/actions/survey";
import WeatherSlider from "../../common/components/WeatherSlider";
import { fetchPublicPages } from "../../utils/api/cms/page";
import { errorHandler } from "../../utils/helpers/errorHandler";

import DownloadApp from "../../common/components/DownloadApp";
import { data } from "jquery";
const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));
const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [query, setQuery] = useState("");
  const [publicPages, setPublicPages] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [budgets, setBudget] = useState([]);
  const [quickReads, setQuickReads] = useState([]);

  const handleSearch = (event) => {
    event.preventDefault();

    if (!query) {
      return toast.error("The search word is required");
    }

    dispatch(storeSearchParams({ query }));
    history.push("/search-dataset");
  };

  const handleFetchPublicPages = async (page = 0) => {
    try {
      const data = await fetchPublicPages(page);

      setPublicPages(data.data.allPages);
    } catch (error) {
      errorHandler(error, toast);
    }
  };

  useEffect(() => {
    handleFetchPublicPages();
  }, []);

  useEffect(() => {
    if (publicPages) {
      const datasets = publicPages.filter((pages) => pages.type === "datasets");
      const quickReads = publicPages.filter(
        (pages) => pages.type === "quick_reads"
      );
      const budgets = publicPages.filter((pages) => pages.type === "budgets");
      const analysis = publicPages.filter((pages) => pages.type === "analysis");

      setDatasets(datasets.slice(0, 6));
      setQuickReads(quickReads.slice(0, 3));
      setBudget(budgets.slice(0, 6));
      setAnalysis(analysis.slice(0, 3));
    }
  }, [publicPages]);

  const handleFileDownload = async (event, dataset) => {
    event.preventDefault();
    try {
      const fileName = dataset.file.substr(dataset.file.lastIndexOf("/") + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/datasets/${fileName}/download/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      const blob = await res.blob();
      saveAs(blob, dataset.title);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  };

  const today = new Date();

  return (
    <div>
      <header>
        <Nav />
        <div className="__landbg">
          <div className="container">
            <div className="__captindiv">
              <p className="__caption text-center">
                Welcome to Lagos Bureau of Statistics Open Data Portal
              </p>
              <p className="__caption-sub text-center">
                What data are you looking for?
              </p>
              <div className="__formdiv">
                <form>
                  <div className="input-group">
                    <select
                      required
                      className="form-control __ekoform"
                      placeholder="Search for information"
                      onChange={(event) => {
                        setQuery(event.target.value);
                      }}
                    >
                      <option value="" selected disabled>
                        Search for information
                      </option>
                      <option value="Education">Education</option>
                      <option value="Health">Health</option>
                      <option value="Economic Affairs">Economic Affairs</option>
                    </select>
                    <span className="input-group-btn">
                      <button
                        className="btn __ekoformbtn"
                        type="button"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </span>
                  </div>
                </form>

                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section>
        {datasets.length > 0 && (
          <div
            className="__datasetbg"
            style={{ background: `url(/assets/img/latestbg.png)` }}
          >
            <div className="container">
              <p className="__quicp">Latest Datasets</p>
              <div className="__quipdiv">
                <p className="__latst1">
                  These shows the latest datasets from health, education and
                  economic affairs. Click “download dataset” to get the
                  corresponding data associated with each dataset
                </p>
              </div>

              <div className="row">
                {datasets.length > 0
                  ? datasets.map((dataset) => (
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className=" __wokin">
                          <div
                            className="__latimg"
                            style={{
                              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), url(${dataset?.banner})`,
                            }}
                          ></div>
                          <div className="__latestcard">
                            <p className="__latimgp">{dataset.title}</p>
                            {dataset.body.length > 700 ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${dataset.body.substr(0, 700)}...`,
                                }}
                                style={{ flex: 1 }}
                              ></div>
                            ) : (
                              <div
                                className="__setpar"
                                dangerouslySetInnerHTML={{
                                  __html: dataset.body,
                                }}
                                style={{ flex: 1 }}
                              ></div>
                            )}
                            <div className="__morbtn">
                              {dataset.file && (
                                <div
                                  className="__btnbloc"
                                  onClick={(event) =>
                                    handleFileDownload(event, dataset)
                                  }
                                >
                                  <a
                                    href="#"
                                    className="btn hvr-float-shadow __latbtn1"
                                    style={{ alignSelf: "flex-ends" }}
                                  >
                                    {" "}
                                    <img src="/assets/img/datadownload.png" />{" "}
                                    Download Dataset
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <div className="__viewdiv">
                <Link className="btn btn-block __quicp1 " to="/datasetspage">
                  View all
                </Link>
              </div>
            </div>
          </div>
        )}

        {analysis.length > 0 && (
          <div className="__quickbg">
            <div className="container">
              <p className="__quicp">Interactive Analysis</p>
              <div className="__quipdiv">
                <p className="__latst1">
                  This is an interactive dashboard that visualizes different
                  analysis and insights on various Lagos State data across
                  health, education and economic affairs sectors.
                </p>
              </div>
              <div className="row">
                {analysis.length > 0
                  ? analysis.map((data) => (
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="__intracard">
                          <div
                            className="__quicimg"
                            style={{ background: `url(${data?.banner})` }}
                          ></div>
                          <div className="__quicdiv">
                            <p className="__quicp2 text-center1">
                              {data.title}
                            </p>
                            {data.body.length > 2500 ? (
                              <p className="__quicp3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${data.body.substr(0, 2500)}...`,
                                  }}
                                ></div>
                              </p>
                            ) : (
                              <p className="__quicp3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.body,
                                  }}
                                ></div>
                              </p>
                            )}
                            <div className="__morbtn">
                              {data.iFrame && (
                                <div className="__btnbloc" onClick={null}>
                                  <a
                                    href={`${data.iFrame}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn hvr-float-shadow __quicbtn"
                                    style={{ alignSelf: "flex-ends" }}
                                  >
                                    {" "}
                                    <img src="/assets/img/view_list.png" /> View
                                    Dashboard
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <div className="__viewdiv">
                <Link className="btn btn-block __quicp1 " to="/analysispage">
                  View all analysis
                </Link>
              </div>
            </div>
          </div>
        )}

        {budgets.length > 0 && (
          <div
            className="__invdiv"
            style={{ background: `url(/assets/img/budgetbg.png)` }}
          >
            <div className="container">
              <p className="__quicp">
                Year {today.getFullYear()} Lagos State Comprehensive Budget
              </p>

              <div className="row">
                {budgets.length > 0
                  ? budgets.map((budget, index) => (
                      <div className="col-xl-6 col-lg-6">
                        <div className="__budgcard">
                          <div className="row __flexsmail">
                            {budget?.banner && index % 2 === 0 && (
                              <div className="col-xl-6 col-lg-6 col-md-6 __mobiup">
                                <img
                                  className="__budgimg"
                                  src={
                                    budget?.banner.startsWith("/")
                                      ? `${rootUrl}/${budget?.banner.substr(
                                          budget?.banner.lastIndexOf("/") + 1
                                        )}`
                                      : `${budget.banner}`
                                  }
                                />
                              </div>
                            )}
                            <div className="col-xl-6 col-lg-6 col-md-6 __mobidown">
                              <div className="">
                                {/* <div className='' style={{ textAlign: index%2 === 0? 'right':"left" }}> */}
                                <p className="__mewbudp-title">
                                  {budget.title}
                                </p>
                                <p
                                  className="__mewbudp"
                                  dangerouslySetInnerHTML={{
                                    __html: budget.budget,
                                  }}
                                ></p>
                              </div>
                            </div>
                            {budget?.banner && index % 2 !== 0 && (
                              <div className="col-xl-6 col-lg-6 col-md-6 __mobiup">
                                <img
                                  className="__budgimg"
                                  src={
                                    budget?.banner.startsWith("/")
                                      ? `${rootUrl}/${budget?.banner.substr(
                                          budget?.banner.lastIndexOf("/") + 1
                                        )}`
                                      : `${budget.banner}`
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        )}

        <div className="__shabg">
          <div className="container">
            <p className="__quicp">Share Datasets</p>
            <div className="__quipdiv">
              <p className="__latst1">
                Do you have datasets you would like to share with the public?
                Please click the button below to upload your data.
              </p>
            </div>
            <div className="row">
              <div className="col-xl-1 col-lg-1 col-md-1"></div>
              <div className="col-xl-10 col-lg-10 col-md-10">
                <img className="__shaimg" src="/assets/img/shareimg.png" />
              </div>
              <div className="col-xl-1 col-lg-1 col-md-1"></div>
            </div>
            <div className="__viewdiv">
              <Link className="btn btn-block __quicp1 " to="/submit-dataset">
                Upload Now
              </Link>
            </div>
          </div>
        </div>

        {quickReads.length > 0 && (
          <div className="__quickbg">
            <div className="container">
              <div className="row">
                <div className="col-xs-8">
                  <p className="__quicp">Quick Reads</p>
                </div>
                <div className="col-xs-4">
                  <p className="__quicp1 text-right">
                    <Link to="/public">View all</Link>
                  </p>
                </div>
              </div>
              <div className="row">
                {quickReads.length > 0
                  ? quickReads.map((quickRead) => (
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <div
                          className="__quicimg"
                          style={{ background: `url(${quickRead?.banner})` }}
                        ></div>
                        <div className="__quicdiv">
                          <p
                            className="__quicp2"
                            style={{ textTransform: "uppercase" }}
                          >
                            {quickRead.title}
                          </p>
                          {quickRead.body.length > 150 ? (
                            <p className="__quicp4">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${quickRead.body.substr(0, 200)}...`,
                                }}
                              ></div>
                            </p>
                          ) : (
                            <p className="__quicp4">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: quickRead.body,
                                }}
                              ></div>
                            </p>
                          )}
                          <Link
                            to={`/pages/${quickRead.slug}`}
                            className="btn btn-block hvr-float-shadow __quicbtn"
                          >
                            Read Now
                          </Link>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        )}

        <WeatherSlider />

        {/* <DownloadApp /> */}
      </section>

      <ToastContainer closeOnClick pauseOnHover></ToastContainer>

      <Footer />
    </div>
  );
};

export default HomePage;
