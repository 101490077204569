import React, { useEffect, useState } from 'react';
import {NavLink as Link, useHistory} from 'react-router-dom';
import {useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { fetchAllDataset } from '../../utils/api/dataset';
import { setSelectedDataSet } from '../../store/actions/actions/dataset';

import './dashboard.css';
import SideMenu from '../common/SideMenu.jsx';
import UploadComponent from '../common/Upload.jsx';

import { fetchAllMetrics } from '../../utils/api/metrics';
import { storeMetrics } from '../../store/actions/actions/metrics'
import { setAllDatasets } from '../../store/actions/actions/dataset';
// import UserAvatar from '../common/UserAvatar.jsx';
import DashboardNav from './component/DashboardNav';
import { errorHandler } from "../../utils/helpers/errorHandler";
import Pagination from "../../common/components/Pagination";

const Index = () => {
  const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

    
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("eko-360-p2-token")); 
  const interactiveAnalysisFromTheStore = useSelector(state => state.interactiveAnalysis.interactive_analysis);
  const metrics = useSelector(state => {
    return state.metrics.metrics;
  });
  const dataset = useSelector(state => state.dataset.dataset);
  
  const dispatch = useDispatch();
  
  const [datasets, setDatasets ] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [datasetCount, setDatasetCount] = useState(0);
  const [publicationCount, setPublicationCount] = useState(0);
  const [interactivityCount, setInteractivityCount] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(SIZE_PER_PAGE);

  const handleFetchMetrics = async () => {
    try {
      const data = await fetchAllMetrics(token);

      dispatch(storeMetrics(data));
      setUserCount(data.usersCount);
      setDatasetCount(data.datasetCount);
      setPublicationCount(data.publicationCount);
      setInteractivityCount(data.interactivityCount);
    } catch (error) {
      errorHandler(error, toast);
    }
  }
    
  const fetchDataset = async (page) => {
    try {
      const data = await fetchAllDataset(token, page);
      
      if (data.statusCode === 401) {
        setTimeout(() => {
          toast.error("Your Token has Expired please login to continue");
        }, 200);
        localStorage.removeItem('eko-360-p2-user');
        history.push("/login");
      } 

      setPage(data.page);
      setMaxPage(data.count);
      setDatasets(data.allDatasets);
      dispatch(setAllDatasets(data.allDatasets));
    } catch (error) {
      errorHandler(error, toast);
    }
  }
  
  useEffect(() => {
    if(metrics) {
      setUserCount(metrics?.usersCount);
      setDatasetCount(metrics?.datasetCount);
      setPublicationCount(metrics?.publicationCount);
      setInteractivityCount(metrics?.interactivityCount);
    }
  }, [metrics]);

  useEffect(() => {
    window.$('#openSidebar').click(function(){
      window.$('.leftbar').addClass('biger');
      });
      window.$('#closebtn').click(function(){
          window.$('.leftbar').removeClass('biger');
      });
  },[])

  useEffect(() => {
    handleFetchMetrics();
  }, []);

  useEffect(() => {
    if(dataset && dataset.length <= 1) {
      fetchDataset();
    }
  },[]);

  useEffect(() => {
    if(dataset && dataset.length > 0) {
      setDatasets(dataset);
    }
  },[dataset]);

  const handleUpdateDataset = (data) => {
    dispatch(setSelectedDataSet(data));
  }

  const handleSearch = (event) => {
    event.preventDefault();

    let searchWord = event.target.value.toLowerCase();
    const datasetClone = [...dataset];
    const searchResult = datasetClone.filter(dataset => dataset.name.toLowerCase().includes(searchWord));

    setSearchResult(searchResult);
  }

  const handleNextPagination = async (event) => {
    event.preventDefault();
    await fetchDataset(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await fetchDataset(page - 2);
  }

  const title = "Overview";

  return (
    <>
      <div className='section body'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-2'>
              <SideMenu />
            </div>

            <div className='col-md-10'>
              <DashboardNav 
                page={title}
              />
                {/* <nav class='navbar navbar-expand-lg navbar-dark __dashnavbar'>
                    <div class='container'>
                        <a
                        href='#'
                        className='navbar-brand __navbar-brand1'
                        id='openSidebar'>
                        <i
                            className=' fa fa-bars'
                            ></i>
                        <span className='hidden-sm'>
                            Overview
                        </span>
                        </a>
                        <p className='__dasovp'>{title}</p>
                        <button class='navbar-toggler' type='button' data-bs-toggle="collapse" data-bs-target="#navmenu">
                            <span class='navbar-toggler-icon'></span>
                        </button>

                        <div class='collapse navbar-collapse' id='navmenu'>
                            <ul class="navbar-nav ms-auto">
                            <UserAvatar />
                            </ul>
                        </div>
                    </div>
                </nav> */}
              {/* <nav className='navbar __dashnavbar' role='navigation'>
                <div className='container-fluid'>
                  <div className='navbar-header'>
                    <button
                      type='button'
                      className='navbar-toggle'
                      data-toggle='collapse'
                      data-target='.navbar-ex1-collapse'>
                      <span className='sr-only'>
                        Toggle navigation
                      </span>
                      <span className='icon-bar'></span>
                      <span className='icon-bar'></span>
                      <span className='icon-bar'></span>
                    </button>
                    <a
                      href='#'
                      className='navbar-brand __navbar-brand1'
                      id='openSidebar'>
                      <i
                        className=' fa fa-bars'
                        ></i>
                      <span className='hidden-sm'>
                         Overview
                      </span>
                    </a>
                  </div>

                  <div className='collapse navbar-collapse navbar-ex1-collapse'>
                    <ul className='nav navbar-nav'>
                      <p className='__dasovp'>Overview</p>
                    </ul>
                    <ul className='nav navbar-nav navbar-right'>
                      <UserAvatar />
                    </ul>
                  </div>
                </div>
              </nav> */}

              <section>
                <div className='__house'>
                  <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-3'>
                      <a>
                        <div className='__dascard text-center'>
                          <p className='__dasp'>
                            Datasets
                          </p>
                          <p className='__dasp1'>
                            {datasetCount || 0}
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3'>
                      <a>
                        <div className='__dascard text-center'>
                          <p className='__dasp'>
                            Interactive analysis
                          </p>
                          <p className='__dasp1'>
                            {interactivityCount || 0}
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3'>
                      <a>
                        <div className='__dascard text-center'>
                          <p className='__dasp'>
                            Publications
                          </p>
                          <p className='__dasp1'>
                            {publicationCount || 0}
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3'>
                      <a>
                        <div className='__dascard text-center'>
                          <p className='__dasp'>Users</p>
                          <p className='__dasp1'>
                            {userCount || 0}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className='__btcard'>
                    <div className='__btcardin'>
                      <div className='row'>
                        <div className='col-lg-9 col-md-8 col-sm-8'>
                          <p className='__btp'>
                            Submitted datasets
                          </p>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4'>
                          <form>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control __btform'
                                placeholder='Search by name'
                                onChange={handleSearch}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table __table table-striped'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Submitted date</th>
                            <th>Owner</th>
                            <th>File</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          { (datasets.length > 0 && searchResult.length === 0) ? (datasets.map(dataset => (
                            
                              <tr onClick={() => handleUpdateDataset(dataset)} key={dataset.id}>
                                <td>
                                  <span>Name</span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    <p className='__tablp'>
                                      <img
                                        src='/assets/img/clock.png'
                                        className='__tabimg'
                                        alt=''
                                      />
                                      &nbsp;&nbsp;{dataset.name}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span>
                                    Submitted date
                                  </span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    <p className='__tablp'>
                                      {moment(dataset.createdAt).format('LLLL')}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span>Owner</span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    <p className='__tablp'>
                                      <img
                                        src='/assets/img/clock.jpg'
                                        className='__tabimg2'
                                        alt=''
                                      />
                                      &nbsp;&nbsp;{dataset.email}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span>File format</span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    <p className='__tablp1'>
                                      <img
                                        src='/assets/img/attachment.png'
                                        className='__tabimg1'
                                        alt=''
                                      />
                                      &nbsp;&nbsp;
                                      {dataset.type}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span>Status</span>
                                  <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                    {dataset.status === 'draft' && (<p
                                      className='__allstatus btn'
                                      style={{ color: '#464646', background: '#F0F0F0' }}>draft
                                    </p>)}

                                    {dataset.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{dataset.status}</p>)}

                                    {dataset.status === 'declined' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Rejected</p>)}
                                  </Link>
                                </td>
                              </tr>
                          ))): (
                            searchResult.map(dataset => (
                              <tr onClick={() => handleUpdateDataset(dataset)} key={dataset.id}>
                              <td>
                                <span>Name</span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  <p className='__tablp'>
                                    <img
                                      src='/assets/img/clock.png'
                                      className='__tabimg'
                                      alt=''
                                    />
                                    &nbsp;&nbsp;{dataset.name}
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <span>
                                  Submitted date
                                </span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  <p className='__tablp'>
                                    {moment(dataset.createdAt).format('LLLL')}
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <span>Owner</span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  <p className='__tablp'>
                                    <img
                                      src='/assets/img/clock.jpg'
                                      className='__tabimg2'
                                      alt=''
                                    />
                                    &nbsp;&nbsp;{dataset.email}
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <span>File format</span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  <p className='__tablp1'>
                                    <img
                                      src='/assets/img/attachment.png'
                                      className='__tabimg1'
                                      alt=''
                                    />
                                    &nbsp;&nbsp;
                                    {dataset.type}
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <span>Status</span>
                                <Link to={`/admin/dashboard/${dataset.uuid}/over-view`}>
                                  {dataset.status === 'draft' && (<p
                                    className='__allstatus btn'
                                    style={{ color: '#464646', background: '#F0F0F0' }}>draft
                                  </p>)}

                                  {dataset.status === 'published' && (<p className="__allstatus btn" style={{ color: '#0C9EA9', background: '#E6FDFF' }}>{dataset.status}</p>)}

                                  {dataset.status === 'declined' && (<p className="__allstatus btn" style={{ color: '#EE7000', background: '#FFE8D4' }}>Rejected</p>)}
                                </Link>
                              </td>
                            </tr>
                          )))}
                        </tbody>
                      </table> 
                      { datasets.length === 0 && 
												<p style={{textAlign: "center" }}>There are no Datasets yet</p>}
                    </div>

                    <Pagination
                      page={+page}
                      maxPage={+maxPage}
                      SIZE_PER_PAGE={+SIZE_PER_PAGE}
                      handleNextPagination={handleNextPagination}
                      handlePreviousPagination={handlePreviousPagination}
                    />
                  </div>

                  <div className='__spacet7p'></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <UploadComponent />

      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
    </>
  )
}

export default Index;
