import React, { useEffect, useState } from 'react';
import { NavLink as Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { saveAs } from 'file-saver';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { findPublishedPublication } from "../../utils/api/publication";
import { errorHandler } from "../../utils/helpers/errorHandler";
import { setPublicationToBeVisualized } from "../../store/actions/actions/publication";
import Pagination from "../../common/components/Pagination";
import DownloadApp from '../../common/components/DownloadApp';

const PublicationsPage = () => {
  const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;
  const SIZE_PER_PAGE = `${process.env.REACT_APP_SIZE_PER_PAGE}` === "undefined"
    ? 20 : `${process.env.REACT_APP_SIZE_PER_PAGE}`;

  const history = useHistory(),
        dispatch = useDispatch();
  
  const [page, setPage] = useState(0),
        [maxPage, setMaxPage] = useState(SIZE_PER_PAGE),
        [publications, setPublications] = useState([]),
        [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    handleFetchPublishedPublication();
  }, []);
  const handleFetchPublishedPublication = async (page = 0) => {
    setShowPageLoader(true);
    try {
      const data = await findPublishedPublication(page);

      setPage(data.page);
      setMaxPage(data.count);
      setPublications(data.allPublications);
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      errorHandler(error, toast);
    }
  }

  const handleFileDownload = async (file) => {
    try {
      const fileName = file.substr(file.lastIndexOf('/')+1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/publications/${fileName}/download/`,
        {
          headers: { 'Content-Type': 'application/json' }
      });

      const blob = await res.blob()
      saveAs(blob, fileName);
    } catch (error) {
      errorHandler(error, toast);
    }
  }

  const handleVisualizePublication = (event, data) => {
    event.preventDefault();
    dispatch(setPublicationToBeVisualized(data));
    history.push(`/publications/${data.uuid}/visualise`);
  }

  const handleViewPublication = (data) => {
    dispatch(setPublicationToBeVisualized(data));
    history.push(`/publications/${data.uuid}/view`);
  }

  const handleNextPagination = async (event) => {
    event.preventDefault();
    await handleFetchPublishedPublication(+page);
  }

  const handlePreviousPagination = async (event) => {
    event.preventDefault();
    await handleFetchPublishedPublication(page - 2);
  }

  return (
    <>
      <header>
        <Nav/>
        
      {/* <div className="__landbgex">
        
        
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-2 col-sm-1"></div>
            <div className="col-lg-6 col-md-8 col-sm-10">
              <p className="__captionex text-center">Our Publications</p>
              <p className="__captionex1 text-center">Go through our rich library of publications of different activities relating to Ministry of Economic Planning and Budget</p>
            </div>
            <div className="col-lg-3 col-md-2 col-sm-1"></div>
          </div>
        </div>
      </div> */}
    </header>

    <section>

    <div className='container'>
          <div className='__all-banner' style={{background: `url(/assets/img/pagesbanner.png)`}}>
            <p className="__caption-banner text-center">Publications</p>
          </div>
          
          <div className="__spacet5p"></div>
          <div className='__quipdiv'>
            <p className="__latst1">Go through our rich library of publications of different activities relating to Ministry of Economic Planning and Budget</p>
          </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <img src="/assets/img/sideimg1.png" className="__latflotimg hidden-xs" alt="" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 text-right">
            <img src="/assets/img/sideimg.png" className="__latflotimg1 hidden-xs" alt="" />
          </div>
        </div>

        {/* <div className="row">
          <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10"> */}
            <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
              {publications.length > 0 && !showPageLoader? publications.map(publication => (
              <div
                className="col-lg-4 col-lg-4 col-md-6 col-sm-6"
                style={{
                  marginBottom: "1rem",
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: 370
                }} 
                key={publication.uuid}
              >
                <div className='__pupli'>
                  <div
                    className="__latimg"
                    style={{
                      background: publication?.banner.startsWith("/") ? `linear-gradient(360deg, #175C7D 0%, rgba(255, 255, 255, 0) 100%), url(${rootUrl}/${publication.banner.substr(publication.banner.lastIndexOf('/')+1)})` : `linear-gradient(360deg, #175C7D 0%, rgba(255, 255, 255, 0) 100%), url(${publication.banner})`,
                      cursor: "pointer"
                    }}
                    onClick={() => handleViewPublication(publication)}
                    >
                    
                  </div>
                  <div className='__latestcard'>
                    <p className="__latimgp">{publication.title}</p>
                    <p
                    className="__latp"
                    style={{
                      flex: 1,
                    }}>
                      {publication.body.length > 300 ? `${publication.body.substr(0, 300)}...` : publication.body }
                    </p>

                      {publication.file &&
                            <a
                              href={publication?.file.startsWith('/') ? `${rootUrl}/${publication.file.substr(publication.file.lastIndexOf('/')+1)}` : `${publication.file}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn hvr-float-shadow __latbtn"
                            >
                              Preview file
                            </a>}
                          {/* {publication.iframe && <div className="col-xs-6">
                            <Link
                              to={''}
                              className="btn btn-block hvr-float-shadow __latbtn1"
                              onClick={event => handleVisualizePublication(event, publication)}
                            >
                              Visualize Data
                            </Link>
                          </div>} */}
                      
                  </div>
                </div>
              </div>)): (
                <>
                  {!showPageLoader && (
                    <div className="container">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p style={{ fontSize: 24, textAlign: "center" }}>There are no publications available</p>
                      </div>
                    </div>
                  )}
                </>
              )}

              {showPageLoader && (
                <div className="container">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p style={{ display: "flex", justifyContent: "center"}}>
                      <img src="/assets/img/page-loader.gif" className="icon" alt="" />
                    </p>
                  </div>
                </div>
              )}
            </div>
          {/* </div>
          <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
        </div> */}
      </div>
      <div className="__expmia1i">
          <div className="container">
            <Pagination
              page={+page}
              maxPage={+maxPage}
              SIZE_PER_PAGE={+SIZE_PER_PAGE}
              handleNextPagination={handleNextPagination}
              handlePreviousPagination={handlePreviousPagination}
            />
          </div>
        </div>
      <div className="__spacet5p"></div>
      {/* <DownloadApp /> */}
    </section>
      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
      <Footer />
    </>
  )
}

export default PublicationsPage;