import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { saveAs } from 'file-saver';

// import Header from '../../../common/components/Header';
import Nav from '../../../common/components/Nav';
import Footer from '../../../common/components/Footer';
import { errorHandler } from '../../../utils/helpers/errorHandler';
import { toast } from 'react-toastify';

import { findDataset } from "../../../utils/api/dataset";

const token = JSON.parse(localStorage.getItem("eko-360-p2-token"));

const PageDetails = () => {
	const { uuid } = useParams();

	const [publicDataset, setPublicDataset] = useState(undefined);

	const handleFetchPublicDataset = async () => {
		try {
			const data = await findDataset(uuid);
			console.log("WHAT DO WE HAVE IN DETAIL ------------->", data);

			setPublicDataset(data);
		} catch (error) {
			errorHandler(error, toast);
		}
	}

	useEffect(() => {
		handleFetchPublicDataset()
	}, []);

	const handleFileDownload = async (event, dataset) => {
		event.preventDefault();
    try {
      const fileName = dataset.file.substr(dataset.file.lastIndexOf('/') + 1);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/datasets/${fileName}/download/`,
        {
          headers: {
            'Content-Type': 'application/json', Authorization: `${token}`
          }
        });

      const blob = await res.blob()
      saveAs(blob, fileName);
    } catch (error) {
      return toast.error(`An unexpected error just occured ${error.message}`);
    }
  }

	return (
		<>
			<header>
        {/* <div className="__landbgex"> */}
          <Nav/>
          	<div className="container">
				<div className='__all-banner' style={{background: `url(/assets/img/pagesbanner.png)`}}>
				<p className="__caption-banner text-center">{(publicDataset && publicDataset?.title) ? publicDataset?.title : 'Public Datasets'}</p>
				</div>
				<div className="__spacet3p"></div>
			</div>
            {/* <div className="row">
              <div className="col-lg-3 col-md-2 col-sm-1"></div>
              <div className="col-lg-6 col-md-8 col-sm-10">
                <p className="__captionex text-center">{(publicDataset && publicDataset?.title) ? publicDataset?.title : 'Public Datasets'}jhjhv</p>
              </div>
              <div className="col-lg-3 col-md-2 col-sm-1"></div>
            </div>
          </div> */}
        {/* </div> */}
      </header>

			<section>

				<div className="__abg">
					<div className="container">
						<div className="__spacet3p"></div>
						<div className="row">
							{publicDataset?.file && (<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-2">
								<a
									href="#"
									className="btn btn-block hvr-float-shadow __latbtn1"
									style={{ alignSelf: 'flex-ends', marginBottom: 20 }}
									onClick={(event) => handleFileDownload(event, publicDataset)}
								>Download Dataset</a>
							</div>)}
						</div>
						<div className="row">
							<div className="col-lg-1"></div>
							<div className="col-lg-12" style={{ minHeight: "250px" }}>
								<div className="row" style={{ display: "flex" }}>
									<div style={{ width: "50%", padding: "10px", flex: "1" }}>

										{publicDataset?.description && (<div
											dangerouslySetInnerHTML={{ __html: publicDataset?.description }}
											style={{
												fontSize: "13px",
												lineHeight: "1.1",
												color: "#000000",
												marginBottom: "30px",
											}}
										></div>)}

										 {publicDataset?.type === 'link' && (
                      <iframe
                        className="__ifrmv"
                        src={publicDataset?.file}
                        frameBorder="0"
                        allowFullScreen="true"
                        title="powerBi"
                      ></iframe>
                    )}
									</div>
								</div>
								<div className="__spacet7p"></div>
							</div>
							<div className="col-lg-1"></div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}

export default PageDetails;