import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';

import {NavLink as Link, Route } from 'react-router-dom';

import FetchedPublicDatasets from "./datasetAndPagesTabContent/publicDataset";
// import FetchedPublicPages from './datasetAndPagesTabContent/publicPages';

import "./page.css";
 

const PublicPage = () => {

  return(
    <>
      <header>
      <Nav/>
      </header>

      <section>
        
      <div className='container'>
			  <div className='__all-banner' style={{background: `url(/assets/img/pagesbanner.png)`}}>
            <p className="__caption-banner text-center">Public Data</p>
            </div>
            <div className="__spacet3p"></div>
        </div>
        <div className="__abg">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
              {/* <p className='__abtab'>PUBLIC DATASETS</p> */}
              {/* <p><Link exact to="/public/datasets" className="__abtab" activeClassName="__abtaba">PUBLIC DATASETS</Link></p> */}
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 col-6">
                    {/* <p><Link exact to="/public" className="__abtab" activeClassName="__abtaba">VISUALIZING DATA remove</Link></p> */}
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 col-6">
                    {/* <p><Link to="/public" className="__abtab" activeClassName="__abtaba">PUBLIC DATASETS</Link></p> */}
                  </div>
                </div>

                

                <Route exact path="/public/datasets" component={FetchedPublicDatasets} />
                {/* <Route exact path="/public" component={FetchedPublicPages} /> */}

                <div className="__spacet5p"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="__spacet7p"></div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default PublicPage;