import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const createSection = async (section, token) => {
  try {
    const { data } = await axios(`${baseUrl}/resources/sections`,{
      method: 'POST',
      data: section,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllSections = async (page = 0) => {
  try {
    const { data: {data} } = await axios(`${baseUrl}/resources/sections?page=${page}`,{
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    });

    return data;

  } catch (error) {
    throw error.response.data;
  }
}

export const updateSection = async (section, uuid, token) => {
   try {
    const { data } = await axios(`${baseUrl}/resources/sections/${uuid}`,{
      method: 'PUT',
      data: section,
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data;

  } catch (error) {
    throw error.response.data;
  }
}

export const deleteSection = async (uuid, token) => {
   try {
    const { data } = await axios(`${baseUrl}/resources/sections/${uuid}`,{
      method: 'DELETE',
      headers: {'Content-Type': 'application/json', 'authorization': `${token}`}
    });

    return data;

  } catch (error) {
    throw error.response.data;
  }
}