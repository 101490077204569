import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { saveAs } from 'file-saver';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './InteractiveSlide.css';
// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';

import { errorHandler } from "../../utils/helpers/errorHandler";
import { findInteractiveAnalysis } from "../../utils/api/interactiveAnalysis";
import { publicInteractiveAnalysisToBeVisualized } from "../../store/actions/actions/interactiveAnalysis";

const rootUrl = `${process.env.REACT_APP_ROOT_URL}`;

const InteractivitySlidePage = () => {
  const dispatch = useDispatch();
  const publicInteractiveAnalysisToVisualize = useSelector(
    state => state.interactiveAnalysis.public_interactive_analysis_to_be_visualized
  );

  const { uuid } = useParams();

  const [slides, setSlides] = useState([]);
  const [fetchedSlide, setFetchedSlide] = useState(undefined);

  const handleFindInteractiveAnalysis = async () => {
    try {
      const data = await findInteractiveAnalysis(uuid);

      dispatch(publicInteractiveAnalysisToBeVisualized(data));
      setFetchedSlide(data);

      const fetchedSlides = data.contentUrl.split(",");
      setSlides(fetchedSlides);
    } catch (error) {
      errorHandler(error, toast);
    }
  }
  
  useEffect(() => {
    handleFindInteractiveAnalysis();
  }, []);

  useState(() => {
    if(publicInteractiveAnalysisToVisualize) {
      const slides = publicInteractiveAnalysisToVisualize.contentUrl.split(",");
      setSlides(slides);
    }
  }, [publicInteractiveAnalysisToVisualize]);
  
  useEffect(() => {
    window.$(() => {
      let nsOptions = {
        sliderId: "ninja-slider",
        transitionType: "slide", //"fade", "slide", "zoom", "kenburns 1.2" or "none"
        autoAdvance: false,
        delay: 2000,
        transitionSpeed: 1000,
        aspectRatio: "1000:424",
        initSliderByCallingInitFunc: false,
        shuffle: false,
        startSlideIndex: 0, //0-based
        navigateByTap: true,
        pauseOnHover: true,
        keyboardNav: true,
        before: null,
        license: ""
      };
    
      const nslider = new window.NinjaSlider(nsOptions);
    });
  },[]);

  const handleFileDownload = async (event, interactiveAnalysisData) => {
		event.preventDefault();
		try {
			let files;

			if (interactiveAnalysisData.contentUrl.includes(",")) {
				files = interactiveAnalysisData.contentUrl
				.split(",")
				.map(file => file.substr(file)).join("+");
			} else {
				files = interactiveAnalysisData?.contentUrl.startsWith('/') ? interactiveAnalysisData.contentUrl.substr(interactiveAnalysisData.contentUrl.lastIndexOf('/')+1) : interactiveAnalysisData.contentUrl;
			}

			const res = await fetch(`${process.env.REACT_APP_BASE_URL}/interactivities/${files}/download/`);

			
			const blob = await res.blob();
			const name = interactiveAnalysisData.title.split(" ").join("-");
			saveAs(blob, `${name}`);
		} catch (error) {
			errorHandler(error, toast);
		}
	}


  return (
    <>
      <header className="__nehead">
        <Nav/>
      </header>

      <section>
           {(slides.length && fetchedSlide?.type !== "iframe") &&  (
           <>
           <div id="ninja-slider">
                <div className="slider-inner">
                    <ul>
                      {slides.length && slides.map((slide, idx)=> (
                          <li key={idx}>
                            <img
                              className="ns-img"
                              src={slide.startsWith('/') ? `${rootUrl}/${slide.substr(slide.lastIndexOf('/')+1)}` : `${slide}`}
                              alt=""
                            />
                          </li>
                        ))
                      }
                    </ul>
                    <div id="current-slide-num" className="__num"></div>
                </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-1 col-md-1 col-sm-1"></div>
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <div className="__assdiv text-right">
                    <a
                      href=""
                      className="btn __intbtn1"
                      onClick={event => handleFileDownload(event, publicInteractiveAnalysisToVisualize)}
                    >Download</a>
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1"></div>
              </div>
            </div>
            </>)}

            {fetchedSlide?.type === "iframe" && (
               <iframe
                src={fetchedSlide.contentUrl}
                frameBorder="0"
                allowFullScreen="true"
                className="__ifrmv  __iftop"
                title="powerBi"
              ></iframe>
            )}
        </section>
        <ToastContainer
          closeOnClick
          pauseOnHover
        ></ToastContainer>
        <Footer />
    </>
  )
}

export default InteractivitySlidePage;