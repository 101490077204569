import React, { useEffect } from "react";
import { NavLink as Link, useHistory } from "react-router-dom";

const SideMenu = () => {
  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  useEffect(() => {
    window.$(document).ready(function () {
      window.$("#showbutton").click(function () {
        window.$("#showing").slideToggle("slow");
      });
    });

    window.$(document).ready(function () {
      window.$("#showbuttonup").click(function () {
        window.$("#showingup").slideToggle("slow");
      });
    });
  }, []);

  return (
    <>
      <div className="leftbar">
        <img src="/assets/img/cross-out.svg" id="closebtn" alt="" />
        <div className="__sidep">
          <div className="__dashlogoimg">
            <img
              src="/assets/img/logo1.png"
              className="__sidimg"
              alt=""
              onClick={goHome}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Link
            to="/admin/dashboard"
            exact
            className="__dashboard"
            activeClassName="activedashboard"
          >
            <p>
              <img
                src="/assets/img/overview.png"
                className="__leftbarimg"
                alt=""
              />
              &nbsp;&nbsp;&nbsp;Home
            </p>
          </Link>

          <Link
            to="/admin/dashboard/submissions"
            className="__dashboard"
            activeClassName="activedashboard"
          >
            <p>
              <img
                src="/assets/img/draft.png"
                className="__leftbarimg"
                alt=""
              />
              &nbsp;&nbsp;&nbsp;Datasets
            </p>
          </Link>

          <Link
            to="/admin/dashboard/users"
            className="__dashboard"
            activeClassName="activedashboard"
          >
            <p>
              <img
                src="/assets/img/usersad.png"
                className="__leftbarimg"
                alt=""
              />
              &nbsp;&nbsp;&nbsp;Users
            </p>
          </Link>

          <Link
            to="/admin/dashboard/publications"
            className="__dashboard"
            activeClassName="activedashboard"
          >
            <p>
              <img
                src="/assets/img/publication.png"
                className="__leftbarimg"
                alt=""
              />
              &nbsp;&nbsp;&nbsp;Publications
            </p>
          </Link>

          <a href="#" className="dropdown">
            <p
              className="dropdown-toggle"
              style={{
                paddingLeft: "30px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
              type="button"
              data-toggle="dropdown"
              id="showbutton"
            >
              <img
                src="/assets/img/intaractivead.png"
                className="__leftbarimg"
                alt=""
              />{" "}
              &nbsp;&nbsp;&nbsp;CMS
              <span className="caret"></span>
            </p>
            <ul
              className="dropdown-menu"
              style={{ marginLeft: "30px", marginTop: "-26px" }}
              id="showing"
            >
              <li>
                <Link
                  to="/admin/resources"
                  className="__dashboard"
                  activeClassName="activedashboard"
                >
                  Pages
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/sections"
                  className="__dashboard"
                  activeClassName="activedashboard"
                >
                  Sections
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/team"
                  className="__dashboard"
                  activeClassName="activedashboard"
                >
                  Team
                </Link>
              </li>
            </ul>
          </a>

          <Link
            to="/admin/dashboard/question-logic"
            className="__dashboard"
            activeClassName="activedashboard"
          >
            <p>
              <img
                src="/assets/img/publication.png"
                className="__leftbarimg"
                alt=""
              />
              &nbsp;&nbsp;&nbsp;Question Logic
            </p>
          </Link>

          <Link
            to="/admin/dashboard/interactive-analysis"
            className="__dashboard"
            activeClassName="activedashboard"
          >
            <p>
              <img
                src="/assets/img/intaractivead.png"
                className="__leftbarimg"
                alt=""
              />
              &nbsp;&nbsp;&nbsp;Interactive analysis
            </p>
          </Link>

          {/* <Link to='/admin/dashboard/notifications' className='__dashboard' activeClassName="activedashboard">
							<p>
								<img
									src='/assets/img/notificaton.png'
									className='__leftbarimg'
					        alt=''
								/>
								&nbsp;&nbsp;&nbsp;Notifications
							</p>
						</Link> */}

          <div className="btn-group __addrp">
            <button
              type="button"
              className="btn  __subbtn dropdown-toggle"
              data-toggle="dropdown"
              id="showbuttonup"
            >
              Upload <span className="caret"></span>
            </button>
            <ul
              className="dropdown-menu __adropdown-menu"
              role="menu"
              id="showingup"
            >
              <li>
                <a data-bs-toggle="modal" data-bs-target="#modal-idia">
                  Interactive analysis
                </a>
              </li>
              <li>
                <a data-bs-toggle="modal" data-bs-target="#modal-idpub">
                  Publications
                </a>
              </li>
              <li>
                <a data-bs-toggle="modal" data-bs-target="#modal-id">
                  Dataset
                </a>
              </li>
              <li>
                <a data-bs-toggle="modal" data-bs-target="#modal-quesId">
                  Question Logic
                </a>
              </li>
              <li>
                <a data-bs-toggle="modal" data-bs-target="#modal-section">
                  Sections
                </a>
              </li>
              <li>
                <a data-bs-toggle="modal" data-bs-target="#modal-page">
                  Pages
                </a>
              </li>
              <li>
                <a data-bs-toggle="modal" data-bs-target="#modal-team">
                  Team
                </a>
              </li>
              <li>
                <a data-bs-toggle="modal" data-bs-target="#modal-sliceAndDice">
                  Slice and Dice
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
