import {
  SIGNIN_USER,
  STORE_ALL_USERS,
  UPDATE_USER_STATUS
} from '../../actions/actiontypes';

const initialState = {
  user: null,
  token: '',
  users: [],
};

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case SIGNIN_USER:
      return {
        ...state,
        ...action.payload
      };

    case STORE_ALL_USERS:
      return {
        ...state,
        users: action.payload
      };
    
    case UPDATE_USER_STATUS: {
      const index = state.users.findIndex(data => data.uuid === action.payload.uuid);
      const updateSelectedDataset = {...state.users[index], status: action.payload.status};
      state.users.splice(index, 1, updateSelectedDataset);
      
      return {
        ...state
      }
    }

    default: return state;
  }
}

export default userReducer;