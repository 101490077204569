import {SET_DATASET_TO_VISUALIZE } from '../../actions/actiontypes';

const initialState = {
  datasetToVisualize: null
};

const reducer = (state = initialState, action) => {
  switch(action.type) {

  case SET_DATASET_TO_VISUALIZE: 
    return {
      ...state,
      datasetToVisualize: action.payload
    }
  
    default: return state
  }
}

export default reducer;