import React, { useState } from 'react';
// import Header from '../../common/components/Header';
import Nav from '../../common/components/Nav';
import Footer from '../../common/components/Footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { errorHandler } from '../../utils/helpers/errorHandler';
import { sendContactUsMail } from "../../utils/api/contact-us";

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const handleContactUs = async (event) => {
    console.log("WHAT DO WE HAVE HERE ");
    event.preventDefault();
    setShowLoader(true);
    try {
      const data = await sendContactUsMail({ name, email, message });

      toast.success(`${data.message}`);
      setName('');
      setMessage('');
      setEmail('');
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      errorHandler(error, toast);
    }
  }

  return (
    <>
      <header>
        <div className="__landbgex">
          <Nav/>
          
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="__captionex text-center">Contact Us</p>
                <div className="__spacet3p"></div>
                <form action="">
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control __contform"
                      placeholder="Name"
                      value={name}
                      onChange={event => setName(event.target.value)}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control __contform"
                      placeholder="Email"
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <textarea
                      className="form-control"
                      rows="7"
                      placeholder="Message"
                      value={message}
                      onChange={event => setMessage(event.target.value)}
                      style={{borderRadius: "2px"}}
                    ></textarea>
                  </div>

                  <div className="__spacet5p"></div>
                  <div className="text-center">
                     <button
                      type="submit"
                      className="btn btn-block __autbtn"
                      style={{ width: "100%"}}
                      onClick={handleContactUs}
                    >
                      Send
                      { showLoader && <img src="/assets/img/loader.gif" alt="" style={{ marginLeft: "7px" }} />}
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
            </div>
          </div>
        </div>
      </header>
      <ToastContainer
        closeOnClick
        pauseOnHover
      ></ToastContainer>
      <Footer />
    </>
  )
}

export default ContactPage;